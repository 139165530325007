import FormScreenWrapper from './FormScreenWrapper';
import { Box, Grid } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import { FormConfig } from '../../design/Forms/interfaces';
import FormPage from '../../reusable/FormUI/FormPage';
import React from 'react';
import { Field } from 'react-final-form';
import ContactFormField from './ContactFormField';

export default function RecoveryProceduresForm() {
    return (
        <FormScreenWrapper title="Recovery Procedures">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What are the steps to assess damage after a disaster?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={recoveryPlanConfig} />
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'Who will be responsible for coordinating recovery efforts?'
                    }
                    color={'text.neutral9'}
                />
                <Grid container spacing={2}>
                    <Grid item xs={6} direction={'column'} rowGap={1}>
                        <AppTypography
                            variant="font12"
                            fontWeight={'medium'}
                            text={'Primary Lead'}
                            color={'text.neutral9'}
                        />
                        <Field
                            name={'recoveryProcedures.primaryLead'}
                            render={({ input }) => (
                                <ContactFormField input={input} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} direction={'column'} rowGap={1}>
                        <AppTypography
                            variant="font12"
                            fontWeight={'medium'}
                            text={'Secondary Lead'}
                            color={'text.neutral9'}
                        />
                        <Field
                            name={'recoveryProcedures.secondaryLead'}
                            render={({ input }) => (
                                <ContactFormField input={input} />
                            )}
                        />
                    </Grid>
                </Grid>
            </Box>
        </FormScreenWrapper>
    );
}

const recoveryPlanConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'recoveryProcedures.recoveryPlan',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
];
