import React from 'react';
import PreviewFormWrapper from './PreviewFormWrapper';
import { RecoveryProceduresFormValues } from '../model';

import { Box, Typography } from '@mui/material';

interface OwnProps {
    data: RecoveryProceduresFormValues;
}
export default function RecoveryProcedurePreview({ data }: OwnProps) {
    return (
        <PreviewFormWrapper label={'Recovery Procedure'}>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}>
                <Typography variant={'font14'} fontWeight="bold">
                    Damage assessment
                </Typography>
                <Typography variant="font12" fontWeight={'regular'}>
                    {data?.recoveryPlan}
                </Typography>
            </Box>
        </PreviewFormWrapper>
    );
}
