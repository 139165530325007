import React from 'react';
import './AnimatedSpinner.css';

interface AnimatedSpinnerProps {
    size?: number;
}

export default function AnimatedSpinner({ size = 150 }: AnimatedSpinnerProps) {
    return (
        <div
            style={{
                width: size,
                height: size,
                display: 'inline-block',
            }}
            className="spinner-container">
            <svg
                id="eosgRY7pSGI1"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                viewBox="0 0 128 128"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <filter
                        height="400%"
                        id="eosgRY7pSGI3-filter"
                        width="400%"
                        x="-150%"
                        y="-150%">
                        <feGaussianBlur
                            id="eosgRY7pSGI3-filter-blur-0"
                            result="result"
                            stdDeviation="0,0"
                        />
                    </filter>
                </defs>
                <path
                    clipRule="evenodd"
                    d="M64,0C28.7096,0,0,28.7096,0,64c0,5.7011.761716,11.2294,2.16736,16.4908h13.95434c-1.7904-5.175-2.772-10.7191-2.772-16.4908c0-27.9323,22.718-50.6503,50.6503-50.6503s50.651,22.718,50.651,50.6503-22.7187,50.651-50.651,50.651c-5.7717,0-11.3158-.983-16.4908-2.773v13.955C52.7706,127.238,58.2989,128,64,128c35.2904,0,64-28.7096,64-64s-28.7096-64-64-64Z"
                    fill="#2e798d"
                    fillRule="evenodd"
                />
                <path
                    clipRule="evenodd"
                    d="M64.1657,87.1656c12.7764,0,23.1655-10.3891,23.1655-23.1656s-10.3891-23.1656-23.1655-23.1656C51.3891,40.8344,41,51.2236,41,64c0,12.7765,10.3891,23.1656,23.1657,23.1656Z"
                    fill="#2e798d"
                    fillRule="evenodd"
                    filter="url(#eosgRY7pSGI3-filter)"
                />
                <path
                    clipRule="evenodd"
                    d="M63.5828,20c24.0295,0,43.5832,19.5534,43.5832,43.5828c0,24.0295-19.5537,43.5832-43.5832,43.5832-5.8345,0-11.4021-1.163-16.4908-3.251v-15.0149c4.7431,3.1018,10.4129,4.9158,16.4908,4.9158c16.6715,0,30.2332-13.5617,30.2332-30.2331s-13.5617-30.2331-30.2332-30.2331c-16.6713,0-30.233,13.5617-30.233,30.2331c0,6.078,1.8139,11.7478,4.9158,16.4908h-15.0145C21.1622,74.985,20,69.4174,20,63.5828C20,39.5534,39.5534,20,63.5828,20Z"
                    fill="#2e798d"
                    fillRule="evenodd"
                />
            </svg>
        </div>
    );
}
