import { Button } from '@mui/material';
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';

interface OwnProps {
    onClick: () => void;
}

export default function EditButton({ onClick }: OwnProps) {
    return (
        <Button onClick={onClick} endIcon={<EditIcon />}>
            Edit
        </Button>
    );
}
