import React from 'react';
import UserAvatar from '../../design/Avatar/UserAvatar';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as EmailIcon } from '../../assets/icons/communications_icon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone_icon.svg';
import { ReactComponent as BirthdayIcon } from '../../assets/icons/birthday_icon.svg';
import { ReactComponent as FlowerIcon } from '../../assets/icons/spa_icon.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/pin_location_icon.svg';
import { ReactComponent as AnniversaryIcon } from '../../assets/icons/calendar_with_heart_icon.svg';
import { ReactComponent as RelationshipIcon } from '../../assets/icons/calendar_icon.svg';
import InfoWithLabel from '../../reusable/InfoWithLabel';
import SocialMediaList from '../../reusable/SocialMediaList';
import { styled } from '@mui/material/styles';
import theme from '../../styles/theme';
import { typeOfAdvocacy } from './LandPartnerWizard';
import { PhoneType } from '../../reusable/FormUI/PhoneNumberField';
import { phoneTypeMapper } from './PreviewLandPartner';
import { phoneMaskForString } from '../../utils/fieldMasks';
import { SocialMedia } from '../ViewLandownersDetails/LandPartnerDetails';

export interface MemberCard {
    firstName: string;
    lastName: string;
    primaryEmail: string;
    secondaryEmail?: string;
    onClick: () => void;
    avatar?: string;
    advocacy?: typeOfAdvocacy[];
    phoneNumber?: PhoneType[];
    address?: string[];
    preferredContactMethod?: string;
    birthday?: string;
    anniversary?: string;
    relationshipStartDate?: string;
    dateOfDeath?: string;
    socialMedia?: SocialMedia;
    isPrimaryContact?: boolean;
}

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 380,
    backgroundColor: theme.palette.darkTurq.darkTurqTint1,
    gap: theme.spacing(1),
    padding: theme.spacing(2),
}));

const advocateMapper: Record<typeOfAdvocacy, string> = {
    BLOCKER: 'Blocker',
    CHAMPION: 'Champion',
    INFLUENCER: 'Influencer',
    KEY_DECISION_MAKER: 'Key Decision Maker',
    DO_NOT_CONTACT: 'Do Not Contact',
};

const preferredContactMethodMapper: Record<string, string> = {
    PRIMARY_EMAIL: 'Primary Email',
    PHONE: 'Phone',
    SECONDARY_EMAIL: 'Secondary Email',
};

export default function PreviewMemberCard({
    firstName,
    lastName,
    avatar,
    primaryEmail,
    advocacy,
    phoneNumber,
    address,
    birthday,
    relationshipStartDate,
    secondaryEmail,
    preferredContactMethod,
    anniversary,
    dateOfDeath,
    socialMedia,
    isPrimaryContact,
    onClick,
}: MemberCard) {
    return (
        <Container>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} columnGap={1}>
                    <UserAvatar
                        name={`${firstName} ${lastName}`}
                        avatar={avatar}
                    />
                    <Box display={'flex'} flexDirection={'column'}>
                        <Typography
                            variant={'font20'}
                            fontWeight="medium"
                            color="text.secondary">
                            {firstName} {lastName}
                        </Typography>
                        {advocacy?.map((advocate) => (
                            <Typography
                                key={advocate}
                                color={'text.secondaryContrast'}
                                variant={'font12'}>
                                {advocateMapper[advocate]}
                            </Typography>
                        ))}
                        {isPrimaryContact && (
                            <Typography
                                color={'text.secondaryContrast'}
                                variant={'font12'}>
                                Primary contact
                            </Typography>
                        )}
                        {preferredContactMethod && (
                            <Typography
                                color={'text.secondaryContrast'}
                                variant={'font12'}>
                                {
                                    preferredContactMethodMapper[
                                        preferredContactMethod
                                    ]
                                }
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Button variant={'text'} onClick={onClick}>
                    Edit
                </Button>
            </Box>
            <Box display={'flex'} flexDirection={'column'} rowGap={1}>
                {primaryEmail && (
                    <InfoWithLabel icon={EmailIcon} text={primaryEmail} />
                )}
                {secondaryEmail && (
                    <InfoWithLabel icon={EmailIcon} text={secondaryEmail} />
                )}
                {phoneNumber?.map((phone) => (
                    <InfoWithLabel
                        key={phone.type}
                        icon={PhoneIcon}
                        text={`${phoneTypeMapper[phone?.type]}: ${phoneMaskForString(phone?.number)}`}
                    />
                ))}
                {!!address?.length &&
                    address?.map((addr) => (
                        <InfoWithLabel
                            key={addr}
                            icon={LocationIcon}
                            text={addr}
                        />
                    ))}
                <Box
                    display={'flex'}
                    flexWrap={'wrap'}
                    alignItems={'center'}
                    width={'100%'}
                    gap={1}>
                    {birthday && (
                        <InfoWithLabel icon={BirthdayIcon} text={birthday} />
                    )}
                    {dateOfDeath && (
                        <InfoWithLabel icon={FlowerIcon} text={dateOfDeath} />
                    )}
                    {relationshipStartDate && (
                        <InfoWithLabel
                            icon={RelationshipIcon}
                            text={relationshipStartDate}
                        />
                    )}
                    {anniversary && (
                        <InfoWithLabel
                            icon={AnniversaryIcon}
                            text={anniversary}
                        />
                    )}
                </Box>
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'}>
                <SocialMediaList socialMedia={socialMedia} />
            </Box>
        </Container>
    );
}
