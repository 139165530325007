import React, { useEffect, useState } from 'react';
import { Box, Skeleton } from '@mui/material';

import theme from '../../styles/theme';
import ProgressBarWithPercentage from '../../design/ProgressBarWithPercentage';
import EmergencyReadinessFormPage from './EmergencyReadinessFormPage';
import { EmergencyReadinessValues, RecoveryProcedures } from './model';
import { useLazyGetOneEmergencyReadinessPlanQuery } from '../../api/emergencyReadiness/emergencyReadiness';
import InfoButton from './InfoButton';

interface OwnProps {
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
    totalSteps: number;
    planId?: string;
}

export default function EmergencyReadinessForm({
    pageNumber,
    planId,
    setPageNumber,
    totalSteps,
}: OwnProps) {
    const [getData, { data, isLoading }] =
        useLazyGetOneEmergencyReadinessPlanQuery();

    const [initialValues, setInitialValues] =
        useState<EmergencyReadinessValues>({ planId, ...defaultValues });
    useEffect(() => {
        if (planId) {
            getData(planId);
        }
    }, [planId]);

    useEffect(() => {
        if (!isLoading && data) {
            setInitialValues({ planId, ...initialDataAdapter(data) });
        }
    }, [data, isLoading]);

    if (isLoading) {
        return <SkeletonFormLoading />;
    }
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            p={2}
            bgcolor={theme.palette.neutralWhite}>
            <Box width={'100%'} display={'flex'} columnGap={2}>
                <InfoButton />
                <ProgressBarWithPercentage
                    color={theme.palette.statusYellow}
                    percentage={(pageNumber / totalSteps) * 100}
                    label={`${pageNumber} of ${totalSteps}`}
                />
            </Box>
            <EmergencyReadinessFormPage
                planId={planId}
                initialValues={initialValues}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
            />
        </Box>
    );
}

function initialDataAdapter(
    data: EmergencyReadinessValues,
): EmergencyReadinessValues {
    return {
        ...data,
        trainingPreparedness: {
            emergencyDrill: {
                attendeesIds:
                    data.trainingPreparedness?.emergencyDrill?.attendeesIds,
                scheduledEvent:
                    data.trainingPreparedness?.emergencyDrill?.scheduledEvent &&
                    new Date(
                        data.trainingPreparedness?.emergencyDrill?.scheduledEvent,
                    ),
            },
            reviewFrequency: {
                days: data.trainingPreparedness?.reviewFrequency?.days,
                exactDate:
                    data.trainingPreparedness?.reviewFrequency?.exactDate &&
                    new Date(
                        data.trainingPreparedness?.reviewFrequency?.exactDate,
                    ),
            },
        },
    };
}

const defaultValues: EmergencyReadinessValues = {
    emergencyContacts: [],
    recoveryProcedures: {} as RecoveryProcedures,
    riskAssessment: {
        naturalDisasters: [
            'Flooding',
            'Drought',
            'Wind Storm',
            'Wild Fire',
            'Tornado',
            'Thunderstorm',
        ],
        manMadeDisasters: ['Chemical Spill', 'Explosion', 'Equipment Fire'],
    },
    criticalResources: {
        crops: ['Corn', 'Soybeans', 'Wheat'],
        equipment: [
            'Tractors',
            'Combines',
            'Sprayers',
            'C16 R Corn Head',
            '2003 John Deere 1820 61 ft Air Drill',
        ],
    },
};

function SkeletonFormLoading() {
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            <Box display={'flex'} columnGap={2}>
                <Skeleton variant="rounded" width="50%" height={40} />
                <Skeleton variant="rounded" width="50%" height={40} />
            </Box>
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width="100%" height={40} />
        </Box>
    );
}
