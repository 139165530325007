import FormScreenWrapper from './FormScreenWrapper';
import { Box, Grid } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import { FormConfig } from '../../design/Forms/interfaces';
import GenericArrayField, { Config } from './GenericArrayField';
import FormPage from '../../reusable/FormUI/FormPage';
import { Field } from 'react-final-form';
import ContactFormField from './ContactFormField';
import React from 'react';

export default function ResourceManagementForm() {
    return (
        <FormScreenWrapper title="Resource Management">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={'What are the backup power sources available?'}
                    color={'text.neutral9'}
                />
                <GenericArrayField
                    fieldName={'backupPowerSources'}
                    config={({ name }: Config) => backupPowerConfig({ name })}
                    addButtonLabel={'Add another power source'}
                />
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What is the average amount of fuel, water, and other essential supplies stored on-site?'
                    }
                    color={'text.neutral9'}
                />
                <GenericArrayField
                    fieldName={'essentialSupplies'}
                    config={({ name }: Config) =>
                        essentialSuppliesConfig({ name })
                    }
                    addButtonLabel={'Add another essential supply'}
                />
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What is the plan for accessing additional resources if needed?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={additionalResourcesConfig} />
                <Grid item xs={6} direction={'column'} rowGap={1}>
                    <AppTypography
                        variant="font12"
                        fontWeight={'medium'}
                        text={'Is this your local store agent?'}
                        color={'text.neutral9'}
                    />
                    <Field
                        name={'resourceManagement.localStoreAgent'}
                        render={({ input }) => (
                            <ContactFormField input={input} />
                        )}
                    />
                </Grid>
            </Box>
        </FormScreenWrapper>
    );
}

function backupPowerConfig({ name }: Config): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'half',
                name: `resourceManagement.${name}.type`,
                type: 'select',
                label: 'Type',
            },
            renderProps: {
                placeholder: 'Select from list',
                isDisabled: false,
                size: 'small',
                options: [],
            },
        },
        {
            formField: {
                scheme: 'half',
                name: `resourceManagement.${name}.location`,
                type: 'input',
                label: 'Location',
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
            },
        },
    ];
}
function essentialSuppliesConfig({ name }: Config): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'third',
                name: `resourceManagement.${name}.type`,
                type: 'select',
                label: 'Type',
            },
            renderProps: {
                placeholder: 'Select from list',
                isDisabled: false,
                size: 'small',
                options: [],
            },
        },
        {
            formField: {
                scheme: 'third',
                name: `resourceManagement.${name}.minimumQuantity`,
                type: 'select',
                label: 'Minimum Quantity',
            },
            renderProps: {
                placeholder: 'Select from list',
                isDisabled: false,
                size: 'small',
                options: [],
            },
        },
        {
            formField: {
                scheme: 'third',
                name: `resourceManagement.${name}.location`,
                type: 'input',
                label: 'Location',
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
            },
        },
    ];
}

const additionalResourcesConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'resourceManagement.additionalResourcesPlan',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
];
