import { Features } from './OrganizationSubscriptionPage';

export const messagesFieldLabels: Record<string, string> = {
    fieldManagement: 'Field Management',
    landPartnerManagement: 'Land Partner Management',
    landPartnerSharing: 'Land Partner Sharing',
    leaseManagement: 'Lease Management',
    paymentManagement: 'Payment Management',
    taskManagement: 'Task Management',
    contentLockbox: 'Content Lockbox & Sharing ',
    successionPlanning: 'Succession Planning ',
    emergencyReadinessPlanning: 'Emergency Readiness Planning',
    quickBooks: 'QuickBooks',
    johnDeereOps: 'John Deere Ops',
    partnerServices: 'Partner Services',
    aiManagement: 'AI Management',
};
export const mapTypeFeatures = {
    BASIC: [
        'fieldManagement',
        'landPartnerManagement',
        'leaseManagement',
        'paymentManagement',
    ],
    PREMIUM: [
        'fieldManagement',
        'landPartnerManagement',
        'landPartnerSharing',
        'leaseManagement',
        'paymentManagement',
        'taskManagement',
        'emergencyReadinessPlanning',
        'aiManagement',
    ],
    ENTERPRISE: [
        'fieldManagement',
        'landPartnerManagement',
        'landPartnerSharing',
        'leaseManagement',
        'paymentManagement',
        'taskManagement',
        'emergencyReadinessPlanning',
        'aiManagement',
    ],
};
export const farmLandManagerMapTypeFeatures = {
    BASIC: ['landPartnerManagement', 'contentLockbox'],
    PREMIUM: [
        'landPartnerManagement',
        'taskManagement',
        'contentLockbox',
        'emergencyReadinessPlanning',
        'aiManagement',
    ],
    ENTERPRISE: [
        'landPartnerManagement',
        'taskManagement',
        'contentLockbox',
        'emergencyReadinessPlanning',
        'aiManagement',
    ],
};
export const successionPlannersMapTypeFeatures = {
    BASIC: ['landPartnerManagement', 'contentLockbox', 'successionPlanning'],
    PREMIUM: [
        'landPartnerManagement',
        'taskManagement',
        'contentLockbox',
        'successionPlanning',
        'emergencyReadinessPlanning',
        'aiManagement',
    ],
    ENTERPRISE: [
        'landPartnerManagement',
        'taskManagement',
        'contentLockbox',
        'successionPlanning',
        'emergencyReadinessPlanning',
        'aiManagement',
    ],
};
export const mapTypeIntegrations = {
    BASIC: ['partnerServices'],
    PREMIUM: ['quickBooks', 'johnDeereOps', 'partnerServices'],
    ENTERPRISE: ['quickBooks', 'johnDeereOps', 'partnerServices'],
};
export const mockFeatures: Features[] = [
    {
        type: 'BASIC',
        features: {
            fieldManagement: true,
            landPartnerManagement: true,
            leaseManagement: true,
            paymentManagement: true,
            partnerServices: true,
        },
    },
    {
        type: 'PREMIUM',
        features: {
            fieldManagement: true,
            landPartnerManagement: true,
            landPartnerSharing: false,
            leaseManagement: true,
            paymentManagement: true,
            taskManagement: true,
            emergencyReadinessPlanning: true,
            quickBooks: true,
            johnDeereOps: true,
            partnerServices: true,
            aiManagement: true,
        },

        isMostPopular: true,
    },
    {
        type: 'ENTERPRISE',
        features: {
            fieldManagement: true,
            landPartnerManagement: true,
            landPartnerSharing: false,
            leaseManagement: true,
            paymentManagement: true,
            taskManagement: true,
            emergencyReadinessPlanning: true,
            quickBooks: true,
            johnDeereOps: true,
            partnerServices: true,
            aiManagement: true,
        },
    },
];
export const farmLandManagerMockFeatures: Features[] = [
    {
        type: 'BASIC',
        features: {
            landPartnerManagement: true,
            contentLockbox: true,
            partnerServices: true,
        },
    },
    {
        type: 'PREMIUM',
        features: {
            landPartnerManagement: true,
            contentLockbox: true,
            taskManagement: true,
            emergencyReadinessPlanning: true,
            quickBooks: true,
            johnDeereOps: true,
            partnerServices: true,
            aiManagement: true,
        },
        isMostPopular: true,
    },
    {
        type: 'ENTERPRISE',
        features: {
            landPartnerManagement: true,
            contentLockbox: true,
            taskManagement: true,
            emergencyReadinessPlanning: true,
            quickBooks: true,
            johnDeereOps: true,
            partnerServices: true,
            aiManagement: true,
        },
    },
];
export const successionPlannersMockFeatures: Features[] = [
    {
        type: 'BASIC',
        features: {
            landPartnerManagement: true,
            contentLockbox: true,
            successionPlanning: true,
            partnerServices: true,
        },
    },
    {
        type: 'PREMIUM',
        features: {
            landPartnerManagement: true,
            contentLockbox: true,
            successionPlanning: true,
            taskManagement: true,
            emergencyReadinessPlanning: true,
            quickBooks: true,
            johnDeereOps: true,
            partnerServices: true,
            aiManagement: true,
        },
        isMostPopular: true,
    },
    {
        type: 'ENTERPRISE',
        features: {
            landPartnerManagement: true,
            contentLockbox: true,
            successionPlanning: true,
            taskManagement: true,
            emergencyReadinessPlanning: true,
            quickBooks: true,
            johnDeereOps: true,
            partnerServices: true,
            aiManagement: true,
        },
    },
];

export function returnFeatures(type: string) {
    if (type === 'FARMLAND_MANAGER') {
        return farmLandManagerMockFeatures;
    }
    if (type === 'SUCCESSION_PLANNERS') {
        return successionPlannersMockFeatures;
    }
    return mockFeatures;
}

export function returnFeaturesMap(type: string) {
    if (type === 'FARMLAND_MANAGER') {
        return farmLandManagerMapTypeFeatures;
    }
    if (type === 'SUCCESSION_PLANNERS') {
        return successionPlannersMapTypeFeatures;
    }
    return mapTypeFeatures;
}
