import { CheckboxOption } from '../../../design/Fields/CheckboxRow';

export const legalAndFinancial: CheckboxOption[] = [
    {
        id: 'FAMILY',
        name: 'Family',
    },
    {
        id: 'OWNER',
        name: 'Owner',
    },
    {
        id: 'POA',
        name: 'POA',
    },
    {
        id: 'ATTORNEY',
        name: 'Attorney',
    },
    {
        id: 'DISASTER_CONTACT',
        name: 'Disaster Contact',
    },
    {
        id: 'INSURANCE_AGENT',
        name: 'Insurence Agent',
    },
];
export const emergencyResponseAndSafety: CheckboxOption[] = [
    {
        id: 'POLICE',
        name: 'Police',
    },
    {
        id: 'FIRE',
        name: 'Fire',
    },
    {
        id: 'MEDICAL',
        name: 'Medical',
    },
];

export const farmAndCropManagement: CheckboxOption[] = [
    {
        id: 'AGRONOMIST',
        name: 'Agronomist',
    },
    {
        id: 'EQUIPMENT_TECH',
        name: 'Equipment Tech',
    },
    {
        id: 'GOVERNMENT_AGENCY',
        name: 'Government Agency (EPA, FDA, FSA)',
    },
    {
        id: 'KEY_CUSTODIAN',
        name: 'Key Custodian',
    },
    {
        id: 'PASSWORD_MANAGER',
        name: 'Password Manager',
    },
];
