import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';
import { QuickBookBills } from '../../features/PaymentsOverview/interfaces';

type IntegrationTypes = 'QUICKBOOKS' | 'DOCUSIGN';

interface IntegrationResponse {
    id: string;
    type: IntegrationTypes;
    active: boolean;
}

interface ConnectQuickBooksResponse {
    qboUrl: string;
}
interface ConnectDocuSignResponse {
    docusignUrl: string;
}

export const integrationsAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        connectQuickBooks: builder.query<ConnectQuickBooksResponse, void>({
            query: () => {
                return {
                    url:
                        process.env.REACT_APP_INTEGRATION_API_HOST +
                        ENDPOINTS.integrations.quickBooks.connect,
                    method: HTTP_METHOD.GET,
                };
            },
        }),
        connectDocuSign: builder.query<ConnectDocuSignResponse, void>({
            query: () => {
                return {
                    url:
                        process.env.REACT_APP_INTEGRATION_API_HOST +
                        ENDPOINTS.integrations.docusign.connect,
                    method: HTTP_METHOD.GET,
                };
            },
        }),

        getIntegrations: builder.query<IntegrationResponse[], void>({
            query: () => {
                return {
                    url:
                        process.env.REACT_APP_INTEGRATION_API_HOST +
                        ENDPOINTS.integrations.root,
                    method: HTTP_METHOD.GET,
                };
            },
        }),
        getListOfBills: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_INTEGRATION_API_HOST +
                        ENDPOINTS.integrations.quickBooks.bills,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: QuickBookBills[],
                meta,
            ): { data: QuickBookBills[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
        }),
    }),
});

export const {
    useLazyConnectDocuSignQuery,
    useGetListOfBillsQuery,
    useGetIntegrationsQuery,
    useLazyConnectQuickBooksQuery,
} = integrationsAPI;
