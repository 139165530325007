import { Box, Button, Divider } from '@mui/material';

import { Form } from 'react-final-form';

import FormPage from '../../reusable/FormUI/FormPage';
import SubmitButton from '../../design/Buttons/SubmitButton';
import BaseModal from '../../reusable/BaseModal';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as DollarIcon } from '../../assets/icons/dollar_icon.svg';

import {
    composeValidators,
    isNumber,
    maxLength,
    minLength,
    required,
} from '../../utils/validation';

import { FormConfig } from '../../design/Forms/interfaces';
import { Option } from '../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';
import { numberMask } from '../../utils/fieldMasks';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../constants';
import { useGetLandPartnersShortQuery } from '../../api/landpartners/landPartnersAPI';
import { useGetLeaseListQuery } from '../../api/leases/leaseAPI';
import { convertLeaseToOptions } from '../PaymentsOverview/IntegrationPayment/utils';
import theme from '../../styles/theme';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import { usePayExpenseMutation } from '../../api/payment/paymentAPI';
import { convertDateToString } from '../../utils/dateFormat';

interface FormConfigProps {
    landPartnersOptions: Option[];
    leasesOptions: Option[];
}

interface FormValues {
    payee?: {
        id: string;
        name: string;
    };
    paymentDate: Date;
    category?: string;
    leaseId?: {
        id: string;
        name: string;
    };
    amount: number;
    note?: string;
    file?: File[];
}

interface OwnProps {
    isShowing: boolean;
    handleClose: () => void;
    initData?: FormValues;
    isLoading?: boolean;
}

export default function AddExpenseModal({
    isShowing,
    handleClose,
    initData,
    isLoading,
}: OwnProps) {
    const { handleSubmit } = useHandleSubmitRTK({
        useRtkHook: usePayExpenseMutation,
        successMessage: 'Expense added successfully',
        onSuccess: () => {
            handleClose();
        },
    });

    function handleExpensesSubmit(values: FormValues) {
        const { payee, paymentDate, category, leaseId, amount, note, file } =
            values;

        const data = {
            payee: payee,
            paymentDate: convertDateToString(paymentDate),
            category: category,
            leaseId: leaseId?.id,
            amount: +amount,
            note: note,
        };

        handleSubmit({ file, ...data });
    }
    const { classes } = useStyles();

    const { data: landPartnersOptions, isLoading: isLandPartnersLoading } =
        useGetLandPartnersShortQuery({
            sort: 'name,asc',
            page: 0,
            size: 1000,
        });

    const { data: leasesOptions, isLoading: isLeaseLoading } =
        useGetLeaseListQuery({
            sort: 'updatedAt,asc',
            page: 0,
            size: 1000,
        });

    return (
        <BaseModal
            isLoading={isLoading || isLeaseLoading || isLandPartnersLoading}
            size={'small'}
            isShowing={isShowing}
            onClose={handleClose}
            header={'Add an Expense'}>
            <>
                <Box
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={2}
                    bgcolor={theme.palette.backgroundB.backgroundB1}
                    p={theme.spacing(2, 3)}>
                    <Form
                        onSubmit={handleExpensesSubmit}
                        initialValues={initData}>
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} id="expensesForm">
                                <FormPage
                                    formConfig={formExpensesConfig({
                                        landPartnersOptions:
                                            landPartnersOptions?.data || [],
                                        leasesOptions: convertLeaseToOptions(
                                            leasesOptions?.data,
                                        ),
                                    })}
                                />
                            </form>
                        )}
                    </Form>
                </Box>
                <Divider orientation="horizontal" />
                <div className={classes.bottomContainer}>
                    <Button onClick={handleClose} color={'secondary'}>
                        Cancel
                    </Button>
                    <SubmitButton
                        form={'expensesForm'}
                        variant={'text'}
                        isLoading={isLoading}
                        text={'Save'}
                    />
                </div>
            </>
        </BaseModal>
    );
}

const useStyles = makeStyles()((theme) => ({
    bottomContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px',
        height: 70,
        width: '100%',
        padding: theme.spacing(3),
        backgroundColor: theme.palette.neutralWhite,
        borderRadius: '0px 0px 12px 12px',
    },
}));

function formExpensesConfig({
    landPartnersOptions,
    leasesOptions,
}: FormConfigProps): FormConfig[] {
    return [
        {
            formField: {
                name: 'payee',
                type: 'searchAutocomplete',
                label: 'Payee',
                isRequired: true,
                validation: composeValidators(required),
            },
            renderProps: {
                placeholder: '',
                isDisabled: false,
                options: landPartnersOptions,
            },
        },
        {
            formField: {
                name: 'paymentDate',
                type: 'datepicker',
                label: 'Date',
                isRequired: true,
                validation: composeValidators(required),
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
                format: 'MMM dd, yyyy',
            },
        },
        {
            formField: {
                name: `category`,
                type: 'select',
                label: 'What is it for?',
            },
            renderProps: {
                placeholder: '',
                isDisabled: false,
                size: 'small',
                options: paidForOptions,
            },
        },
        {
            formField: {
                name: 'leaseId',
                type: 'searchAutocomplete',
                label: 'Attach to a Lease',
                isRequired: true,
                validation: composeValidators(required),
            },
            renderProps: {
                placeholder: '',
                isDisabled: false,
                options: leasesOptions,
            },
        },
        {
            formField: {
                name: 'amount',
                type: 'maskedInput',
                label: 'Amount Paid',
                isRequired: true,
                validation: composeValidators(
                    minLength(1),
                    maxLength(256),
                    isNumber,
                    required,
                ),
            },
            renderProps: {
                postfix: <DollarIcon />,
                mask: numberMask,
                placeholder: '',
                size: 'small',
            },
        },
        {
            formField: {
                name: 'note',
                type: 'textarea',
                label: 'Note (Optional)',
                isRequired: false,
                validation: composeValidators(minLength(1), maxLength(1024)),
            },
            renderProps: {
                resize: 'none',
                size: 'small',
            },
        },
        {
            formField: {
                name: 'file',
                type: 'fileupload',
                label: 'Add Attachment',
                validation: composeValidators(
                    tooLargeFile(MAX_FILE_SIZE),
                    tooManyFiles(10),
                    invalidFileType([
                        'image/png',
                        'image/jpg',
                        'image/jpeg',
                        'image/bmp',
                        'application/pdf',
                        'text/csv',
                    ]),
                ),
            },
            renderProps: {
                filesAccepted: [
                    '.png',
                    '.jpg',
                    '.jpeg',
                    '.bmp',
                    '.pdf',
                    '.csv',
                ],
                placeholder: 'Or select from your computer',
                helperText: 'Drag document here',
                multipleFiles: true,
                isDisabled: false,
            },
        },
    ];
}

const paidForOptions: Option[] = [
    { id: 'EQUIPMENT', name: 'Equipment repair' },
    {
        id: 'FUEL',
        name: 'Fuel',
    },
    {
        id: 'INSURANCE',
        name: 'Insurance',
    },
    {
        id: 'MAINTENANCE',
        name: 'Maintenance',
    },
    {
        id: 'MORTGAGE',
        name: 'Mortgage',
        // eslint-disable-next-line max-lines
    },
    {
        id: 'RENT',
        name: 'Rent',
    },
    {
        id: 'REPAIRS',
        name: 'Repairs',
    },
    {
        id: 'TAXES',
        name: 'Taxes',
    },
    {
        id: 'UTILITIES',
        name: 'Utilities',
    },
    {
        id: 'OTHER',
        name: 'Other',
    },
];
