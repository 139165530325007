import React from 'react';

import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import GenericTable from '../../design/GenericTable/GenericTable';

import useFieldTable from './useFieldTable';
import AddFieldModal from './AddFieldModal';
import PreviewMapSlider from './PreviewMapSlider';
import FieldNotesModal from './Notes/FieldNotesModal';

export default function LeaseMap() {
    const { classes } = useStyles();

    const {
        data,
        tableConfig,
        totalCount,
        handleClose,
        landData,
        isInfoModalShowing,
        isNotesModalShowing,
        isLoading,
    } = useFieldTable();

    return (
        <div className={classes.containerWrapper}>
            <div>
                <Grid className={classes.viewWrapper}>
                    <PreviewMapSlider data={data} />
                </Grid>
                <Grid className={classes.detailWrapper}>
                    <GenericTable
                        isLoading={isLoading}
                        data={data}
                        totalCount={totalCount}
                        tableConfig={tableConfig}
                    />
                </Grid>
                {isInfoModalShowing && (
                    <AddFieldModal
                        legalDescription={landData?.description}
                        landId={landData?.id}
                        isShowing={isInfoModalShowing}
                        handleClose={handleClose}
                        initData={landData}
                    />
                )}
                {isNotesModalShowing && (
                    <FieldNotesModal
                        id={landData?.id || ''}
                        name={landData?.landName || ''}
                        isShowing={isNotesModalShowing}
                        onClose={handleClose}
                    />
                )}
            </div>
        </div>
    );
}
const useStyles = makeStyles()((theme) => ({
    containerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
        backgroundColor: theme.palette.neutralWhite,
    },
    mapImage: {
        height: 390,
    },
    titleWrapper: {
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    viewWrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
        padding: theme.spacing(2),
    },
    detailWrapper: {
        display: 'flex',
        flex: 1,
        width: '100%',
        gap: theme.spacing(2),
    },
}));
