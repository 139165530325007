import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import { useDocumentsUpdateMutation } from '../../../api/documents/documentsAPI';
import { convertBytesToMbOrKb } from '../../../utils/dataConvertor';
import { DocumentRequest } from './UploadDocument';

import BaseModal from '../../../reusable/BaseModal';
import { Box, Tooltip, Typography } from '@mui/material';
import theme from '../../../styles/theme';
import BasedTooltip from '../../../design/BasedTooltip';
import TextWithTitle from '../../../reusable/TextWithTitle';
import UploadOneDocumentForm from './UploadOneDocumentForm';
import React from 'react';
import { DocumentTable } from './useDocumentsTable';
import { dateTimeConverterFromUTC } from '../../../utils/dateFormat';
import { extractExtensionFromFileName } from '../../../utils/fileHandler';
import FileIconView from '../../../reusable/FileIconView';
import SharedWithCell from './SharedWithCell';

interface OwnProps {
    isShowing: boolean;
    handleClose: () => void;
    documentData?: DocumentTable;
}

export interface InitialDocumentFormValues {
    sharingAllowed: boolean;
}

export default function ModifyDocumentModal({
    documentData,
    isShowing,
    handleClose,
}: OwnProps) {
    const { handleSubmit: handleUpdate, isLoading: isDocumentUpdating } =
        useHandleSubmitRTK({
            useRtkHook: useDocumentsUpdateMutation,
            successMessage: 'Document information updated successfully',
            onSuccess: () => {
                handleClose();
            },
        });
    const initialFormValues = documentData && {
        documentCategory: documentData.documentCategory,
        sharingAllowed: documentData.sharingAllowed,
        documentPurpose: documentData.documentPurpose,
    };

    function handleSubmit(values: DocumentRequest) {
        handleUpdate(
            documentData && {
                documentId: documentData.id,
                documentCategory: values.documentCategory,
                sharingAllowed: values.sharingAllowed,
                documentPurpose: values.documentPurpose,
            },
        );
    }

    return (
        <BaseModal
            size={'xSmall'}
            isShowing={isShowing}
            onClose={handleClose}
            header={'Document Uploads'}>
            <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                <Box
                    p={2}
                    display={'flex'}
                    flexWrap={'wrap'}
                    columnGap={2}
                    justifyContent={'space-between'}
                    bgcolor={theme.palette.backgroundB.backgroundB1}>
                    <BasedTooltip text={documentData?.fileName}>
                        <TextWithTitle
                            title={'File Name'}
                            value={documentData?.fileName}
                        />
                    </BasedTooltip>
                    <TextWithTitle
                        title={'Uploaded by'}
                        value={`${documentData?.createdBy.firstName} ${documentData?.createdBy.lastName}`}
                    />
                    <TextWithTitle
                        title={'Date Uploaded'}
                        value={
                            documentData &&
                            dateTimeConverterFromUTC(documentData?.createdAt)
                        }
                    />
                    <TextWithTitle
                        title={'File Size'}
                        value={
                            documentData &&
                            convertBytesToMbOrKb(documentData?.fileSize)
                        }
                    />
                    {documentData?.fileName && (
                        <Tooltip
                            placement="top"
                            title={extractExtensionFromFileName(
                                documentData?.fileName,
                            )}>
                            <Box
                                width={'30%'}
                                display={'flex'}
                                flexDirection={'column'}>
                                <Typography
                                    variant={'font14'}
                                    fontWeight="bold">
                                    File Type
                                </Typography>
                                <FileIconView name={documentData?.fileName} />
                            </Box>
                        </Tooltip>
                    )}
                    <Box
                        width={'100%'}
                        display={'flex'}
                        flexDirection={'column'}>
                        <Typography variant={'font14'} fontWeight="bold">
                            Shared With:
                        </Typography>
                        {!!documentData?.sharedWith?.length && (
                            <SharedWithCell data={documentData?.sharedWith} />
                        )}
                    </Box>
                </Box>

                <UploadOneDocumentForm
                    buttonLabel={'Update'}
                    handleSubmit={handleSubmit}
                    initialValues={initialFormValues}
                    isLoading={isDocumentUpdating}
                />
            </Box>
        </BaseModal>
    );
}
