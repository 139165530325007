import React, { useState } from 'react';

import GenericTable, { Table } from '../../design/GenericTable/GenericTable';

import { Box, Typography } from '@mui/material';
import { useGetRecentUploadsQuery } from '../../api/leases/leaseAPI';

import PreviewModal from '../../reusable/Modal/PreviewModal';
import { makeStyles } from 'tss-react/mui';

import { dateConverterFromUTC } from '../../utils/dateFormat';
import { useDownloadDocuments } from '../../hooks/useDownloadDocuments';
import ProfilePhoto from '../../reusable/ProfilePhoto';
import AppTypography from '../../design/AppTypography';

export interface CreatedBy {
    id: string;
    firstName: string;
    lastName: string;
}

export type DocumentCategory =
    | 'LEASE'
    | 'LAND'
    | 'TASK'
    | 'FINANCIAL'
    | 'FILINGS'
    | 'REPORTS'
    | 'OTHER'
    | '';

export interface Upload {
    id: string;
    fileName: string;
    documentCategory: DocumentCategory;
    createdAt: number;
    createdBy: CreatedBy;
}

export default function RecentUploads() {
    const [fileName, setFileName] = useState<string>('');

    const { isLoading, isFetching, data } = useGetRecentUploadsQuery({
        sort: `createdAt,desc`,
        page: 0,
        size: 5,
    });

    const {
        isShowing,
        file,
        isLoading: isDocumentLoading,
        handleClose,
        extension,
        handlePreviewOpen,
    } = useDownloadDocuments();

    function handlePreview(documentId: string, fileName: string) {
        setFileName(fileName);
        handlePreviewOpen(documentId);
    }

    const { classes } = useStyles();

    const uploadTableConfig: Table<Upload> = {
        columns: [
            {
                header: {
                    label: 'Name',
                    sortable: false,
                },
                cellRender: (upload: Upload) => (
                    <div
                        onClick={() =>
                            handlePreview(upload?.id, upload?.fileName)
                        }
                        className={classes.link}>
                        <Typography
                            variant={'font14'}
                            color={'text.link'}
                            fontWeight={'medium'}>
                            {upload?.fileName}
                        </Typography>
                    </div>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Document Type',
                    sortable: false,
                },
                cellRender: (upload: Upload) => (
                    <Typography variant={'font14'}>
                        {
                            documentCategoryMapper[
                                upload?.documentCategory as DocumentCategory
                            ]
                        }
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Date',
                    sortable: false,
                },
                cellRender: (upload: Upload) => (
                    <Typography variant={'font14'}>
                        {dateConverterFromUTC(upload.createdAt)}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Uploaded By',
                    sortable: false,
                },
                cellRender: (upload: Upload) => (
                    <Box display={'flex'} columnGap={1}>
                        {upload.createdBy?.id && (
                            <ProfilePhoto
                                type={'USER'}
                                id={upload.createdBy?.id}
                                avatarFormat={{
                                    size: 'small',
                                    name: `${upload.createdBy?.firstName} ${upload.createdBy?.lastName}`,
                                }}
                            />
                        )}
                        <AppTypography
                            variant={'font14'}
                            color={'text.link'}
                            fontWeight={'medium'}
                            text={`${upload.createdBy?.firstName} ${upload.createdBy?.lastName}`}
                            isHidden={!upload.createdBy?.id}
                        />
                    </Box>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        pageable: false,
        multiselect: false,
        expandable: false,
    };
    return (
        <>
            <GenericTable
                isLoading={isLoading || isFetching}
                data={data}
                tableConfig={uploadTableConfig}
            />
            {extension(fileName) && (
                <PreviewModal
                    extension={extension(fileName) as string}
                    fileName={fileName}
                    file={file}
                    isLoading={isDocumentLoading}
                    isShowing={isShowing}
                    onClose={handleClose}
                />
            )}
        </>
    );
}
const useStyles = makeStyles()(() => ({
    link: {
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
}));

const documentCategoryMapper: Record<DocumentCategory, string> = {
    LEASE: 'Lease',
    LAND: 'Land',
    FINANCIAL: 'Financial',
    TASK: 'Task',
    FILINGS: 'Filings',
    REPORTS: 'Reports',
    OTHER: 'Other',
    '': '',
};
