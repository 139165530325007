import React from 'react';
import Chat from '../../reusable/Chat/Chat';
import {
    useAddNoteAttachmentMutation,
    useAddNoteMutation,
    useGetAllNotesQuery,
    useLazyGetAllNotesQuery,
} from '../../api/contacts/contactNotesAPI';
import { useParams } from 'react-router-dom';
import customToastWithAlert from '../../design/CustomToastWithAlert';
import { CommunicationContainer } from '../../reusable/NotesField/NotesField';
import { convertResponseToNote } from '../CreateContact/AddNotes';
import { Box } from '@mui/material';

export default function AllProfilesNotes() {
    const { id } = useParams();

    const { data: notes, isLoading: isLoadingNotes } = useGetAllNotesQuery({
        contactId: id,
        page: 0,
        size: 2000,
    });
    const [invalidateNotesCache] = useLazyGetAllNotesQuery();

    const [addNotes, { isLoading: isAddingNote }] = useAddNoteMutation();
    const [addNoteAttachment, { isLoading: isAddingNoteAttachment }] =
        useAddNoteAttachmentMutation();

    async function handleAddNote(
        note: string,
        file: File | null,
        communicationContainer: CommunicationContainer,
    ) {
        try {
            //eslint-disable-next-line
            // @ts-ignore
            const { data } = await addNotes({
                contactId: id,
                content: note,
                communicationContainer,
            });

            if (file && data.id) {
                await addNoteAttachment({
                    id: data.id,
                    attachment: file,
                });
            }

            invalidateNotesCache({
                contactId: id,
                page: 0,
                size: 2000,
            });

            customToastWithAlert({
                type: 'success',
                message: 'Note added successfully',
            });
        } catch {
            customToastWithAlert({
                type: 'error',
                message: 'Note attachment upload failed',
            });
        }
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            width="100%">
            <Box width="85%">
                <Chat
                    type={'CONTACT'}
                    contactId={id}
                    cards={
                        (notes?.data && convertResponseToNote(notes?.data)) ||
                        []
                    }
                    addNote={handleAddNote}
                    isLoadingNotes={isLoadingNotes}
                    isAddingNote={isAddingNote || isAddingNoteAttachment}
                />
            </Box>
        </Box>
    );
}
