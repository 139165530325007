import { Form } from 'react-final-form';
import { Lease } from '../../pages/Leases/TableConfig';
import FormPage from '../../reusable/FormUI/FormPage';
import { Box, Typography } from '@mui/material';
import SubmitButton from '../../design/Buttons/SubmitButton';
import React from 'react';
import { FormConfig } from '../../design/Forms/interfaces';
import { composeValidators, required } from '../../utils/validation';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../constants';
import { useDocumentsUploadMutation } from '../../api/leases/leaseAPI';

import useHandleSubmitRTK from '../../hooks/useHandleSubmit';

interface OwnProps {
    id?: string;
}
export default function UploadForm({ id }: OwnProps) {
    const { handleSubmit: documentsUpload, isLoading: isUpdating } =
        useHandleSubmitRTK({
            useRtkHook: useDocumentsUploadMutation,
            successMessage: 'Document uploaded successfully',
        });
    function handleSubmit(values: Lease, id?: string) {
        documentsUpload({
            leaseFile: values.leaseFile,
            id,
        });
    }
    return (
        <Box width={'100%'}>
            <Typography
                variant={'font12'}
                fontWeight={'bold'}
                textTransform={'uppercase'}
                color={'text.secondary'}>
                UPLOAD DOCUMENTS
            </Typography>
            <Form
                initialValues={{}}
                onSubmit={(values: Lease, form) => {
                    handleSubmit(values, id);
                    form.reset();
                }}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <FormPage formConfig={uploadFile} />
                        <Box
                            display={'flex'}
                            justifyContent={'flex-end'}
                            pt={2}>
                            <SubmitButton
                                isLoading={isUpdating}
                                text={'Upload'}
                            />
                        </Box>
                    </form>
                )}
            </Form>
        </Box>
    );
}

const uploadFile: FormConfig[] = [
    {
        formField: {
            name: 'leaseFile',
            type: 'fileupload',
            validation: composeValidators(
                tooLargeFile(MAX_FILE_SIZE),
                tooManyFiles(1),
                required,
                invalidFileType([
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/bmp',
                    'application/pdf',
                ]),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
            placeholder: 'Drag a document here',
            helperText: 'Attach Document',
            multipleFiles: false,
            isDisabled: false,
        },
    },
];
