import FormScreenWrapper from './FormScreenWrapper';
import { Box, Grid } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import { FormConfig } from '../../design/Forms/interfaces';
import GenericArrayField, { Config } from './GenericArrayField';
import { composeValidators } from '../../utils/validation';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../constants';
import FormPage from '../../reusable/FormUI/FormPage';
import useFormFileUpload, { FormConfigProps } from './useFormFileUpload';
import { Field, useForm, useFormState } from 'react-final-form';
import FileWithDownloadButton from '../../reusable/FileWithDownloadButton';
import ContactFormField from './ContactFormField';
import React from 'react';

export default function CropProtectionForm() {
    const { handleFileUpload } = useFormFileUpload();
    const { values } = useFormState();
    const form = useForm();
    const cropInsuranceFile = values?.cropProtection?.cropInsuranceFile;

    return (
        <FormScreenWrapper title="Crop Protection">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What measures can be taken to protect crops from various types of disasters?'
                    }
                    color={'text.neutral9'}
                />
                <GenericArrayField
                    fieldName={'measuresToTake'}
                    config={({ name }: Config) =>
                        measuresToTakeConfig({ name })
                    }
                    addButtonLabel={'Add another crop'}
                />
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    text={'Crop Insurance Information'}
                    color={'text.neutral9'}
                />
                <Grid container spacing={2}>
                    <Grid item xs={6} direction={'column'} rowGap={1}>
                        <AppTypography
                            variant="font12"
                            fontWeight={'medium'}
                            text={'Is this your agent?'}
                            color={'text.neutral9'}
                        />
                        <Field
                            name={'cropProtection.insuranceAgent'}
                            render={({ input }) => (
                                <ContactFormField input={input} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} direction={'column'} rowGap={1}>
                        <FormPage
                            formConfig={insuranceFormConfig({
                                onChange: handleFileUpload,
                                isHidden: !!cropInsuranceFile?.id,
                            })}
                        />
                        {cropInsuranceFile?.id && (
                            <FileWithDownloadButton
                                isRemovable={true}
                                onDelete={() => {
                                    form.change(
                                        'cropProtection.cropInsuranceFile',
                                        null,
                                    );
                                }}
                                id={cropInsuranceFile.id}
                                name={cropInsuranceFile?.fileName}
                                size={cropInsuranceFile?.fileSize}
                                date={cropInsuranceFile?.createdAt}
                            />
                        )}
                    </Grid>
                </Grid>
            </Box>
        </FormScreenWrapper>
    );
}

function measuresToTakeConfig({ name }: Config): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'third',
                name: `cropProtection.${name}.crop`,
                type: 'select',
                label: 'Crop',
            },
            renderProps: {
                placeholder: 'Select from list',
                isDisabled: false,
                size: 'small',
                options: [],
            },
        },
        {
            formField: {
                scheme: 'third',
                name: `cropProtection.${name}.protectionMeasures`,
                type: 'select',
                label: 'Protection',
            },
            renderProps: {
                placeholder: 'Select from list',
                isDisabled: false,
                size: 'small',
                options: [],
            },
        },
        {
            formField: {
                scheme: 'third',
                name: `cropProtection.${name}.field`,
                type: 'input',
                label: 'Field',
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
            },
        },
    ];
}
function insuranceFormConfig({
    onChange,
    isHidden,
}: FormConfigProps): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'half',
                name: 'cropProtection.cropInsuranceFile',
                type: 'fileupload',
                validation: composeValidators(
                    tooLargeFile(MAX_FILE_SIZE),
                    tooManyFiles(1),
                    invalidFileType([
                        'image/png',
                        'image/jpg',
                        'image/jpeg',
                        'image/bmp',
                        'application/pdf',
                    ]),
                ),
                onChange: (e) =>
                    onChange({
                        document: e as File[],
                        type: 'ER_CROP_INSURANCE',
                        fieldName: 'cropProtection.cropInsuranceFile',
                    }),
                isHidden: isHidden,
            },
            renderProps: {
                filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
                placeholder: 'Or select from your computer',
                helperText: 'Add your policy by dragging it here',
                multipleFiles: false,
                isDisabled: false,
            },
        },
    ];
}
