import React, { useEffect, useMemo, useState } from 'react';
import GenericTable, {
    Table,
    TableColumn,
} from '../../design/GenericTable/GenericTable';
import PATHS from '../../router/paths';
import NotesButton from './NotesButton';
import StatusTag, { Tag } from '../../design/StatusTag/StatusTag';
import { useSorting } from '../../design/GenericTable/useSorting';
import { usePagination } from '../../design/GenericTable/useBackendPagination';

import {
    LandPartnerUpdate,
    Status,
} from '../../features/ViewLandownersDetails/LandPartnerDetails';
import AddNotesModal, { ContactNotesData } from '../../reusable/AddNotesModal';
import {
    useGetAllContactsQuery,
    useLazyGetAllContactsQuery,
} from '../../api/contacts/contactsAPI';

import { isColumnHidden, sortColumnsByOrder } from '../utils';
import { useSettingsFromStorage } from '../../hooks/useSettingsFromStorage';
import { useSaveSettings } from '../../hooks/useSaveSettings';
import { ContactAvatar } from '../../features/Notifications/NotificationCard';
import { PhoneType } from '../../reusable/FormUI/PhoneNumberField';
import ExpandablePhoneList from './ExpandablePhoneList';
import LinkText from '../../design/Fields/LinkText';
import { Box } from '@mui/material';
import InfoTooltip from '../../design/Tooltip/InfoTooltip';
import { LPSharingContact } from '../Settings/SharingSettings/ManageSettingsTable';
import EmailVerificationButton from './EmailVerificationButton';
import { dateTimeConverterFromUTC } from '../../utils/dateFormat';
import { ColumnConfig } from '../../api/users/customizeConfigAPI';
import ProfilePhoto from '../../reusable/ProfilePhoto';

interface OwnProps {
    config: ColumnConfig[];
    isSelected: (items: ContactLandPartnerView) => boolean;
    toggleSelection: (items: ContactLandPartnerView) => void;
    toggleSelectionAll: (items: ContactLandPartnerView[]) => void;
    isSelectedAll: (items: ContactLandPartnerView[]) => boolean;
    isLoading: boolean;
}

export type EmailStatus = 'VERIFIED' | 'UNVERIFIED' | 'PENDING' | 'FAILED';

export interface ContactLandPartnerView {
    id: string;
    firstName: string;
    lastName: string;
    contactNumbers?: PhoneType[];
    primaryEmail?: string;
    acres?: number;
    relationshipManager?: string;
    lastContactDate?: string;
    firstLandPartner?: LandPartnerUpdate;
    otherLandPartners?: LandPartnerUpdate[];
    avatar?: ContactAvatar;
    attachmentCount: number;
    noteCount: number;
    primaryEmailStatus: EmailStatus;
    associatedAcres?: number;
}

export default function AllProfilesTable({
    isSelected,
    toggleSelection,
    toggleSelectionAll,
    isSelectedAll,
    config,
    isLoading,
}: OwnProps) {
    const { settings, saveSettings } = useSettingsFromStorage('ALL_CONTACTS');
    const { sort, page, sortDirection, size } = settings;
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);

    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'createdAt',
        sortDirection || 'desc',
    );
    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });

    const { data: initData, isLoading: isAllContactsLoading } =
        useGetAllContactsQuery({
            sort: `${sortKey},${sortOrder}`,
            page: currentPage - 1,
            size: itemsPerPage,
        });
    const [getItems, { data: sortedData, isLoading: isLazyLoading }] =
        useLazyGetAllContactsQuery();
    const [openNotesModal, setOpenNotesModal] = useState(false);
    const [currentContact, setCurrentContact] =
        useState<ContactNotesData | null>(null);

    function handleModalOpen(item: ContactLandPartnerView) {
        setCurrentContact(convertContactData(item));
        setOpenNotesModal(true);
    }

    const handleNotesClose = () => {
        setCurrentContact(null);
        setOpenNotesModal(false);
    };

    useEffect(() => {
        getItems({
            sort: `${sortKey},${sortOrder}`,
            page: currentPage - 1,
            size: itemsPerPage,
        });
    }, [sortKey, sortOrder, currentPage, itemsPerPage]);

    const totalCount = useMemo(() => {
        return sortedData?.totalCount || initData?.totalCount || 0;
    }, [sortedData, initData]);

    const data = useMemo(() => {
        return sortedData?.data || initData?.data || [];
    }, [sortedData, initData]);

    const columns: TableColumn<ContactLandPartnerView>[] = [
        {
            header: {
                customizeKey: 'fullName.first_last',
                label: 'Full Name',
                sortable: true,
                sorting: {
                    id: 'firstName',
                    direction: sortKey === 'firstName' ? sortOrder : 'desc',
                    isSorted: sortKey === 'firstName',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(config, 'fullName.first_last', false),
            },
            cellRender: (item) => (
                <Box display={'flex'} alignItems={'center'} columnGap={1}>
                    <ProfilePhoto
                        type={'CONTACT'}
                        id={item?.id}
                        avatarFormat={{
                            size: 'small',
                            name: `${item?.lastName} ${item?.firstName}`,
                        }}
                    />
                    <LinkText
                        to={`/${PATHS.landpartners}/${PATHS.allProfiles}/${item.id}`}
                        text={`${item.firstName} ${item.lastName}`}
                        variant={'font14'}
                    />
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'fullName.first_last', false),
        },
        {
            header: {
                customizeKey: 'fullName.last_first',
                label: 'Full Name',
                sortable: true,
                sorting: {
                    id: 'lastName',
                    direction: sortKey === 'lastName' ? sortOrder : 'desc',
                    isSorted: sortKey === 'lastName',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(config, 'fullName.last_first', true),
            },
            cellRender: (item) => (
                <Box display={'flex'} alignItems={'center'} columnGap={1}>
                    <ProfilePhoto
                        type={'CONTACT'}
                        id={item?.id}
                        avatarFormat={{
                            size: 'small',
                            name: `${item?.lastName} ${item?.firstName}`,
                        }}
                    />
                    <LinkText
                        to={`/${PATHS.landpartners}/${PATHS.allProfiles}/${item.id}`}
                        text={`${item.lastName}, ${item.firstName}`}
                        variant={'font14'}
                    />
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'fullName.last_first', true),
        },
        {
            header: {
                customizeKey: 'landPartnerName',
                label: 'Land Partner',
                sortable: true,
                sorting: {
                    id: 'firstLandPartner.name',
                    direction:
                        sortKey === 'firstLandPartner.name'
                            ? sortOrder
                            : 'desc',
                    isSorted: sortKey === 'firstLandPartner.name',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(config, 'landPartnerName', false),
            },
            cellRender: (item) =>
                item.firstLandPartner && showLandPartnerNames(item),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'landPartnerName', false),
        },
        {
            header: {
                customizeKey: 'phoneNumber',
                label: 'Primary Phone',
                sortable: true,
                sorting: {
                    id: 'cellPhoneNumber,officePhoneNumber,homePhoneNumber,homePhoneNumber',
                    direction:
                        sortKey ===
                        'cellPhoneNumber,officePhoneNumber,homePhoneNumber,homePhoneNumber'
                            ? sortOrder
                            : 'desc',
                    isSorted:
                        sortKey ===
                        'cellPhoneNumber,officePhoneNumber,homePhoneNumber,homePhoneNumber',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(config, 'phoneNumber', false),
            },
            cellRender: (item) =>
                !!item?.contactNumbers?.length && (
                    <ExpandablePhoneList phoneList={item.contactNumbers} />
                ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'phoneNumber', false),
        },
        {
            header: {
                customizeKey: 'primaryEmail',
                label: 'Email',
                sortable: true,
                sorting: {
                    id: 'primaryEmail',
                    direction: sortKey === 'primaryEmail' ? sortOrder : 'desc',
                    isSorted: sortKey === 'primaryEmail',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(config, 'primaryEmail', false),
            },
            cellRender: (item) => (
                <Box display={'flex'} columnGap={1} alignItems={'center'}>
                    {item.primaryEmail}{' '}
                    <EmailVerificationButton
                        id={item.id}
                        isHidden={!item.primaryEmail}
                        emailStatus={item.primaryEmailStatus}
                    />
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'primaryEmail', false),
        },
        {
            header: {
                customizeKey: 'relationshipManager',
                label: 'Relationship Manager',
                sortable: false,
                isHidden: isColumnHidden(config, 'relationshipManager', true),
            },
            cellRender: (item) => <>{item.relationshipManager}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'relationshipManager', true),
        },
        {
            header: {
                customizeKey: 'acres',
                label: 'Acres',
                sortable: true,
                sorting: {
                    id: 'associatedAcres',
                    direction:
                        sortKey === 'associatedAcres' ? sortOrder : 'desc',
                    isSorted: sortKey === 'associatedAcres',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(config, 'acres', false),
            },
            cellRender: (item) => <>{item.associatedAcres}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'acres', false),
        },
        {
            header: {
                customizeKey: 'notes',
                label: 'Notes',
                sortable: false,
                // eslint-disable-next-line max-lines
                isHidden: isColumnHidden(config, 'notes', false),
            },
            cellRender: (item) => (
                <NotesButton
                    notesCount={item.noteCount}
                    attachmentCount={item.attachmentCount}
                    handleModalOpen={() => handleModalOpen(item)}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'notes', false),
        },
        {
            header: {
                customizeKey: 'lastContactDate',
                label: 'Last Contact',
                sortable: false,
                sorting: {
                    id: 'lastContactDate',
                    direction:
                        sortKey === 'lastContactDate' ? sortOrder : 'desc',
                    isSorted: sortKey === 'lastContactDate',
                },

                onClick: handleSort,
                isHidden: isColumnHidden(config, 'lastContactDate', false),
            },
            cellRender: (item) => (
                <>
                    {item.lastContactDate &&
                        dateTimeConverterFromUTC(item.lastContactDate)}
                </>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'lastContactDate', false),
        },
        {
            header: {
                customizeKey: 'status',
                label: 'Status',
                sortable: false,

                sorting: {
                    id: 'status',

                    direction: sortKey === 'status' ? sortOrder : 'desc',
                    isSorted: sortKey === 'status',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(config, 'status', false),
            },
            cellRender: (contact) => (
                <StatusTag
                    {...statusMap[contact.firstLandPartner?.status || 'ACTIVE']}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'status', false),
        },
    ];
    const orderedColumns = sortColumnsByOrder<ContactLandPartnerView>(
        columns,
        config,
    );
    const tableConfig: Table<ContactLandPartnerView> = {
        columns: orderedColumns,
        multiselect: true,
        expandable: false,
        handlePageChange: handlePageChange,
        handleItemsPerPageChange: handleItemsPerPageChange,
        initialPage: page,
        initialPageSize: size,
        isSelected: isSelected,
        toggleSelection: toggleSelection,
        toggleSelectionAll: toggleSelectionAll,
        isSelectedAll: isSelectedAll,
    };
    return (
        <>
            <GenericTable
                isLoading={isLoading || isAllContactsLoading || isLazyLoading}
                data={data}
                totalCount={totalCount}
                tableConfig={tableConfig}
            />
            {currentContact && currentContact.id && (
                <AddNotesModal
                    id={currentContact.id}
                    contact={currentContact}
                    isShowing={openNotesModal}
                    onClose={handleNotesClose}
                />
            )}
        </>
    );
}

const statusMap: Record<Status, Tag> = {
    ACTIVE: {
        status: 'success',
        text: 'Active',
    },

    INACTIVE: {
        status: 'warning',
        text: 'Prospect',
    },
    PROSPECT: {
        status: 'info',
        text: 'Pending',
    },

    SUSPENDED: {
        status: 'error',
        text: 'Suspended',
    },
};

function convertContactData(item: ContactLandPartnerView) {
    return {
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        phoneNumber: item?.contactNumbers,
        organization: item?.firstLandPartner?.name,
        avatar: item?.avatar,
    };
}

export const showLandPartnerNames = (
    contact: LPSharingContact | ContactLandPartnerView,
) => {
    const firstLpId = contact.firstLandPartner?.id;
    const landPartnerNames = contact.otherLandPartners
        ?.filter((i) => i.id !== firstLpId)
        .map((i) => i.name);
    const firstLandPartnerName = contact.firstLandPartner?.name || '';
    return (
        <Box display={'flex'} alignItems={'center'} gap={1}>
            <LinkText
                to={`/${PATHS.landpartners}/${PATHS.profiles}/${firstLpId}`}
                text={firstLandPartnerName}
                sx={{ textAlign: 'center' }}
                variant={'font14'}
            />
            <span>
                {landPartnerNames &&
                    landPartnerNames.length > 0 &&
                    `+${landPartnerNames.length}`}
            </span>
            {landPartnerNames && landPartnerNames.length > 0 && (
                <InfoTooltip
                    text={landPartnerNames.join(', ')}
                    position={'top'}
                />
            )}
        </Box>
    );
};
