export enum USERS {
    USERS = 'Users',
}

export enum ROLES {
    ROLES = 'Roles',
}

export enum ORGANIZATIONS {
    ORGANIZATIONS = 'Organizations',
    ORGANIZATION_CONFIG = 'OrganizationConfig',
}

export enum PARTNER_SERVICES {
    PARTNER_SERVICES_STATUS = 'PartnerServicesStatus',
}

export enum REDUX_CACHE {
    LIST = 'LIST',
    ABORT_INVALIDATION = 'ABORT',
}

export enum ACCOUNT {
    ACCOUNT = 'Account',
    ACCOUNT_LAYOUTS = 'AccountLayouts',
}

export enum LANDOWNERS {
    LANDOWNERS = 'Landowners',
}

export enum LAND_PARTNERS {
    LAND_PARTNERS = 'LandPartners',
}

export enum LEASES {
    LEASES = 'Leases',
    FIELD = 'Field',
}

export enum FIELDS {
    FIELD_NOTES = 'FieldNotes',
}

export enum BUG_REPORTS {
    BUG_REPORTS = 'BugReports',
}

export enum CONTACTS {
    CONTACTS = 'Contacts',
}

export enum EMAIL {
    EMAIL = 'Email',
}

export enum CONTACT_NOTES {
    CONTACT_NOTES = 'ContactNotes',
}

export enum LEASE_NOTES {
    LEASE_NOTES = 'LeaseNotes',
}

export enum DOCUMENTS {
    DOCUMENTS = 'Documents',
    DOCUMENT = 'Document',
    GROUPS = 'Groups',
}

export enum PAYMENTS {
    PAYMENTS = 'Payments',
}

export enum CUSTOMIZE_CONFIG {
    CUSTOMIZE_CONFIG = 'CustomizeConfig',
}

export enum NOTIFICATIONS {
    NOTIFICATIONS = 'Notifications',
    NOTIFICATIONS_INFO = 'NotificationsInfo',
}

export enum SEARCH {
    SEARCH = 'Search',
}

export enum DASHBOARD {
    DASHBOARD = 'Dashboard',
}

export enum TASKS {
    TASKS = 'Tasks',
}

export enum CALENDAR {
    CALENDAR = 'Calendar',
}

export enum PERMISSIONS {
    PERMISSIONS = 'Permissions',
}

export enum ACTIVITY {
    ACTIVITY = 'Activity',
}

export enum AVATAR {
    AVATAR = 'Avatar',
}

export enum GROWERS {
    GROWERS = 'Growers',
}

export enum EMERGENCY {
    EMERGENCY = 'Emergency',
    STATUS = 'Status',
}
