import GenericTable from '../../../../design/GenericTable/GenericTable';
import { Box } from '@mui/material';
import TableName from '../TableName';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import React from 'react';
import useTableArchivedContactNotesConfig from './useTableArchivedContactNotesConfig';

export default function ArchivedContactNotesTable() {
    const {
        data,
        tableConfig,
        setIsShowing,
        deleteData,
        handleDelete,
        isLoading,
        isProcessing,
        isShowing,
        totalCount,
    } = useTableArchivedContactNotesConfig();

    return (
        <Box>
            <TableName name="Contact Notes" />
            <GenericTable
                data={data}
                isLoading={isLoading}
                totalCount={totalCount}
                tableConfig={tableConfig}
            />
            <ConfirmDeleteModal
                isShowing={isShowing}
                onClose={() => setIsShowing(false)}
                handleConfirm={() => {
                    handleDelete(deleteData?.id);
                }}
                title={'Are you sure you want to delete this contact note?'}
                info={'This action cannot be undone'}
                isProcessing={isProcessing}
            />
        </Box>
    );
}
