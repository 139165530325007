import { Box, Divider } from '@mui/material';
import LandPartnerMainSection, {
    LandPartnerMainSectionProps,
} from './LandPartnerMainSection';
import LandPartnerAdditionalSection from './LandPartnerAdditionalSection';
import {
    LandPartnerUpdate,
    SocialMedia,
} from '../../../features/ViewLandownersDetails/LandPartnerDetails';
import { createBlobUrl } from '../../../utils/dataConvertor';
import { PrimaryAdditionalInfoProps } from './PrimaryAdditionalInfo';
import { Address } from '../../../features/CreateLandPartner/LandPartnerWizard';
import ActionButton, {
    ActionMenuItem,
} from '../../../design/Buttons/ActionButton';
import React, { useEffect, useState } from 'react';
import PATHS from '../../../router/paths';
import { useNavigate, useParams } from 'react-router-dom';
import ArchivedLandPartner from '../../../features/ViewLandownersDetails/ArchivedLandPartner';
import { useGetLandPartnersContactsQuery } from '../../../api/landpartners/landPartnersAPI';
import useManagePermission from '../../../hooks/useManagePermission';
import { useLazyGetEmergencyReadinessStatusQuery } from '../../../api/emergencyReadiness/emergencyReadiness';

interface EmergencyReadinessStatus {
    exist: boolean;
    status: string;
}

interface OwnProps {
    landPartner?: LandPartnerUpdate;
}

export default function LandPartnerDetailContainer({ landPartner }: OwnProps) {
    const [isDeactivateModalShowing, setIsDeactivateModalShowing] =
        useState(false);
    const [labelName, setLabelName] = useState('');
    const { id } = useParams<{ id: string }>();
    const { checkPermission } = useManagePermission();

    const navigate = useNavigate();

    const { data } = useGetLandPartnersContactsQuery({ id });

    const [fetchEmergencyReadinessStatus, { data: emergencyStat }] =
        useLazyGetEmergencyReadinessStatusQuery();

    const canModifyEmergencyReadiness = checkPermission(
        'emergencyReadinessPlan',
        'modify',
    );

    useEffect(() => {
        if (canModifyEmergencyReadiness) {
            fetchEmergencyReadinessStatus({ id });
        }
    }, [canModifyEmergencyReadiness, id, fetchEmergencyReadinessStatus]);

    useEffect(() => {
        if (emergencyStat) {
            setLabelName(emergencyLabel(emergencyStat));
        }
    }, [emergencyStat]);

    function emergencyLabel(emergencyStat: EmergencyReadinessStatus): string {
        if (emergencyStat?.status === 'IN_PROGRESS')
            return ' Continue Emergency Readiness';
        if (emergencyStat?.status === 'COMPLETED')
            return 'Edit Emergency Readiness';
        if (!emergencyStat?.exist) return 'Start Emergency Readiness Plan';

        return 'Start Emergency Readiness Plan';
    }

    const canModifyLandPartner = checkPermission('landPartners', 'modify');

    const actionButtonItems: ActionMenuItem[] = [
        {
            label: 'Edit',
            onClick: () => navigate(PATHS.update),
            hidden: !canModifyLandPartner,
        },
        {
            label: 'Archive',
            onClick: () => setIsDeactivateModalShowing(true),
            hidden: !canModifyLandPartner,
        },
        {
            label: labelName,
            onClick: () =>
                navigate(
                    `/${PATHS.services}/${PATHS.emergencyReadiness}/${PATHS.create}?organizationId=${id}`,
                ),
            hidden: !canModifyEmergencyReadiness,
        },
    ];
    return (
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
            <Box display={'flex'} columnGap={2} width={'100%'}>
                {landPartner && (
                    <Box display={'flex'}>
                        <LandPartnerMainSection
                            {...mainSectionConvertor(landPartner)}
                        />
                    </Box>
                )}
                <Divider orientation={'vertical'} flexItem variant="middle" />
                {landPartner && (
                    <LandPartnerAdditionalSection
                        primaryInfo={primaryAdditionalDataConvertor(
                            landPartner,
                        )}
                        contactsCount={
                            ((data?.members?.length || 0) + 1) as number
                        } // +1 for the primary contact
                    />
                )}
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'}>
                <ActionButton label={'Actions'} items={actionButtonItems} />
            </Box>
            {isDeactivateModalShowing && (
                <ArchivedLandPartner
                    isShowing={isDeactivateModalShowing}
                    id={id}
                    onClose={() => setIsDeactivateModalShowing(false)}
                />
            )}
        </Box>
    );
}

function socialMediaConvertor(data: LandPartnerUpdate): SocialMedia {
    return {
        facebook: data.socialMedia?.facebook,
        instagram: data.socialMedia?.instagram,
        linkedin: data.socialMedia?.linkedin,
        twitter: data.socialMedia?.twitter,
    };
}

function primaryAdditionalDataConvertor(
    data: LandPartnerUpdate,
): PrimaryAdditionalInfoProps {
    return {
        address: addressConvertor(data.billingAddress),
        primaryEmail: data?.email,
    };
}

function mainSectionConvertor(
    data: LandPartnerUpdate,
): LandPartnerMainSectionProps {
    return {
        avatar:
            data?.landPartnerLogo?.companyLogo &&
            data?.landPartnerLogo?.fileType &&
            createBlobUrl(
                data?.landPartnerLogo?.companyLogo,
                data?.landPartnerLogo?.fileType,
            ),
        name: data.name,
        socialMedia: socialMediaConvertor(data),
        status: data.status,
    };
}

function addressConvertor(data: Address): string {
    return data
        ? [data.address1, data.address2, data.city, data.state, data.zipCode]
              .filter(Boolean)
              .join(', ')
        : 'N/A';
}
