import React, { ReactNode } from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

interface OwnProps {
    text?: string;
    size?: ButtonProps['size'];
    variant?: 'text' | 'contained' | 'outlined';
    isLoading?: boolean;
    isDisabled?: boolean;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    form?: string;
    onClick?: () => void;
}
export default function SubmitButton({
    text = 'Submit',
    variant = 'contained',
    isLoading,
    isDisabled,
    startIcon,
    endIcon,
    onClick,
    form,
    size,
}: OwnProps) {
    return (
        <Button
            form={form}
            size={size}
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={onClick}
            disabled={isDisabled || isLoading}
            variant={variant}
            type="submit">
            {!isLoading ? (
                text
            ) : (
                <CircularProgress
                    size={30}
                    color="primary"
                    style={{ position: 'absolute' }}
                />
            )}
        </Button>
    );
}
