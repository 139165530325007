import { useNavigate } from 'react-router-dom';
import PATHS from '../../router/paths';
import customToastWithAlert from '../../design/CustomToastWithAlert';
import SearchOrganizationField from './SearchOrganizationField/SearchOrganizationField';
import BaseModal from '../../reusable/BaseModal';
import { Box } from '@mui/material';
import theme from '../../styles/theme';
import AppTypography from '../../design/AppTypography';

interface EmergencyModalProps {
    isShowing: boolean;
    onClose: () => void;
}

interface EmergencySelectForm {
    organizationId: {
        id: string;
        name: string;
    };
}

export default function EmergencyReadinessModal({
    isShowing,
    onClose,
}: EmergencyModalProps) {
    const navigate = useNavigate();

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = (value: EmergencySelectForm) => {
        const { organizationId } = value || {};

        if (!organizationId) {
            return customToastWithAlert({
                type: 'error',
                message:
                    'Please select an Organization to startEmergency Readiness',
            });
        }
        navigate(
            `/${PATHS.services}/${PATHS.emergencyReadiness}/${PATHS.create}?organizationId=${organizationId?.id}`,
        );
    };

    return (
        <BaseModal
            header="Initiate Emergency Readiness"
            isShowing={isShowing}
            size="small"
            onClose={handleClose}>
            <Box
                display="flex"
                flexDirection="column"
                rowGap={2}
                padding={2}
                pb={6}
                width={'100%'}
                sx={{
                    borderRadius: ' 0 0 8px 8px',
                }}
                bgcolor={theme.palette.backgroundB.backgroundB1}>
                <AppTypography
                    variant="font16"
                    color="text.secondary"
                    text={'Select Organization for Emergency Readiness Plan'}
                />
                <SearchOrganizationField
                    onSelect={(value) =>
                        handleSubmit({ organizationId: value })
                    }
                />
            </Box>
        </BaseModal>
    );
}
