import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AttachmentDisplay from '../../features/ViewLandownersDetails/ActivityPost/AttachmentDisplay';
import { Attachment } from '../../features/ViewLandownersDetails/LandPartnerActivity';
import PinControls from './PinControls';
import NoteDetails, { NoteDetailsProps } from './NoteDetails';
import EditNoteCard from '../NotesField/EditNoteCard';
import ArchiveButton from './ArchiveButton';
import EditButton from './EditButton';

export type NoteType = 'LEASE' | 'CONTACT' | 'FIELD';

interface OwnProps {
    data: NoteDetailsProps;
    type: NoteType;
    attachment?: Attachment;
    onPreviewOpen: () => void;
    onDownload: () => void;
    handlePinNote: (id: string) => void;
    handleUnpinNote: (id: string) => void;
    handleEdit: (note: NoteDetailsProps) => void;
    handleArchiveNote: (noteId: string) => void;
}

export default function NoteCard({
    data,
    type,
    attachment,
    onPreviewOpen,
    onDownload,
    handleUnpinNote,
    handlePinNote,
    handleEdit,
    handleArchiveNote,
}: OwnProps) {
    const { classes } = useStyles();
    const [isEdit, setIsEdit] = useState(false);

    function handleCancel() {
        setIsEdit(false);
    }

    if (isEdit) {
        return (
            <EditNoteCard
                data={data}
                handleEdit={handleEdit}
                isEdit={isEdit}
                handleCancel={handleCancel}
            />
        );
    }

    return (
        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
            <div
                className={`${classes.container} ${data.pinned ? classes.pinnedContactNote : ''}`}>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    width={'100%'}
                    flexWrap={'wrap'}
                    rowGap={2}
                    columnGap={4}
                    justifyContent="space-between">
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        width={{ xs: '100%', sm: '28%' }}>
                        <NoteDetails data={data} />
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        width={{ xs: '60%', sm: '40%' }}
                        flexGrow={1}>
                        <Typography sx={{ wordBreak: 'break-all' }}>
                            {data.content}
                        </Typography>
                        {attachment && (
                            <AttachmentDisplay
                                attachment={attachment}
                                onPreviewOpen={onPreviewOpen}
                                onDownload={onDownload}
                            />
                        )}
                    </Box>
                    <Box className={classes.actionButtons}>
                        <EditButton
                            onClick={() => setIsEdit(true)}
                            isHidden={isEdit}
                        />
                        <PinControls
                            id={data.id}
                            pinned={data.pinned}
                            handlePinNote={handlePinNote}
                            handleUnpinNote={handleUnpinNote}
                        />
                        <ArchiveButton
                            type={type}
                            noteId={data.id}
                            onClick={handleArchiveNote}
                        />
                    </Box>
                </Box>
            </div>
        </Box>
    );
}

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
        padding: theme.spacing(2),
        gap: theme.spacing(6),
        backgroundColor: theme.palette.backgroundB.backgroundB1,
        borderBottom: `solid 1px ${theme.palette.strokeS1}`,
    },
    pinnedContactNote: {
        backgroundColor: `${theme.palette.backgroundB.pinnedNote} !important`,
    },
    actionButtons: {
        width: '10%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
        },
    },
}));
