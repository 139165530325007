import React, { ReactNode } from 'react';

import { Box, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import BasedTooltip from '../design/BasedTooltip';

export interface TextWithTitleProps {
    title: string;
    value?: string | number | ReactNode;
    hasCopied?: boolean;
    isHidden?: boolean;
    maxWidth?: string | number;
}
export default function TextWithTitle({
    title,
    value,
    isHidden,
    maxWidth = '50%',
}: TextWithTitleProps) {
    const { classes } = useStyles();

    if (isHidden) return null;
    return (
        <Box maxWidth={maxWidth} className={classes.container}>
            <div className={classes.titleContainer}>
                <Typography variant={'font14'} fontWeight="bold">
                    {title}
                </Typography>
            </div>
            <BasedTooltip text={(typeof value === 'string' && value) || ''}>
                <Typography variant="font12" fontWeight={'regular'}>
                    {value}
                </Typography>
            </BasedTooltip>
        </Box>
    );
}
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(3),
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
    },
}));
