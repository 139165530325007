import { MouseEvent, useState } from 'react';
import { useLazySearchByCriteriaQuery } from '../../../api/contacts/contactsAPI';
import { getLodashDebounce } from '../../../utils/wrapperLodash';

interface OwnProps {
    organizationId: string | null;
}
export const useSearchContactField = ({ organizationId }: OwnProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event: MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const [getItems, { data: fetchedData }] = useLazySearchByCriteriaQuery();

    const debouncedSearch = getLodashDebounce((value: string) => {
        if (value.length >= 2) {
            getItems(
                organizationId
                    ? { excludeLandPartnerId: organizationId, nameLike: value }
                    : { nameLike: value },
            );
        }
    }, 300);

    return {
        anchorEl,
        open,
        handlePopoverOpen,
        handlePopoverClose,
        debouncedSearch,
        fetchedData,
    };
};
