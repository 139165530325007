import { useEffect, useState } from 'react';
import customToastWithAlert from '../../design/CustomToastWithAlert';
import {
    createDownloadLinkFromBlob,
    downloadFileFromURL,
    getFilenameFromContentDisposition,
} from '../../utils/fileHandler';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import {
    useArchiveContactNoteMutation,
    usePinContactNoteMutation,
    useUnpinContactNoteMutation,
    useUpdateNoteMutation,
} from '../../api/contacts/contactNotesAPI';
import {
    useArchiveLeaseNoteMutation,
    usePinLeaseNoteMutation,
    useUnpinLeaseNoteMutation,
    useUpdateLeaseNoteMutation,
} from '../../api/leases/leaseNotesAPI';
import { useLazyGetFileQuery } from '../../api/fileApiSlice';
import { NoteDetailsProps } from './NoteDetails';
import { NoteType } from './NoteCard';
import {
    UpdateFieldNoteArgs,
    useArchiveFieldNoteMutation,
    usePinFieldNoteMutation,
    useUnpinFieldNoteMutation,
    useUpdateFieldNoteMutation,
} from '../../api/leases/fieldNotesAPI';

interface ContactNoteUpdate {
    id: string;
    content: string;
    communicationContainer: {
        communicationType: string;
        actualContactDate: string;
    };
}

interface LeaseNoteUpdate {
    id: string;
    content: string;
    communicationContainer: {
        communicationType: string;
        actualContactDate: string;
    };
}

interface OwnProps {
    type?: NoteType;
    notes?: NoteDetailsProps[];
}

export default function useNoteCard({ type = 'CONTACT', notes }: OwnProps) {
    const [file, setFile] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string | undefined>();
    const [isShowing, setIsShowing] = useState(false);
    const [header, setHeader] = useState<string | undefined>();

    const [
        getItems,
        { data: responsePromise, isLoading: isDocumentLoading, isFetching },
    ] = useLazyGetFileQuery();

    const { handleSubmit: updateContactNote } = useHandleSubmitRTK({
        useRtkHook: useUpdateNoteMutation,
        successMessage: 'Note updated successfully',
    });
    const { handleSubmit: submitPinContactNote } = useHandleSubmitRTK({
        useRtkHook: usePinContactNoteMutation,
        isShowSuccessToast: false,
    });
    const { handleSubmit: submitUnpinContactNote } = useHandleSubmitRTK({
        useRtkHook: useUnpinContactNoteMutation,
        isShowSuccessToast: false,
    });
    const { handleSubmit: submitArchiveContactNote } = useHandleSubmitRTK({
        useRtkHook: useArchiveContactNoteMutation,
        successMessage: 'Note archived successfully',
    });

    const { handleSubmit: updateLeaseNote } = useHandleSubmitRTK({
        useRtkHook: useUpdateLeaseNoteMutation,
        successMessage: 'Note updated successfully',
    });
    const { handleSubmit: submitPinLeaseNote } = useHandleSubmitRTK({
        useRtkHook: usePinLeaseNoteMutation,
        isShowSuccessToast: false,
    });
    const { handleSubmit: submitUnpinLeaseNote } = useHandleSubmitRTK({
        useRtkHook: useUnpinLeaseNoteMutation,
        isShowSuccessToast: false,
    });
    const { handleSubmit: submitArchiveLeaseNote } = useHandleSubmitRTK({
        useRtkHook: useArchiveLeaseNoteMutation,
        successMessage: 'Note archived successfully',
    });

    const { handleSubmit: updateFieldNote } = useHandleSubmitRTK({
        useRtkHook: useUpdateFieldNoteMutation,
        successMessage: 'Note updated successfully',
    });
    const { handleSubmit: submitPinFieldNote } = useHandleSubmitRTK({
        useRtkHook: usePinFieldNoteMutation,
        isShowSuccessToast: false,
    });
    const { handleSubmit: submitUnpinFieldNote } = useHandleSubmitRTK({
        useRtkHook: useUnpinFieldNoteMutation,
        isShowSuccessToast: false,
    });
    const { handleSubmit: submitArchiveFieldNote } = useHandleSubmitRTK({
        useRtkHook: useArchiveFieldNoteMutation,
        successMessage: 'Note archived successfully',
    });

    const handlePreviewOpenLease = async (id: string) => {
        getItems(id);
        setIsShowing(true);
    };
    const handlePreviewOpenOther = async (url: string, fileName: string) => {
        setFileName(fileName);
        setFile(url);
        setIsShowing(true);
    };
    const handlePreviewOpen = ({
        id,
        url,
        fileName,
    }: {
        id?: string;
        url?: string;
        fileName?: string;
    }) => {
        if (id && (type === 'LEASE' || type === 'FIELD')) {
            handlePreviewOpenLease(id);
            return;
        }
        if (url) {
            fileName && handlePreviewOpenOther(url, fileName);
        }
    };
    const handleDownloadLease = async (id: string) => {
        getItems(id)
            .unwrap()
            .then(({ data, headers }) => {
                createDownloadLinkFromBlob(
                    data,
                    getFilenameFromContentDisposition(headers),
                );
            });
    };
    const handleDownloadUrl = async (url: string, fileName: string) => {
        downloadFileFromURL(url, fileName);
    };
    const handleDownload = ({ id, url }: { id?: string; url?: string }) => {
        if (id && (type === 'LEASE' || type === 'FIELD')) {
            handleDownloadLease(id);
            return;
        }
        if (url) {
            fileName && handleDownloadUrl(url, fileName);
        }
    };

    const handleEdit = (note: NoteDetailsProps) => {
        if (type === 'LEASE') {
            updateLeaseNote({
                ...noteDetailsToLeaseNoteRequest(note),
            });
        }

        if (type === 'CONTACT') {
            updateContactNote({
                ...noteDetailsToContactNoteRequest(note),
            });
        }

        if (type === 'FIELD') {
            updateFieldNote(noteDetailsToFieldNoteRequest(note));
        }
    };

    useEffect(() => {
        if (type === 'CONTACT') return;
        if (!responsePromise) return;
        const { data, headers }: { data: Blob; headers: string } =
            responsePromise;
        const fileData = data && URL.createObjectURL(data);
        fileData && setFile(fileData);
        headers && setHeader(headers);
    }, [responsePromise, type]);

    const handlePinNote = (id: string) => {
        if (notes && countPinnedNotes(notes) >= 6) {
            customToastWithAlert({
                type: 'warning',
                message: `You've reached the limit of 6 pinned notes. Please unpin some notes to pin new ones.`,
            });
            return;
        }
        const actionMap: Record<NoteType, (request: string) => void> = {
            CONTACT: submitPinContactNote,
            LEASE: submitPinLeaseNote,
            FIELD: submitPinFieldNote,
        };
        actionMap[type](id);
    };

    const handleUnpinNote = (id: string) => {
        const actionMap: Record<NoteType, (request: string) => void> = {
            CONTACT: submitUnpinContactNote,
            LEASE: submitUnpinLeaseNote,
            FIELD: submitUnpinFieldNote,
        };
        actionMap[type](id);
    };

    const handleArchiveNote = (id: string) => {
        const actionMap: Record<NoteType, (id: string) => void> = {
            CONTACT: submitArchiveContactNote,
            LEASE: submitArchiveLeaseNote,
            FIELD: submitArchiveFieldNote,
        };
        actionMap[type](id);
    };

    const handleClose = () => {
        setIsShowing(false);
        setFile(null);
    };
    const isLoading = isDocumentLoading || isFetching;
    return {
        handlePinNote,
        handleUnpinNote,
        handleDownload,
        handleClose,
        handlePreviewOpen,
        isShowing,
        file,
        fileName,
        header,
        isLoading,
        handleEdit,
        handleArchiveNote,
    };
}
export const countPinnedNotes = (notes: NoteDetailsProps[]) =>
    notes.filter((note) => note.pinned).length ?? 0;

function noteDetailsToContactNoteRequest(
    note: NoteDetailsProps,
): ContactNoteUpdate {
    return {
        id: note.id,
        content: note.content,
        communicationContainer: {
            communicationType: note.communicationType,
            actualContactDate: (note.actualContactDate as string) || '',
        },
    };
}

function noteDetailsToLeaseNoteRequest(
    note: NoteDetailsProps,
): LeaseNoteUpdate {
    return {
        id: note.id,
        content: note.content,
        communicationContainer: {
            communicationType: note.communicationType,
            actualContactDate: (note.actualContactDate as string) || '',
        },
    };
}

function noteDetailsToFieldNoteRequest(
    note: NoteDetailsProps,
): UpdateFieldNoteArgs {
    return {
        id: note.id,
        body: {
            content: note.content,
            communicationType: note.communicationType,
            actualContactDate: (note.actualContactDate as string) || '',
        },
    };
}
