interface OwnProps {
    isHidden: boolean;
    docuSignUrl: string;
}

export default function IframeDocuSign({ isHidden, docuSignUrl }: OwnProps) {
    if (isHidden) {
        return null;
    }
    return (
        <iframe src={docuSignUrl} width="100%" height="100%" title="DocuSign" />
    );
}
