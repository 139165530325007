import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download_icon.svg';
import { Attachment } from '../LandPartnerActivity';
import { dateTimeConverterFromUTC } from '../../../utils/dateFormat';
import FileIconView from '../../../reusable/FileIconView';

interface OwnProps {
    attachment: Attachment;
    onPreviewOpen: () => void;
    onDownload: () => void;
}

const formatFileSize = (size: number): string => {
    if (size > 1000 * 1000) return `${(size / (1000 * 1000)).toFixed(2)} MB`;
    return `${(size / 1000).toFixed(2)} KB`;
};

export default function AttachmentDisplay({
    attachment,
    onPreviewOpen,
    onDownload,
}: OwnProps) {
    const [isTruncated, setIsTruncated] = useState(false);
    const textRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const node = textRef.current;
        if (node) {
            setIsTruncated(node.offsetWidth < node.scrollWidth);
        }
    }, [attachment.name]);

    return (
        <Box sx={styles.container}>
            <Tooltip
                title={isTruncated ? attachment.name : ''}
                arrow
                disableHoverListener={!isTruncated}>
                <Typography
                    ref={textRef}
                    variant="font13"
                    color="text.link"
                    fontWeight="regular"
                    onClick={onPreviewOpen}
                    sx={styles.filename}>
                    {attachment.name}
                </Typography>
            </Tooltip>
            <Typography
                variant="font13"
                color="text.neutral8"
                sx={{ flexShrink: 0 }}>
                {formatFileSize(attachment.size || 0)}
            </Typography>
            <Typography
                variant="font13"
                color="text.neutral8"
                sx={{ flexShrink: 0 }}>
                {attachment.date && dateTimeConverterFromUTC(attachment.date)}
            </Typography>
            <Box sx={styles.actionIcons}>
                <Tooltip title={`Preview ${attachment.name}`} arrow>
                    <IconButton
                        onClick={onPreviewOpen}
                        aria-label={`Preview file ${attachment.name}`}>
                        <FileIconView
                            name={attachment.name}
                            style={styles.previewIcon}
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title={`Download ${attachment.name}`} arrow>
                    <IconButton
                        onClick={onDownload}
                        aria-label={`Download file ${attachment.name}`}>
                        <DownloadIcon style={styles.downloadIcon} />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    );
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
        width: '95%',
    },
    filename: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
    },
    actionIcons: {
        display: 'flex',
        gap: 0,
    },
    previewIcon: {
        width: 17,
        height: 17,
    },
    downloadIcon: {
        width: 15,
        height: 15,
    },
};
