import { MouseEvent, useState } from 'react';

import { getLodashDebounce } from '../../../utils/wrapperLodash';
import { useLazySearchOrganizationWithoutPlanQuery } from '../../../api/emergencyReadiness/emergencyReadiness';

export const useSearchOrganizationField = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event: MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const [getItems, { data: fetchedData }] =
        useLazySearchOrganizationWithoutPlanQuery();

    const debouncedSearch = getLodashDebounce((value: string) => {
        if (value.length >= 1) {
            getItems({ nameLike: value });
        }
    }, 300);

    return {
        anchorEl,
        open,
        handlePopoverOpen,
        handlePopoverClose,
        debouncedSearch,
        fetchedData,
    };
};
