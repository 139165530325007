import IntegrationRow from './IntegrationRow';
import { Box } from '@mui/material';
import AppTypography from '../../../design/AppTypography';
import { QuickBookBills } from '../interfaces';
import { HandleSubmitData } from './QuickBookDataImport';
import { convertDataToIntegration } from './utils';

interface OwnProps {
    integration: QuickBookBills[];
    handleSubmitData: (value: HandleSubmitData) => void;
}

export default function IntegrationList({
    integration,
    handleSubmitData,
}: OwnProps) {
    return (
        <>
            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}
                width={'100%'}>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    rowGap={2}
                    width={'100%'}>
                    <AppTypography
                        variant={'font20'}
                        fontWeight={'regular'}
                        color={'text.neutral9'}
                        text={'QuickBooks'}
                    />
                    <Box mr={'calc(35% - 66px)'}>
                        <AppTypography
                            variant={'font20'}
                            fontWeight={'regular'}
                            color={'text.neutral9'}
                            text={'Oaken'}
                        />
                    </Box>
                </Box>
                {integration.map((item, index) => (
                    <IntegrationRow
                        key={index}
                        handleSubmitData={handleSubmitData}
                        data={convertDataToIntegration(item)}
                    />
                ))}
            </Box>
        </>
    );
}
