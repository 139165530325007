import React from 'react';
import BaseModal from '../../../reusable/BaseModal';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import AppTypography from '../../../design/AppTypography';
import ImportPaymentModalHeader from '../ImportPaymentModalHeader';
import { makeStyles } from 'tss-react/mui';
import theme from '../../../styles/theme';
import { useImportPaymentsTable } from './useImportPaymentsTable';
import GenericTable from '../../../design/GenericTable/GenericTable';

interface OwnProps {
    isShowing: boolean;
    handleClose: () => void;
}

export default function ImportPaymentsModal({
    isShowing,
    handleClose,
}: OwnProps) {
    const navigate = useNavigate();

    const { data, tableConfig, totalCount, selected, isLoading } =
        useImportPaymentsTable();

    function handleImportPayments() {
        navigate('/payments/import', { state: { selected } });
    }

    const { classes } = useStyles();

    return (
        <BaseModal
            isLoading={isLoading}
            size={'medium'}
            isShowing={isShowing}
            onClose={handleClose}
            header={'Connect: quickbooks'}>
            <Box>
                <Box
                    bgcolor={theme.palette.backgroundB.backgroundB1}
                    display={'flex'}
                    flexDirection={'column'}
                    p={2}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        rowGap={2}
                        bgcolor={theme.palette.neutralWhite}
                        p={2}
                        borderRadius={2}>
                        <ImportPaymentModalHeader />
                        <AppTypography
                            variant={'font20'}
                            fontWeight={'regular'}
                            text={'Connect: quickbooks'}
                        />
                        <AppTypography
                            variant={'font14'}
                            flexWrap={true}
                            fontWeight={'regular'}
                            text={
                                "You're QuickBooks has been successfully connected. Next it’s time to select the information you’d like to import. Simply choose the data points you need, and don’t worry—if you change your mind, you can always go back and adjust your selections before moving forward. Once you've made your choices, we’ll help you map everything to the right dates, customers, and payments for a smooth integration."
                            }
                        />
                        <Box height={'45vh'} overflow={'auto'}>
                            <GenericTable
                                data={data}
                                tableConfig={tableConfig}
                                totalCount={totalCount}
                            />
                        </Box>
                    </Box>
                </Box>
                <div className={classes.bottomContainer}>
                    <Button
                        sx={{
                            padding: 2,
                        }}
                        onClick={handleClose}
                        color={'secondary'}>
                        Cancel
                    </Button>

                    <Button
                        sx={{
                            padding: 2,
                        }}
                        onClick={handleImportPayments}
                        variant={'contained'}>
                        Sync
                    </Button>
                </div>
            </Box>
        </BaseModal>
    );
}

const useStyles = makeStyles()((theme) => ({
    bottomContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px',
        height: 70,
        width: '100%',
        padding: theme.spacing(3),
        backgroundColor: theme.palette.neutralWhite,
        borderRadius: '0px 0px 12px 12px',
    },
}));
