import React, { useState } from 'react';

import {
    AgriData,
    KeyDates,
    SocialMedia,
    Status,
} from '../../features/ViewLandownersDetails/LandPartnerDetails';
import { ContactAvatar } from '../../features/Notifications/NotificationCard';

import { Grid } from '@mui/material';
import ActionButton, {
    ActionMenuItem,
} from '../../design/Buttons/ActionButton';
import BasePieChart, {
    PieChartConfig,
} from '../../design/Charts/PieChart/BasePieChart';
import LastOutreachTable from '../LandPartners/LastOutreachTable';
import { notificationsChart } from '../../features/ViewDashboard/DashboardCharts';

import {
    useGetLandPartnersSummaryQuery,
    useGetNotificationsSummaryQuery,
} from '../../api/landpartners/landPartnersAPI';
import { chartValue } from '../LandPartners/LandPartnerProfilesPage';
import { useNavigate } from 'react-router-dom';
import AllProfilesTable, {
    ContactLandPartnerView,
    EmailStatus,
} from './AllProfilesTable';
import CustomizeViewModal from '../CustomizeViewModal';
import {
    useGetOneConfigQuery,
    useLazyGetOneConfigQuery,
} from '../../api/users/customizeConfigAPI';
import { initCustomViewForAllContacts } from '../customizeViewInitialStates';
import Loader from '../../design/BaseLoader';
import { PhoneType } from '../../reusable/FormUI/PhoneNumberField';
import PATHS from '../../router/paths';
import { useSelection } from '../../design/GenericTable/useSelection';
import {
    createDownloadLinkFromBlob,
    getFilenameFromContentDisposition,
} from '../../utils/fileHandler';
import { useLazyDownloadBulkContactsQuery } from '../../api/fileApiSlice';
import { Option } from '../Settings/SharingSettings/ShareDataSelectField';
import useManagePermission from '../../hooks/useManagePermission';
import useCustomizeView from '../../hooks/useCustomizeView';
import { typeOfAdvocacy } from '../../features/ViewAllProfiles/PrimaryContactInfoPanel/PrimaryContactMainInfo';

export interface ContactProfile {
    id: string;
    subEntityId?: string;
    organizationId?: string;
    firstName: string;
    lastName: string;
    landPartners: LinkedLandPartner[];
    status: Status;
    phoneNumber?: PhoneType[];
    primaryEmail?: string;
    secondaryEmail?: string;
    relationshipManager?: string;
    acres?: number;
    contactNumbers: PhoneType[];
    lastContactDate?: string;
    socialMedia?: SocialMedia;
    keyDates?: KeyDates;
    avatar?: ContactAvatar;
    age?: string;
    agriData: AgriData;
    relationship?: string;
    relationshipDescription?: string;
    contactRoles: string[] | Option[];
    powerOfAttorney?: boolean;
    preferredContactMethod?: string;
    addresses?: Address[];
    advocate?: typeOfAdvocacy[];
    primaryContact?: boolean;
    active?: boolean;
    subEntity?: LinkedLandPartner;
    primaryEmailStatus: EmailStatus;
    secondaryEmailStatus: EmailStatus;
}

export interface LinkedLandPartner {
    id: string;
    name: string;
    status: string;
    parentOrganizationId: string;
}

export interface Address {
    id: string;
    type: string;
    address1?: string;
    address2?: string;
    zipCode?: string;
    city?: string;
    state?: string;
}

export default function AllProfiles() {
    const navigate = useNavigate();
    const { data: notificationsChartData } = useGetNotificationsSummaryQuery(
        {},
    );
    const { checkPermission } = useManagePermission();
    const [isShowingCustomizeView, setIsShowingCustomizeView] = useState(false);
    const { data: chartData, isLoading: isChartsLoading } =
        useGetLandPartnersSummaryQuery({});
    const [getDocuments] = useLazyDownloadBulkContactsQuery();

    const { data: customizeConfig, isLoading: isLoadingCustomizeConfig } =
        useGetOneConfigQuery({ viewType: 'ALL_CONTACTS' });
    const [
        getData,
        { data: customizeConfigLazy, isLoading: isLoadingLazyCustomizeConfig },
    ] = useLazyGetOneConfigQuery();
    const { configData, isLoading, isFetched } = useCustomizeView({
        fetchedData: customizeConfigLazy || customizeConfig,
        isLoading: isLoadingLazyCustomizeConfig || isLoadingCustomizeConfig,
        initialTableSettings: initCustomViewForAllContacts,
        viewType: 'ALL_CONTACTS',
    });

    const {
        selected: selectedRows,
        isSelected,
        toggleSelection,
        toggleSelectionAll,
        isSelectedAll,
    } = useSelection<ContactLandPartnerView>();
    if (isChartsLoading) {
        return (
            <Grid
                container
                sx={{ height: '100vh' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    const documentsIds = () => {
        const ids = selectedRows.map((i) => `${i.id}`);
        return `contactIds=${ids.join(',')} `;
    };
    const handleDownload = async ({
        documentIds,
    }: {
        documentIds?: string;
    }) => {
        getDocuments(documentIds)
            .unwrap()
            .then(({ data, headers }) => {
                createDownloadLinkFromBlob(
                    data,
                    getFilenameFromContentDisposition(headers),
                );
            })
            .then(() => toggleSelectionAll([]));
    };
    const actionButtonItems: ActionMenuItem[] = [
        {
            label: 'Add a Profile',
            onClick: () =>
                navigate(
                    `/${PATHS.landpartners}/${PATHS.allProfiles}/${PATHS.create}`,
                ),
            hidden: !checkPermission('contacts', 'modify'),
        },

        {
            label: `Download Contacts (${selectedRows.length})`,
            onClick: () => handleDownload({ documentIds: documentsIds() }),
            hidden: !selectedRows.length,
        },
        {
            label: 'Customize View',
            onClick: () => {
                setIsShowingCustomizeView(true);
            },
        },

        {
            label: 'Create a Task',
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick: () => {},
        },

        {
            label: 'Set a Reminder',
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick: () => {},
        },
    ];

    return (
        <>
            <Grid container item xs={12} justifyContent={'space-between'}>
                <BasePieChart
                    data={totalContacts(chartData)}
                    config={chartConfigTotalContacts(chartData)}
                />
                <BasePieChart
                    data={totalUpEvents(notificationsChartData)}
                    config={pieChartConfigTotalUpEvents(notificationsChartData)}
                />
                <Grid item xs={5}>
                    <LastOutreachTable />
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent={'flex-end'}
                width={'100%'}
                sx={{
                    padding: 2,
                }}>
                <ActionButton label={'Actions'} items={actionButtonItems} />
            </Grid>
            <AllProfilesTable
                isLoading={
                    isLoadingCustomizeConfig ||
                    isLoadingLazyCustomizeConfig ||
                    isLoading
                }
                isSelected={isSelected}
                toggleSelection={toggleSelection}
                toggleSelectionAll={toggleSelectionAll}
                isSelectedAll={isSelectedAll}
                config={configData}
            />
            <CustomizeViewModal
                config={configData}
                viewType={'ALL_CONTACTS'}
                isShowing={isShowingCustomizeView}
                onClose={() => {
                    setIsShowingCustomizeView(false);
                    getData({ viewType: 'ALL_CONTACTS' });
                }}
                isFetched={isFetched}
            />
        </>
    );
}

function totalContacts(summaryData: chartValue) {
    return [
        { name: 'Active', value: summaryData?.activeRelationships || 0 },
        { name: 'Inactive', value: summaryData?.inactiveRelationships || 0 },
        { name: 'Prospect', value: summaryData?.prospectRelationships || 0 },
    ];
}

function totalUpEvents(notificationsChartData: notificationsChart) {
    return [
        {
            name: 'Birthdays',
            value: notificationsChartData?.birthdayCount || 0,
        },
        {
            name: 'Lease Renewals',
            value: notificationsChartData?.leaseRenewalCount || 0,
        },
        {
            name: 'Payments',
            value: notificationsChartData?.paymentCount || 0,
        },
        {
            name: 'Tasks',
            value: notificationsChartData?.taskCount || 0,
        },
    ];
}

function chartConfigTotalContacts(summaryData: chartValue): PieChartConfig {
    return {
        summaryTitle: 'Total Contacts',
        summaryValue: `${summaryData?.totalRelationships || 0}`,
        showLegend: true,
        dataKey: 'value',
        chartTitle: 'RELATIONSHIPS',
        showTooltip: true,
    };
}

function pieChartConfigTotalUpEvents(
    notificationsChartData: notificationsChart,
): PieChartConfig {
    return {
        summaryTitle: 'Events Total',
        summaryValue: `${notificationsChartData?.totalCount || 0}`,
        showLegend: true,
        dataKey: 'value',
        chartTitle: 'UPCOMING EVENTS',
        showTooltip: true,
    };
}
