import GenericTable from '../../../../design/GenericTable/GenericTable';
import { Box } from '@mui/material';
import TableName from '../TableName';
import useTableArchiveLPConfig from './useTableArchiveLPConfig';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import React from 'react';

export default function ArchiveLPTable() {
    const {
        data,
        tableConfig,
        setIsShowing,
        deleteData,
        handleDelete,
        isLoading,
        isProcessing,
        isShowing,
    } = useTableArchiveLPConfig();

    return (
        <>
            <Box>
                <TableName name="Land Partners" />
                <GenericTable
                    isLoading={isLoading}
                    data={data}
                    tableConfig={tableConfig}
                />
            </Box>
            <ConfirmDeleteModal
                isShowing={isShowing}
                onClose={() => setIsShowing(false)}
                handleConfirm={() => {
                    handleDelete(deleteData?.id);
                }}
                title={`Are you sure you want to delete ${deleteData?.landPartnerName}?`}
                info={'This action cannot be undone'}
                isProcessing={isProcessing}
            />
        </>
    );
}
