import React from 'react';
import { Box, Switch } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
    useGetPartnerServicesStatusQuery,
    useUpdatePartnerServicesStatusMutation,
} from '../../api/partnerServices/partnerServicesAPI';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';

export default function PartnerServicesToggle() {
    const { classes } = useStyles();

    const { data, isLoading, isFetching } = useGetPartnerServicesStatusQuery();

    const { handleSubmit } = useHandleSubmitRTK({
        useRtkHook: useUpdatePartnerServicesStatusMutation,
        isShowSuccessToast: false,
    });

    const handleToggle = () => {
        if (!data) return;
        handleSubmit({
            enabled: !data.enabled,
        });
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.toggleWrapper}>
                <Switch
                    checked={data?.enabled}
                    disabled={isLoading || isFetching}
                    onChange={handleToggle}
                    className={classes.toggleSwitch}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    color={'customToggle'}
                />
            </Box>
        </Box>
    );
}

const useStyles = makeStyles()((theme) => ({
    container: {
        position: 'relative',
        padding: theme.spacing(2),
        height: '100%',
    },
    toggleWrapper: {
        position: 'absolute',
        bottom: theme.spacing(0.2),
        right: theme.spacing(0.2),
        display: 'flex',
        alignItems: 'center',
    },
    toggleSwitch: {
        marginLeft: theme.spacing(1),
    },
}));
