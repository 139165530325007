import {
    useGetIntegrationsQuery,
    useLazyConnectQuickBooksQuery,
} from '../../../../api/integrations/integrationsAPI';
import customToastWithAlert from '../../../../design/CustomToastWithAlert';
import { useGetCurrentSubscriptionQuery } from '../../../../api/subscriptions/subscriptionsAPI';
import { IntegrationSettings } from '../interfaces';

export default function useQuickBooksIntegration(): IntegrationSettings {
    const [
        connectQuickBooks,
        { isLoading: isQBLoading, isFetching: isQBFetching },
    ] = useLazyConnectQuickBooksQuery();

    const {
        data: integrations,
        isLoading: isIntegrationsLoading,
        isFetching: isIntegrationsFetching,
    } = useGetIntegrationsQuery();

    const {
        data: subscriptionData,
        isLoading: isSubscriptionLoading,
        isFetching: isSubscriptionFetching,
    } = useGetCurrentSubscriptionQuery();

    const onConnect = async () => {
        try {
            const response = await connectQuickBooks().unwrap();

            if (response.qboUrl) {
                window.location.href = response.qboUrl;
            }
        } catch (err) {
            customToastWithAlert({
                type: 'error',
                message: 'Error connecting to QuickBooks',
            });
        }
    };

    return {
        status: integrations?.find((item) => item.type === 'QUICKBOOKS')?.active
            ? 'ACTIVE'
            : 'INACTIVE',
        isDisabled: !subscriptionData?.features?.quickBooks,
        onConnect,
        isLoading:
            isQBLoading ||
            isQBFetching ||
            isIntegrationsLoading ||
            isIntegrationsFetching ||
            isSubscriptionLoading ||
            isSubscriptionFetching,
    };
}
