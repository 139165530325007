import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import TwoColumnLayout from '../../layouts/TwoColumnLayout';
import ExpandableGroupStepper from '../../design/Stepper/ExpandableGroupStepper';
import EmergencyReadinessForm from './EmergencyReadinessForm';
import theme from '../../styles/theme';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
    useInitiateEmergencyReadinessMutation,
    useLazyGetEmergencyReadinessStatusQuery,
} from '../../api/emergencyReadiness/emergencyReadiness';
import Loader from '../../design/BaseLoader';

export default function EmergencyReadinessStepper() {
    const { state } = useLocation();

    const [pageNumber, setPageNumber] = useState(state?.stepNumber || 0);
    const [planId, setPlanId] = useState<string>();

    const [searchParams] = useSearchParams();
    const organizationId = searchParams.get('organizationId');

    const [
        getItems,
        { data: emergencyStat, isLoading: isEmergencyStatLoading },
    ] = useLazyGetEmergencyReadinessStatusQuery();

    const [handleInitiateEmergencyReadiness, { isLoading: isInitiate }] =
        useInitiateEmergencyReadinessMutation();

    useEffect(() => {
        if (organizationId) {
            getItems({ id: organizationId });
        }
    }, [organizationId]);
    useEffect(() => {
        if (
            ((emergencyStat && !emergencyStat?.exists) ||
                emergencyStat?.status === null) &&
            !!organizationId
        ) {
            handleInitiateEmergencyReadiness({ id: organizationId })
                .unwrap()
                .then((res) => {
                    setPlanId(res.id);
                });
        } else {
            emergencyStat?.id && setPlanId(emergencyStat?.id);
        }
    }, [
        emergencyStat?.exist,
        emergencyStat?.id,
        emergencyStat?.status,
        organizationId,
        isEmergencyStatLoading,
    ]);

    if (isEmergencyStatLoading || isInitiate) {
        return (
            <Grid
                width={'100%'}
                container
                height={'100vh'}
                alignItems={'center'}
                justifyContent={'center'}>
                <Loader />
            </Grid>
        );
    }
    return (
        <Box m={1} bgcolor={theme.palette.backgroundB.backgroundB1}>
            <TwoColumnLayout
                header={'Emergency Readiness'}
                type={'third'}
                leftComponent={
                    <Box p={4}>
                        <ExpandableGroupStepper
                            isNavigable={true}
                            setActiveStep={setPageNumber}
                            stepNumber={pageNumber}
                            stepGroups={stepGroups}
                        />
                    </Box>
                }
                rightComponent={
                    <EmergencyReadinessForm
                        planId={planId}
                        totalSteps={13}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                    />
                }
            />
        </Box>
    );
}
const stepGroups = [
    {
        groupLabel: 'Preparation and Response',
        steps: [
            'a. Emergency Contacts',
            'b. Risk Assessment',
            'c. Critical Resources and Processes',
            'd. Evacuation + Safety',
            'e. Communication',
        ],
    },
    {
        groupLabel: 'Resource and Risk Management',
        steps: [
            'a. Crop Protection',
            'b. Resource Managements',
            'c. Recovery Procedures',
            'd. Environmental Concerns',
        ],
    },
    {
        groupLabel: 'Legal, Financial and Environmental',
        steps: [
            'a. Insurance and Financial Preparedness',
            'b. External Assistance',
            'c. Training and Preparedness',
            'd. Continuity of Operations',
            'e. Preview',
        ],
    },
];
