import CollapsedComponent from '../../../design/CollapsedComponent';
import { Box } from '@mui/material';
import GenericTable, {
    TableColumn,
} from '../../../design/GenericTable/GenericTable';
import PermissionSwitch from './PermissionSwitch';
import { useEffect, useState } from 'react';
import ActionButtons from './ActionButtons';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../../router/paths';
import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import { useUpdatePermissionsByRoleMutation } from '../../../api/users/rolesAPI';

interface OwnProps {
    roleId: string;
    permissions: Permission[];
    isLoading: boolean;
    idOfEnabledPermissions: string[];
}

export interface Permission {
    [key: string]: {
        id: string;
        displayName: string;
        description: string;
        enabled: boolean;
    };
}

export default function PermissionTableList({
    permissions,
    isLoading,
    roleId,
    idOfEnabledPermissions,
}: OwnProps) {
    const navigate = useNavigate();
    const { handleSubmit, isLoading: isSaving } = useHandleSubmitRTK({
        useRtkHook: useUpdatePermissionsByRoleMutation,
        successMessage: 'Permissions updated successfully',
    });
    const [ids, setIds] = useState<string[]>([]);

    const permissionsKeys = Object.keys(permissions) || [];
    useEffect(() => {
        setIds(idOfEnabledPermissions);
    }, [idOfEnabledPermissions]);

    function handleReset() {
        navigate(`${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.roles}/`);
    }

    function handleSave() {
        handleSubmit({ id: roleId, body: ids });
    }

    const columns: TableColumn<Permission>[] = [
        {
            header: {
                label: 'Permissions',
                sortable: false,
            },
            cellRender: (data) => <>{data?.displayName}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '30%',
        },
        {
            header: {
                label: 'Access',
                sortable: false,
            },
            cellRender: (data) => (
                <PermissionSwitch permission={data} setIds={setIds} />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: '10%',
        },
        {
            header: {
                label: 'Comments',
                sortable: false,
            },
            cellRender: (data) => <>{data?.description}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '60%',
        },
    ];
    const tableConfig = {
        columns,
        pageable: false,
        expandable: false,
    };
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            {permissionsKeys?.map((key) => (
                <CollapsedComponent
                    key={key}
                    title={key}
                    isCollapsed={false}
                    type={'contrasted'}>
                    <GenericTable
                        isLoading={isLoading}
                        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        data={permissions[key]}
                        tableConfig={tableConfig}
                    />
                </CollapsedComponent>
            ))}
            <ActionButtons
                onCancel={handleReset}
                onSubmit={handleSave}
                isLoading={isSaving}
            />
        </Box>
    );
}
