import GenericTable from '../../../../design/GenericTable/GenericTable';
import { Box } from '@mui/material';
import TableName from '../TableName';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import React from 'react';
import useTableArchivedLeaseNotesConfig from './useTableArchivedLeaseNotesConfig';

export default function ArchivedLeaseNotesTable() {
    const {
        data,
        tableConfig,
        setIsShowing,
        deleteData,
        handleDelete,
        isLoading,
        isProcessing,
        isShowing,
        totalCount,
    } = useTableArchivedLeaseNotesConfig();

    return (
        <Box>
            <TableName name="Lease Notes" />
            <GenericTable
                isLoading={isLoading}
                data={data}
                totalCount={totalCount}
                tableConfig={tableConfig}
            />
            <ConfirmDeleteModal
                isShowing={isShowing}
                onClose={() => setIsShowing(false)}
                handleConfirm={() => {
                    handleDelete(deleteData?.id);
                }}
                title={'Are you sure you want to delete this lease note?'}
                info={'This action cannot be undone'}
                isProcessing={isProcessing}
            />
        </Box>
    );
}
