import React, { useState } from 'react';
import {
    Box,
    Collapse,
    Grid,
    IconButton,
    ThemeProvider,
    Typography,
    useTheme,
} from '@mui/material';
import ActionButton, {
    ActionMenuItem,
} from '../../design/Buttons/ActionButton';
import GenericTable from '../../design/GenericTable/GenericTable';
import { ReactComponent as ExpandIcon } from '../../assets/icons/expand_icon.svg';

import { useGetPaymentScheduleQuery } from '../../api/payment/paymentAPI';
import { useParams } from 'react-router-dom';
import { convertResponseToModel, tableScheduleSummaryConfig } from './utils';
import clsx from 'clsx';

import { tableTheme } from '../../styles/tableTheme';
import UploadPaymentFile from './actions/UploadPaymentFile';
import { useStyles } from './LeasePayment.styles';

import { useLeasePayment } from './useLeasePayment';
import AddExpenseModal from './AddExpenseModal';
import { FsaId } from './useFieldTable';

export interface PaymentSchedule {
    rentPerAcreRate: number;
    year: number;
    fsaIdPaymentDetails: FsaIdPaymentDetail[];
    yearlyTotal: DateAmount[];
    totalPayment: number;
}

export interface TransformedPaymentData {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    landowners: string[];
    id: string;
    leaseId: string;
    fsaId: string;
    tfpNumber: string[];
    landName: string;
    totalAcres: number;
    tillableAcres: number;
    rentPerAcreRate: number;
    totalAnnualRent: number;
    description: string;
    [key in string]: number;
    splitPaymentData: {
        [key in string]: string;
    };
    year: number;
    total: number;
}

export interface FsaIdPaymentDetail {
    paymentDistributionDateAmounts: DistributionDateAmounts[];
    fsaId: FsaId;
    dateAmounts: DateAmount[];
}
export interface DistributionDateAmounts {
    amount: number;
    landownerId: string;
    landownerName: string;
    paymentDate: string;
}

export interface DateAmount {
    paymentDate: number;
    amount: number;
}

export default function LeasePayments() {
    const theme = useTheme();
    const { id } = useParams();
    const [expanded, setExpanded] = useState(true);
    const { classes } = useStyles(theme);
    const [isImportCSVShowing, setIsImportCSVShowing] = useState(false);
    const [isExpenseShowing, setIsExpenseShowing] = useState(false);
    const { data, totalCount, tableConfig, isLoading } = useLeasePayment();

    const { data: paymentsData, isLoading: isPaymentsDataLoading } =
        useGetPaymentScheduleQuery({
            leaseId: id,
        });

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    function handleExpenseOpen() {
        setIsExpenseShowing(true);
    }

    function handleExpenseClose() {
        setIsExpenseShowing(false);
    }

    const { paymentData: tableData, keys } = convertResponseToModel(
        paymentsData || [],
    );

    const actionButtonItems: ActionMenuItem[] = [
        {
            label: 'Upload Payment File',
            onClick: () => {
                setIsImportCSVShowing(true);
            },
        },
        {
            label: 'Add an Expense',
            onClick: () => {
                handleExpenseOpen();
            },
        },
    ];

    return (
        <div>
            <Grid
                container
                gap={1}
                p={2}
                sx={{ backgroundColor: theme.palette.neutralWhite }}>
                <Typography
                    variant={'font20'}
                    fontWeight="medium"
                    color={'text.secondaryContrast'}>
                    Payment Schedule
                </Typography>
                <Box className={classes.expandHeader}>
                    <Typography
                        variant={'font12'}
                        color={'text.link'}
                        fontWeight={'bold'}>
                        Rent Schedule:
                    </Typography>
                    <div>
                        <Typography
                            variant={'font12'}
                            color={'text.link'}
                            fontWeight={'medium'}>
                            {expanded ? 'Collapse' : 'Expand'}
                        </Typography>
                        <IconButton onClick={handleExpandClick}>
                            <ExpandIcon
                                className={clsx(expanded && classes.expanded)}
                            />
                        </IconButton>
                    </div>
                </Box>
                <Collapse
                    in={expanded}
                    timeout="auto"
                    unmountOnExit
                    sx={{ width: '100%', paddingBottom: theme.spacing(4) }}>
                    <ThemeProvider theme={tableTheme}>
                        <GenericTable
                            data={tableData}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            tableConfig={tableScheduleSummaryConfig(keys)}
                        />
                    </ThemeProvider>
                </Collapse>
            </Grid>

            <div className={classes.titleWrapper}>
                <Typography variant="font32" fontWeight="regular">
                    Payments
                </Typography>
                <ActionButton label={'Actions'} items={actionButtonItems} />
            </div>
            <GenericTable
                data={data}
                isLoading={isPaymentsDataLoading || isLoading}
                totalCount={totalCount}
                tableConfig={tableConfig}
            />
            {isImportCSVShowing && (
                <UploadPaymentFile
                    leaseId={id}
                    isShowing={isImportCSVShowing}
                    setIsShowing={setIsImportCSVShowing}
                />
            )}
            {isExpenseShowing && (
                <AddExpenseModal
                    isShowing={isExpenseShowing}
                    handleClose={handleExpenseClose}
                    initData={{ paymentDate: new Date(), amount: 0 }}
                />
            )}
        </div>
    );
}
