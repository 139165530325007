import React from 'react';
import { CommunicationContainer } from '../../../reusable/NotesField/NotesField';
import {
    useAddLeaseNoteMutation,
    useGetLeaseNotesQuery,
} from '../../../api/leases/leaseNotesAPI';
import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import Chat from '../../../reusable/Chat/Chat';
import { dateTimeConverterFromUTC } from '../../../utils/dateFormat';
import { NoteDetailsProps } from '../../../reusable/Chat/NoteDetails';
import { DocumentResponse } from '../../../api/documents/documentsAPI';

export interface LeaseNoteProps {
    id: string;
    createdBy: string;
    updatedBy: string;
    createdAt: string;
    updatedAt: string;
    pinned: boolean;
    leaseId: string;
    content: string;
    document: DocumentResponse[];
    createdByName: string;
    communicationContainer: CommunicationContainer;
}

interface OwnProps {
    id?: string;
}

export default function LeaseNotes({ id }: OwnProps) {
    const { data: notes, isLoading: isLoadingNotes } = useGetLeaseNotesQuery({
        leaseId: id,
        page: 0,
        size: 2000,
    });
    const { handleSubmit: addNote, isLoading: isAddingNote } =
        useHandleSubmitRTK({
            useRtkHook: useAddLeaseNoteMutation,
            successMessage: 'Note added successfully',
        });

    function handleAddNote(
        note: string,
        file: File | null,
        communicationContainer: CommunicationContainer,
    ) {
        const submitValue = {
            leaseId: id,
            note: { content: note, communicationContainer },
            noteFile: file,
        };
        addNote(submitValue);
    }

    return (
        <Chat
            addNote={handleAddNote}
            cards={convertResponseToNote(notes)}
            isLoadingNotes={isLoadingNotes}
            isAddingNote={isAddingNote}
            type={'LEASE'}
            autoFocus={false}
        />
    );
}

function convertResponseToNote(response: LeaseNoteProps[]): NoteDetailsProps[] {
    return response?.map((note) => ({
        id: note.id,
        pinned: note.pinned,
        content: note.content,
        name: note.createdByName,
        actualContactDate: note.communicationContainer?.actualContactDate,
        communicationType: note.communicationContainer?.communicationType,
        createdAt: dateTimeConverterFromUTC(note.createdAt),
        updatedAt: dateTimeConverterFromUTC(note.updatedAt),
        attachment: note.document.length
            ? {
                  id: note.document[0]?.id,
                  date: dateTimeConverterFromUTC(note.document[0]?.createdAt),
                  name: note.document[0]?.fileName,
                  size: note.document[0]?.fileSize,
                  link: note.document[0]?.fileLocation,
              }
            : undefined,
    }));
}
