import React from 'react';
import { Form } from 'react-final-form';
import { Box } from '@mui/material';
import theme from '../../../styles/theme';
import FormPage from '../../../reusable/FormUI/FormPage';
import Loader from '../../../design/BaseLoader';
import SubmitButton from '../../../design/Buttons/SubmitButton';
import { ContactLandPartnerView } from '../../../pages/AllProfiles/AllProfilesTable';
import { FormConfig } from '../../../design/Forms/interfaces';
import { composeValidators, required } from '../../../utils/validation';
import { AutocompleteOption } from '../../../design/Fields/AutocompleteField';
import { Option } from '../../../pages/Settings/SharingSettings/ShareDataSelectField';
import { useGetAllContactsQuery } from '../../../api/contacts/contactsAPI';
import { FormValues } from './RequestESignatureModal';

interface OwnProps {
    handleSubmit: (values: FormValues) => void;
    isHidden: boolean;
    isRequesting: boolean;
}

export default function RequestSignatureForm({
    handleSubmit,
    isHidden,
    isRequesting,
}: OwnProps) {
    const { data, isLoading } = useGetAllContactsQuery({
        page: 0,
        size: 2000,
    });

    if (isHidden) {
        return null;
    }
    return (
        <Form initialValues={{}} onSubmit={handleSubmit}>
            {({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        p={2}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        rowGap={2}
                        bgcolor={theme.palette.backgroundB.backgroundB1}>
                        {!isLoading ? (
                            <FormPage
                                formConfig={formConfig({
                                    contacts: data?.data || [],
                                })}
                            />
                        ) : (
                            <Loader />
                        )}
                    </Box>
                    <Box
                        display={'flex'}
                        justifyContent={'flex-end'}
                        p={3}
                        borderRadius={'0px 0px 12px 12px'}
                        bgcolor={theme.palette.neutralWhite}>
                        <SubmitButton
                            isDisabled={invalid}
                            isLoading={isRequesting}
                            text={'Send'}
                        />
                    </Box>
                </form>
            )}
        </Form>
    );
}
interface Config {
    contacts: ContactLandPartnerView[];
}
function formConfig({ contacts }: Config): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'full',
                name: 'contacts',
                type: 'autocomplete',
                label: 'Request electronic signature from:',
                isRequired: true,
                validation: composeValidators(required),
            },
            renderProps: {
                isDisabled: false,
                getOptionDisabled: (option: AutocompleteOption) => {
                    return !option?.email;
                },
                options: convertContactToOption(contacts),
            },
        },
        {
            formField: {
                scheme: 'full',
                name: 'note',
                type: 'textarea',
                label: 'Add a note',
            },
            renderProps: {
                isDisabled: false,
                resize: 'none',
                minRows: 3,
            },
        },
        {
            formField: {
                scheme: 'full',
                name: 'completeBy',
                type: 'datepicker',
                label: 'Complete by',
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
                format: 'MMM dd, yyyy',
            },
        },
    ];
}

function convertContactToOption(contacts: ContactLandPartnerView[]): Option[] {
    return contacts.map((contact) => ({
        id: contact.id,
        name: `${contact.firstName} ${contact.lastName} - ${contact?.primaryEmail || 'Email address not available'}`,
        email: contact.primaryEmail,
    }));
}
