import useDocumentsTable, {
    DocumentTable as DocumentTableProps,
} from './useDocumentsTable';
import GenericTable from '../../../design/GenericTable/GenericTable';
import TableSkeleton from '../../../design/Skeleton/TableSkeleton';
import ModifyDocumentModal from './ModifyDocumentModal';
import PreviewModal from '../../../reusable/Modal/PreviewModal';
import React, { useEffect } from 'react';

interface OwnProps {
    setSelectedDocuments?: (
        selectedDocuments:
            | Record<string, DocumentTableProps[]>
            | ((
                  prev: Record<string, DocumentTableProps[]>,
              ) => Record<string, DocumentTableProps[]>),
    ) => void;
}

export default function DocumentTable({ setSelectedDocuments }: OwnProps) {
    const {
        tableConfig,
        documentData,
        isShowing,
        handleClose,
        isLoading,
        isDocumentLoading,
        totalCount,
        documents,
        isShowingDocument,
        file,
        closeDocumentModal,
        fileExtension,
        fileName,
        selectedRows,
    } = useDocumentsTable();

    useEffect(() => {
        if (setSelectedDocuments) {
            setSelectedDocuments((prev) => ({
                ...prev,
                documents: selectedRows.map((row) => row),
            }));
        }
    }, [selectedRows]);

    if (isLoading) {
        return <TableSkeleton />;
    }

    return (
        <div>
            <GenericTable
                data={documents}
                tableConfig={tableConfig}
                totalCount={totalCount}
            />
            <ModifyDocumentModal
                documentData={documentData}
                isShowing={isShowing}
                handleClose={handleClose}
            />
            {fileExtension && (
                <PreviewModal
                    extension={fileExtension}
                    fileName={fileName}
                    file={file}
                    isLoading={isDocumentLoading}
                    isShowing={isShowingDocument}
                    onClose={closeDocumentModal}
                />
            )}
        </div>
    );
}
