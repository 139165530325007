import React from 'react';
import { Box, Typography } from '@mui/material';
import InfoWithLabel from '../../../reusable/InfoWithLabel';
import { ReactComponent as EmailIcon } from '../../../assets/icons/communications_icon.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone_icon.svg';
import { ReactComponent as BuildingIcon } from '../../../assets/icons/building_icon.svg';
import { phoneMaskForString } from '../../../utils/fieldMasks';
import LinkText from '../../../design/Fields/LinkText';
import { phoneTypeMapper } from '../../../pages/utils';
import { PrimaryContactData } from './PrimaryContactInfoPanel';
import EmailVerificationButton from '../../../pages/AllProfiles/EmailVerificationButton';

interface OwnProps {
    primaryContactData: PrimaryContactData;
}

export default function PrimaryContactInfo({ primaryContactData }: OwnProps) {
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={1}>
            <Typography
                textTransform={'capitalize'}
                variant={'font12'}
                color={'text.neutral7'}>
                INFO
            </Typography>
            <InfoWithLabel
                icon={EmailIcon}
                text={
                    primaryContactData?.email ? (
                        <Box
                            display={'flex'}
                            columnGap={1}
                            alignItems={'center'}>
                            <LinkText
                                to={`mailto:${primaryContactData?.email}`}
                                text={primaryContactData?.email}
                                fontWeight={'normal'}
                            />
                            <EmailVerificationButton
                                id={primaryContactData.id}
                                isHidden={!primaryContactData.email}
                                emailStatus={primaryContactData.emailStatus}
                            />
                        </Box>
                    ) : (
                        'N/A'
                    )
                }
            />
            {primaryContactData?.phone?.map((phone) => {
                return (
                    <InfoWithLabel
                        key={phone.type}
                        icon={PhoneIcon}
                        text={`${
                            phoneTypeMapper[phone?.type]
                        }: ${phoneMaskForString(phone?.number)}`}
                    />
                );
            })}
            <InfoWithLabel
                icon={BuildingIcon}
                text={primaryContactData?.address}
            />
        </Box>
    );
}
