import { Box, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
    useDeleteUserMutation,
    useGetUsersQuery,
    useLazyGetUsersQuery,
} from '../../../../api/users/usersAPI';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit_icon.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/remove_icon.svg';
import GenericTable, {
    Table,
} from '../../../../design/GenericTable/GenericTable';
import { useSorting } from '../../../../design/GenericTable/useSorting';
import useModal from '../../../../hooks/useModal';
import ConfirmModal from '../../../../layouts/ConfirmModal/ConfirmModal';
import AddUser from '../AddUser/AddUser';
import { usePagination } from '../../../../design/GenericTable/useBackendPagination';
import useHandleSubmitRTK from '../../../../hooks/useHandleSubmit';
import Loader from '../../../../design/BaseLoader';
import { useSaveSettings } from '../../../../hooks/useSaveSettings';
import { useSettingsFromStorage } from '../../../../hooks/useSettingsFromStorage';
import { dateTimeConverterFromUTC } from '../../../../utils/dateFormat';

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    primaryEmail: string;
    secondaryEmail?: string;
    phoneNumber: string;
    organization: ShortResponse;
    role: ShortResponse;
    createdAt: string;
    lastLoggedInAt?: string;
}

export interface ShortResponse {
    id: string;
    name: string;
}

export default function UsersList() {
    const { settings, saveSettings } = useSettingsFromStorage('ALL_USERS');
    const { sort, page, sortDirection, size } = settings;

    const { isShowing: editIsShowing, setIsShowing: setEditIsShowing } =
        useModal();

    const { isShowing: deleteIsShowing, setIsShowing: setDeleteIsShowing } =
        useModal();
    const [id, setId] = useState<string | null>(null);

    const { handleSubmit: deleteUser, isLoading } = useHandleSubmitRTK({
        useRtkHook: useDeleteUserMutation,
        onSuccess: () => {
            setDeleteIsShowing(false);
            setId(null);
        },
        successMessage: 'User was deactivated successfully',
    });

    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'firstName',
        sortDirection || 'asc',
    );
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);
    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });
    const { data: initData } = useGetUsersQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });

    const [getItems, { data: sortedData }] = useLazyGetUsersQuery();

    useEffect(() => {
        getItems({
            sort: `${sortKey},${sortOrder}`,
            page: currentPage - 1,
            size: itemsPerPage,
        });
    }, [sortKey, sortOrder, currentPage, itemsPerPage]);

    const onDelete = (id: string) => {
        id && setId(id);
        setDeleteIsShowing(true);
    };

    const handleDelete = (id: string) => {
        deleteUser(id);
    };

    const onEdit = (id: string) => {
        id && setId(id);
        setEditIsShowing(true);
    };

    const data = useMemo(() => {
        return sortedData?.data || initData?.data || [];
    }, [sortedData, initData]);

    const totalCount = useMemo(() => {
        return sortedData?.totalCount || initData?.totalCount || 0;
    }, [sortedData, initData]);

    const findUser = (id: string) => {
        return data?.find((item: User) => item.id === id);
    };

    const userTableConfig: Table<User> = {
        columns: [
            {
                header: {
                    label: 'First, Last',
                    sortable: true,
                    sorting: {
                        id: 'firstName',
                        direction: sortKey === 'firstName' ? sortOrder : 'desc',
                        isSorted: sortKey === 'firstName',
                    },
                    onClick: handleSort,
                },
                cellRender: (user: User) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {user.firstName}&nbsp;{user.lastName}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Organization',
                    sortable: true,
                    sorting: {
                        id: 'organizationName',
                        direction:
                            sortKey === 'organizationName' ? sortOrder : 'desc',
                        isSorted: sortKey === 'organizationName',
                    },
                    onClick: handleSort,
                },
                cellRender: (user: User) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {user.organization?.name}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Email',
                    sortable: true,
                    sorting: {
                        id: 'primaryEmail',
                        direction:
                            sortKey === 'primaryEmail' ? sortOrder : 'desc',
                        isSorted: sortKey === 'primaryEmail',
                    },
                    onClick: handleSort,
                },
                cellRender: (user: User) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {user.primaryEmail}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Role',
                    sortable: true,
                    sorting: {
                        id: 'roleName',
                        direction: sortKey === 'roleName' ? sortOrder : 'desc',
                        isSorted: sortKey === 'roleName',
                    },
                    onClick: handleSort,
                },
                cellRender: (user: User) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {user.role && user.role.name}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Create Date',
                    sortable: true,
                    sorting: {
                        id: 'createdAt',
                        direction: sortKey === 'createdAt' ? sortOrder : 'desc',
                        isSorted: sortKey === 'createdAt',
                    },
                    onClick: handleSort,
                },
                cellRender: (user: User) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {dateTimeConverterFromUTC(user.createdAt)}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Last Active',
                    sortable: false,
                },
                cellRender: (user: User) =>
                    user.lastLoggedInAt && (
                        <Typography
                            variant={'font14'}
                            color={'text.link'}
                            fontWeight={'medium'}>
                            {dateTimeConverterFromUTC(user.lastLoggedInAt)}
                        </Typography>
                    ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Actions',
                    sortable: false,
                },
                cellRender: (user: User) => (
                    <Box>
                        {user.organization && user.role.name !== 'ADMIN' && (
                            <>
                                <IconButton onClick={() => onEdit(user.id)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => onDelete(user.id)}>
                                    <RemoveIcon />
                                </IconButton>
                            </>
                        )}
                    </Box>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        multiselect: false,
        expandable: false,
        handlePageChange: handlePageChange,
        handleItemsPerPageChange: handleItemsPerPageChange,
        initialPage: page,
        initialPageSize: size,
    };

    if (isLoading) {
        return (
            <Grid
                container
                sx={{ height: '100%' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <>
            <GenericTable
                data={data}
                totalCount={totalCount}
                tableConfig={userTableConfig}
            />
            {id && (
                <AddUser
                    isShowing={editIsShowing}
                    setIsShowing={setEditIsShowing}
                    id={id}
                />
            )}
            {id && (
                <ConfirmModal
                    isShowing={deleteIsShowing}
                    hide={() => setDeleteIsShowing(false)}
                    title={`Are you sure you want to delete ${
                        findUser(id)?.firstName
                    }?`}
                    info={`You are about to delete  ${
                        // eslint-disable-next-line max-lines
                        findUser(id)?.firstName
                    }. This action cannot be undone`}
                    onSubmit={() => handleDelete(id)}
                />
            )}
        </>
    );
}
