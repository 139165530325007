import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import theme from '../../styles/theme';

interface OwnProps {
    children: ReactNode;
    width?: string;
}
export default function CardWrapper({ children, width = 'auto' }: OwnProps) {
    return (
        <Box
            display={'flex'}
            flexWrap={'wrap'}
            width={width}
            pt={3}
            pb={3}
            pl={2}
            pr={2}
            borderRadius={1}
            border={`1px solid ${theme.palette.limeGreen.limeGreenShade2}`}>
            {children}
        </Box>
    );
}
