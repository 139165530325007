import { OAKENApi } from '../OAKENApi';
import { ENDPOINTS } from '../endpoints';
import { HTTP_METHOD } from '../auth/http';
import { FIELDS, LEASES } from '../tags';
import { FromUser } from '../../reusable/AddNotesModal';
import { DocumentResponse } from '../documents/documentsAPI';
import { getListProvidesTags } from '../generic';

interface ArchivedFieldNoteTransformed {
    data: ArchivedFieldNoteResponse[];
    totalCount: number;
}

export interface ArchivedFieldNoteResponse {
    id: string;
    content: string;
    communicationType: string;
    actualContactDate: number;
    updatedAt: number;
    createdAt: number;
    archivedBy: {
        id: string;
        firstName: string;
        lastName: string;
    };
}

interface AddFieldNoteArgs {
    addFieldNoteRequest: AddFieldNoteRequest;
    attachment?: File;
}

interface AddFieldNoteRequest {
    fieldId: string;
    communicationType: string;
    content: string;
    actualContactDate: number;
}

export interface FieldNoteResponse {
    id: string;
    communicationType: string;
    content: string;
    actualContactDate: number;
    createdAt: string;
    updatedAt: string;
    createdByUser: FromUser;
    pinned: boolean;
    attachments: DocumentResponse[];
}

interface GetFieldNotesQueryParams {
    fieldId: string;
    params: PageableRequestParams;
}

interface PageableRequestParams {
    page: number;
    size: number;
    sort?: string;
}

export interface UpdateFieldNoteArgs {
    id: string;
    body: UpdateFieldNoteRequest;
}

interface UpdateFieldNoteRequest {
    communicationType: string;
    content: string;
    actualContactDate: string;
}

export const fieldNotesAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getFieldNotes: builder.query<
            FieldNoteResponse[],
            GetFieldNotesQueryParams
        >({
            query: ({ fieldId, params }) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.fields.notes.root +
                        `/${fieldId}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            providesTags: [FIELDS.FIELD_NOTES],
        }),
        addFieldNotes: builder.mutation<void, AddFieldNoteArgs>({
            query: ({ addFieldNoteRequest, attachment }) => {
                const formData = new FormData();
                formData.append(
                    'addFieldNoteRequest',
                    new Blob([JSON.stringify(addFieldNoteRequest)], {
                        type: 'application/json',
                    }),
                );
                attachment && formData.append('attachment', attachment);

                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.fields.notes.root,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
            invalidatesTags: [FIELDS.FIELD_NOTES, LEASES.FIELD],
        }),
        updateFieldNote: builder.mutation<void, UpdateFieldNoteArgs>({
            query: (payload) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.fields.notes.root +
                        `/${payload.id}`,
                    method: HTTP_METHOD.PUT,
                    body: payload.body,
                };
            },
            invalidatesTags: [FIELDS.FIELD_NOTES],
        }),
        pinFieldNote: builder.mutation<void, string>({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.fields.notes.pin +
                        `/${id}`,
                    method: HTTP_METHOD.PUT,
                };
            },
            invalidatesTags: [FIELDS.FIELD_NOTES],
        }),
        unpinFieldNote: builder.mutation<void, string>({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.fields.notes.unpin +
                        `/${id}`,
                    method: HTTP_METHOD.PUT,
                };
            },
            invalidatesTags: [FIELDS.FIELD_NOTES],
        }),
        archiveFieldNote: builder.mutation<void, string>({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.fields.notes.archive +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [FIELDS.FIELD_NOTES],
        }),
        getArchivedFieldNotes: builder.query<
            ArchivedFieldNoteTransformed,
            PageableRequestParams
        >({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.fields.notes.archived,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: ArchivedFieldNoteResponse[],
                meta,
            ): ArchivedFieldNoteTransformed => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, FIELDS.FIELD_NOTES),
        }),
        restoreFieldNote: builder.mutation<void, string>({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.fields.notes.restore +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [FIELDS.FIELD_NOTES],
        }),
        deleteFieldNote: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.fields.notes.archived +
                        `/${id}`,
                    method: HTTP_METHOD.DELETE,
                };
            },
            invalidatesTags: [FIELDS.FIELD_NOTES],
        }),
    }),
});

export const {
    useGetFieldNotesQuery,
    useAddFieldNotesMutation,
    useUpdateFieldNoteMutation,
    usePinFieldNoteMutation,
    useUnpinFieldNoteMutation,
    useArchiveFieldNoteMutation,
    useGetArchivedFieldNotesQuery,
    useRestoreFieldNoteMutation,
    useDeleteFieldNoteMutation,
} = fieldNotesAPI;
