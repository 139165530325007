import Paths from '../../router/paths';

export interface EmergencyReadinessResponse {
    id: string;
    status: string;
    organization: Organization;
    createdAt: string;
    updatedAt: string;
    percentageCompleted: number;
    lastActiveStep: number;
}
export type ActiveServices = 'COMPLETED' | 'IN_PROGRESS';
export type OrganizationType = 'GROWER' | 'LAND_PARTNER';
export interface Organization {
    id: string;
    name: string;
    status: string;
    type: OrganizationType;
    parentOrganizationId: string;
}
export const pathMapper: Record<OrganizationType, string> = {
    GROWER: Paths.growers,
    LAND_PARTNER: Paths.profiles,
};
