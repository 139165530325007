import React, { useMemo, useState } from 'react';
import GenericTable, { Table } from '../../design/GenericTable/GenericTable';
import { Box, ThemeProvider, Typography } from '@mui/material';
import UserAvatar from '../../design/Avatar/UserAvatar';
import SelectField from '../../design/Fields/SelectField';
import { dashedTableTheme } from '../../styles/dashedTableTheme';
import LastOutreachTypeLabels from './LastOutreachTypeLabels';
import {
    useGetLastOutreachQuery,
    useLazyGetLastOutreachQuery,
} from '../../api/landpartners/landPartnersAPI';

import PATHS from '../../router/paths';
import LinkText from '../../design/Fields/LinkText';
import { dateTimeConverterFromUTC } from '../../utils/dateFormat';
import useAvatar from '../../hooks/useAvatar';

export interface LastOutreach {
    id: string;
    contact: ContactShort;
    lastContactDate: string;
    landPartner: LandPartnerShort;
    type: OutreachType;
}

interface LandPartnerShort {
    id: string;
    name: string;
}

interface ContactShort {
    id: string;
    firstName: string;
    lastName: string;
    avatar?: ContactAvatar;
}

interface ContactAvatar {
    id: string;
    fileName: string;
    fileType: string;
    contactAvatar: string;
}

export type OutreachType = 'EMAIL' | 'NOTES';

const filterOptions = [
    { name: 'Latest', id: '0' },
    { name: '30 Days ago', id: '30' },
    { name: '60 Days ago', id: '60' },
    { name: '90 Days ago', id: '90' },
    { name: '120 Days ago', id: '120' },
];

export default function LastOutreachTable() {
    const [filter, setFilter] = useState<string>('0');
    const { data: initData, isLoading } = useGetLastOutreachQuery({
        period: filter,
    });
    const [getItems, { data, isLoading: isLazyLoading }] =
        useLazyGetLastOutreachQuery();

    const tableData = useMemo(() => {
        return data || initData;
    }, [data, initData]);

    const { createBlobUrl } = useAvatar();

    const lastOutreachTableConfig: Table<LastOutreach> = {
        columns: [
            {
                header: {
                    label:
                        'Contacts' +
                        (tableData?.totalCount
                            ? ' (Total Notes = ' + `${tableData?.totalCount})`
                            : ''),
                    sortable: false,
                },
                cellRender: (item) => (
                    <Box display={'flex'} alignItems={'center'} columnGap={1}>
                        <UserAvatar
                            name={`${item.contact.firstName} ${item.contact.lastName}`}
                            avatar={
                                item.contact.avatar?.contactAvatar &&
                                item.contact.avatar?.fileType &&
                                createBlobUrl(
                                    item.contact.avatar?.contactAvatar,
                                    item.contact.avatar?.fileType,
                                )
                            }
                            size={'small'}
                        />
                        <LinkText
                            to={`/${PATHS.landpartners}/${PATHS.allProfiles}/${item?.contact?.id}`}
                            text={`${item.contact.firstName} ${item.contact.lastName}`}
                        />
                    </Box>
                ),
                maxColumnWidth: 120,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },

            {
                header: {
                    label: 'Last Contact Date',
                    sortable: false,
                },
                cellRender: (item) => (
                    <Typography variant={'font12'} color={'text.darkTurqTint5'}>
                        {item?.lastContactDate &&
                            dateTimeConverterFromUTC(item?.lastContactDate)}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: ' Land Partner',
                    sortable: false,
                },
                cellRender: (item) => (
                    <LinkText
                        to={`/${PATHS.landpartners}/${PATHS?.profiles}/${item?.landPartner?.id}`}
                        text={item?.landPartner?.name}
                        fontWeight={'normal'}
                    />
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Action',
                    sortable: false,
                },
                cellRender: (item) => <LastOutreachTypeLabels item={item} />,
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        multiselect: false,
        expandable: false,
        pageable: false,
    };

    return (
        <Box flexDirection={'column'} display={'flex'} rowGap={2}>
            <Box
                width={'100%'}
                pt={1}
                display={'flex'}
                justifyContent={'space-between'}
                color={'text.secondary'}>
                <Typography
                    variant={'font16'}
                    fontWeight={'bold'}
                    textTransform={'uppercase'}>
                    Last Outreach
                </Typography>
                <Box>
                    <SelectField
                        size={'small'}
                        height={36}
                        onChange={(id) => {
                            setFilter(id);
                            getItems({ period: id });
                        }}
                        value={filter}
                        options={filterOptions}
                        fullWidth={true}
                    />
                </Box>
            </Box>
            <ThemeProvider theme={dashedTableTheme}>
                <GenericTable
                    isLoading={isLoading || isLazyLoading}
                    data={tableData?.lastOutreach || []}
                    tableConfig={lastOutreachTableConfig}
                />
            </ThemeProvider>
        </Box>
    );
}
