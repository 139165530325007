import {
    useGetIntegrationsQuery,
    useLazyConnectDocuSignQuery,
} from '../../../../api/integrations/integrationsAPI';
import { IntegrationSettings } from '../interfaces';
import customToastWithAlert from '../../../../design/CustomToastWithAlert';

export default function useDocuSignIntegration(): IntegrationSettings {
    const [
        connectDocuSign,
        { isLoading: isDocuSignLoading, isFetching: isDocuSignFetching },
    ] = useLazyConnectDocuSignQuery();

    const {
        data: integrations,
        isLoading: isIntegrationsLoading,
        isFetching: isIntegrationsFetching,
    } = useGetIntegrationsQuery();

    const handleConnect = async () => {
        try {
            const response = await connectDocuSign().unwrap();

            if (response.docusignUrl) {
                window.location.href = response.docusignUrl;
            }
        } catch (err) {
            customToastWithAlert({
                type: 'error',
                message: 'Error connecting to Docusign',
            });
        }
    };
    return {
        status: integrations?.find((item) => item.type === 'DOCUSIGN')?.active
            ? 'ACTIVE'
            : 'INACTIVE',
        isDisabled: false,

        onConnect: handleConnect,
        isLoading:
            isDocuSignLoading ||
            isIntegrationsLoading ||
            isDocuSignFetching ||
            isIntegrationsFetching,
    };
}
