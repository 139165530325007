import useGrowersTable from './useGrowersTable';
import GenericTable from '../../design/GenericTable/GenericTable';
import ActionButton, {
    ActionMenuItem,
} from '../../design/Buttons/ActionButton';
import PATHS from '../../router/paths';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useManagePermission from '../../hooks/useManagePermission';
import {
    createDownloadLinkFromBlob,
    getFilenameFromContentDisposition,
} from '../../utils/fileHandler';

import CustomizeViewModal from '../CustomizeViewModal';
import useCustomizeView from '../../hooks/useCustomizeView';
import { initCustomViewForGrowers } from '../customizeViewInitialStates';

import {
    useGetOneConfigQuery,
    useLazyGetOneConfigQuery,
} from '../../api/users/customizeConfigAPI';
import { useLazyDownloadBulkGrowerQuery } from '../../api/growers/growersAPI';

export default function GrowersTable() {
    const navigate = useNavigate();

    const [
        getData,
        { data: customizeConfigLazy, isLoading: isLoadingLazyCustomizeConfig },
    ] = useLazyGetOneConfigQuery();

    const { data: customizeConfig, isLoading: isLoadingCustomizeConfig } =
        useGetOneConfigQuery({ viewType: 'GROWERS' });

    const { configData, isLoading, isFetched } = useCustomizeView({
        fetchedData: customizeConfigLazy || customizeConfig,
        isLoading: isLoadingLazyCustomizeConfig || isLoadingCustomizeConfig,
        initialTableSettings: initCustomViewForGrowers,
        viewType: 'GROWERS',
    });
    const [getDocuments] = useLazyDownloadBulkGrowerQuery();
    const [isShowingCustomizeView, setIsShowingCustomizeView] = useState(false);
    const { totalCount, data, tableConfig, selectedRows, toggleSelectionAll } =
        useGrowersTable({ config: configData });
    const documentsIds = () => {
        const ids = selectedRows.map((i) => `${i.id}`);
        return `growerIds=${ids.join(',')} `;
    };
    const handleDownload = async ({
        documentIds,
    }: {
        documentIds?: string;
    }) => {
        getDocuments(documentIds)
            .unwrap()
            .then(({ data, headers }) => {
                createDownloadLinkFromBlob(
                    data,
                    getFilenameFromContentDisposition(headers),
                );
            })
            .then(() => toggleSelectionAll([]));
    };
    const { checkPermission } = useManagePermission();
    const actionButtonItems: ActionMenuItem[] = [
        {
            label: 'Add a Grower',
            onClick: () => {
                navigate(`${PATHS.create}`, { state: 'GROWER' });
            },
            hidden: !checkPermission('growers', 'modify'),
        },
        {
            label: 'Customize View',
            onClick: () => {
                setIsShowingCustomizeView(true);
            },
        },
        {
            label: `Download Growers (${selectedRows.length})`,
            onClick: () => handleDownload({ documentIds: documentsIds() }),
            hidden: !selectedRows.length,
        },
        {
            label: `Download All Growers`,
            onClick: () => handleDownload({}),
        },
    ];

    return (
        <div>
            <Grid
                container
                justifyContent={'flex-end'}
                width={'100%'}
                sx={{
                    padding: 2,
                }}>
                <ActionButton label={'Actions'} items={actionButtonItems} />
            </Grid>
            <GenericTable
                isLoading={
                    isLoadingCustomizeConfig ||
                    isLoadingLazyCustomizeConfig ||
                    isLoading
                }
                data={data}
                tableConfig={tableConfig}
                totalCount={totalCount}
            />
            {isShowingCustomizeView && (
                <CustomizeViewModal
                    config={configData}
                    viewType={'GROWERS'}
                    isShowing={isShowingCustomizeView}
                    onClose={() => {
                        setIsShowingCustomizeView(false);
                        getData({ viewType: 'GROWERS' });
                    }}
                    isFetched={isFetched}
                />
            )}
        </div>
    );
}
