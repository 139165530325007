import PreviewFormWrapper from './PreviewFormWrapper';
import { RiskAssessment } from '../model';
import { Box } from '@mui/material';
import CountListWithTitle from './CountLiistWithTiltle';

interface OwnProps {
    data: RiskAssessment;
}
export default function RiskAssessmentPreview({ data }: OwnProps) {
    return (
        <PreviewFormWrapper label={'Risk Assessment'}>
            <Box display={'flex'} columnGap={2} width={'100%'}>
                <CountListWithTitle
                    title={'Natural'}
                    listData={data?.naturalDisasters}
                />

                <CountListWithTitle
                    title={'Man Made'}
                    listData={data?.manMadeDisasters}
                />
            </Box>
        </PreviewFormWrapper>
    );
}
