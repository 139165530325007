import { useGetCurrentSubscriptionQuery } from '../../../../api/subscriptions/subscriptionsAPI';

interface JohnDeereIntegrationData {
    isDisabled: boolean;
    isLoading: boolean;
}

function useJohnDeereIntegration(): JohnDeereIntegrationData {
    const {
        data: subscriptionData,
        isLoading: isSubscriptionLoading,
        isFetching: isSubscriptionFetching,
    } = useGetCurrentSubscriptionQuery();

    return {
        isDisabled: !subscriptionData?.features?.johnDeereOps,
        isLoading: isSubscriptionLoading || isSubscriptionFetching,
    };
}

export default useJohnDeereIntegration;
