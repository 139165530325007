import React, { useState } from 'react';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import { useUpdateLeaseFieldsMutation } from '../../api/leases/leaseAPI';
import BaseModal from '../../reusable/BaseModal';
import { Box, Button, Divider, Tooltip } from '@mui/material';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy_icon.svg';
import theme from '../../styles/theme';
import AppTypography from '../../design/AppTypography';
import copy from 'copy-to-clipboard';
import { Form } from 'react-final-form';
import FormPage from '../../reusable/FormUI/FormPage';
import SubmitButton from '../../design/Buttons/SubmitButton';
import { makeStyles } from 'tss-react/mui';
import { FormConfig } from '../../design/Forms/interfaces';
import {
    composeValidators,
    isMapLink,
    maxLength,
    minLength,
    required,
} from '../../utils/validation';
import { FsaId } from './useFieldTable';

interface OwnProps {
    handleClose: () => void;
    isShowing: boolean;
    legalDescription?: string;
    initData?: FsaId;
    landId?: string;
}

export default function AddFieldModal({
    handleClose,
    isShowing,
    legalDescription,
    landId,
    initData,
}: OwnProps) {
    const { classes } = useStyles();
    const [copied, setCopied] = useState(false);

    const { handleSubmit, isLoading } = useHandleSubmitRTK({
        useRtkHook: useUpdateLeaseFieldsMutation,
        successMessage: 'Field added successfully',
        onSuccess: () => {
            handleClose();
        },
    });

    function handleCopy() {
        if (legalDescription) {
            copy(legalDescription);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }
    }

    function handleFieldSubmit(values: FsaId) {
        const cleanLink = extractLink(values.mapEmbedCode || '');
        handleSubmit({
            leaseLandId: landId,
            mapEmbedCode: cleanLink,
        });
    }

    return (
        <BaseModal
            size={'small'}
            isShowing={isShowing}
            onClose={handleClose}
            header={'Field Details'}>
            <>
                <Box
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={2}
                    bgcolor={theme.palette.backgroundB.backgroundB1}
                    p={theme.spacing(2, 3)}>
                    <Box display={'flex'} alignItems={'center'} columnGap={1}>
                        <Box
                            display={'flex'}
                            width={'80%'}
                            flexDirection={'column'}
                            rowGap={1}
                            flexGrow={1}>
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                columnGap={1}>
                                <AppTypography
                                    variant={'font12'}
                                    color={'text.neutral10'}
                                    fontWeight={'regular'}
                                    text={'Legal Description'}
                                />
                                {legalDescription && (
                                    <Tooltip
                                        title={
                                            copied
                                                ? 'Copied!'
                                                : 'Copy Legal Description'
                                        }
                                        arrow>
                                        <Button
                                            onClick={handleCopy}
                                            size="small"
                                            endIcon={<CopyIcon />}
                                            className={classes.copyButton}>
                                            Copy
                                        </Button>
                                    </Tooltip>
                                )}
                            </Box>
                            <AppTypography
                                variant="font12"
                                color="text.neutral9"
                                fontWeight="regular"
                                text={legalDescription || 'N/A'}
                            />
                        </Box>
                    </Box>
                    <Form
                        onSubmit={handleFieldSubmit}
                        initialValues={{
                            mapEmbedCode: initData?.mapEmbedCode,
                        }}>
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} id="fieldForm">
                                <FormPage formConfig={formLandConfig} />
                            </form>
                        )}
                    </Form>
                </Box>
                <Divider orientation="horizontal" />
                <div className={classes.bottomContainer}>
                    <Button onClick={handleClose} color={'secondary'}>
                        Cancel
                    </Button>
                    <SubmitButton
                        form={'fieldForm'}
                        variant={'text'}
                        isLoading={isLoading}
                        text={'Save'}
                    />
                </div>
            </>
        </BaseModal>
    );
}

const useStyles = makeStyles()((theme) => ({
    bottomContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px',
        height: 70,
        width: '100%',
        padding: theme.spacing(3),
        backgroundColor: theme.palette.neutralWhite,
        borderRadius: '0px 0px 12px 12px',
    },
    copyButton: {
        minWidth: 'auto',
        padding: theme.spacing(0.5, 1),
        fontSize: '12px',
        textTransform: 'none',
    },
}));

const formLandConfig: FormConfig[] = [
    {
        formField: {
            name: 'mapEmbedCode',
            type: 'textarea',
            label: 'Map Link or Embed Code',
            isRequired: true,
            tooltip: {
                text: 'Paste a map link or embed code here. Both formats are supported.',
            },
            validation: composeValidators(
                minLength(1),
                maxLength(1024),
                isMapLink,
                required,
            ),
        },
        renderProps: {
            placeholder:
                'Supported Maps: Acres.com. Paste either a map link or embed code here.',
            resize: 'none',
            isDisabled: false,
        },
    },
];

function extractLink(input: string): string {
    const iframeMatch = input.match(/<iframe[^>]+src="([^"]+)"[^>]*>/);
    if (iframeMatch) {
        return iframeMatch[1];
    }
    return input;
}
