import { TableColumn } from '../../../design/GenericTable/GenericTable';
import { usePotentialClientsQuery } from '../../../api/users/organizationsAPI';
import { PhoneType } from '../../../reusable/FormUI/PhoneNumberField';
import ExpandablePhoneList from '../../../pages/AllProfiles/ExpandablePhoneList';
import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import LinkText from '../../../design/Fields/LinkText';
import Paths from '../../../router/paths';

export interface PotentialClient {
    id: string;
    name: string;
    type: string;
    contactNumbers: PhoneType[];
    email: string;
    solution: string;
    updatedAt: string;
}

export default function usePotentialClientsTable() {
    const { data, isLoading } = usePotentialClientsQuery({
        page: 0,
        size: 5,
        sort: 'updatedAt,desc',
    });

    const columns: TableColumn<PotentialClient>[] = [
        {
            header: {
                label: 'Name',
                sortable: false,
            },
            cellRender: (data) => potentialClientName(data),
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                label: 'Phone',
                sortable: false,
            },
            cellRender: (data) =>
                !!data?.contactNumbers?.length && (
                    <ExpandablePhoneList phoneList={data.contactNumbers} />
                ),
            format: { align: 'left', color: { color: 'primary' } },
            width: '19%',
        },
        {
            header: {
                label: 'Email',
                sortable: false,
            },
            cellRender: (data) => <>{data?.email}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '16%',
        },
        {
            header: {
                label: 'Solution',
                sortable: false,
            },
            cellRender: () => <>Emergency Readiness</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '16%',
        },
    ];
    const tableConfig = {
        columns,
        pageable: false,
        expandable: false,
    };
    return { data: data || [], tableConfig, isLoading };
}

const pathMapper = {
    LAND_PARTNER: 'profiles',
    GROWER: 'growers',
};

const typeTextMapper: Record<string, string> = {
    GROWER: 'Grower',
    LAND_PARTNER: 'Land Partner',
    LANDOWNER: 'Landowner',
    SUCCESSION_PLANNERS: 'Succession Planner',
    FARMLAND_MANAGER: 'Farmland Manager',
};

const potentialClientName = (data: PotentialClient) => {
    const { type, id, name } = data;

    const displayType = typeTextMapper[type] || type;
    const isLinkableType = type === 'GROWER' || type === 'LAND_PARTNER';

    return (
        <Box display="flex" alignItems="center" columnGap={1}>
            {isLinkableType ? (
                <LinkText
                    to={`/${Paths.landpartners}/${pathMapper[type]}/${id}`}
                    text={name}
                    variant="font14"
                />
            ) : (
                <Tooltip
                    title={`We don't have representation for this organization type: ${displayType}`}>
                    <Typography variant="font14">{name}</Typography>
                </Tooltip>
            )}
        </Box>
    );
};
