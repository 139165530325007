import React from 'react';

import { Box, Paper } from '@mui/material';

import AppTypography from '../../design/AppTypography';
import SmileLabel from '../../design/Labels/SmileLabel';
import theme from '../../styles/theme';

export default function EmptyScreen() {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                width: '100%',
                height: 'calc(100vh - 120px)',
                color: '#fff',
                padding: theme.spacing(10, 3),
                background: 'rgba(46,121,141)',
            }}>
            <Paper
                elevation={3}
                sx={{
                    padding: 3,
                    borderRadius: '16px',
                    width: '100%',
                    display: 'flex',
                    paddingRight: 12,
                    flexDirection: 'column',
                    rowGap: 4,
                }}>
                <Box display={'flex'} columnGap={4} alignItems={'center'}>
                    <SmileLabel />
                    <AppTypography
                        variant="font36"
                        fontWeight={'light'}
                        color="text.neutral9"
                        text={'Oooops'}
                    />
                </Box>

                <AppTypography
                    variant="font20"
                    fontWeight={'regular'}
                    color="text.neutral9"
                    flexWrap={true}
                    text={
                        'There are no Partner Services available at this time. '
                    }
                />
                <AppTypography
                    variant="font20"
                    fontWeight={'regular'}
                    color="text.neutral9"
                    flexWrap={true}
                    text={'Please contact your Oaken Administrator'}
                />
            </Paper>
        </Box>
    );
}
