import { Guideline } from './HazardousForm';
import { Box, Grid } from '@mui/material';
import theme from '../../../styles/theme';
import AppTypography from '../../../design/AppTypography';
import ReactPlayer from 'react-player';

interface OwnProps {
    guideline: Guideline;
}

export default function HazardousPreview({ guideline }: OwnProps) {
    return (
        <Box p={theme.spacing(2)}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                fontWeight={'regular'}
                rowGap={2}>
                <AppTypography
                    variant={'font16'}
                    color={'text.neutral9'}
                    text={guideline.eventName}
                />
                <AppTypography
                    flexWrap={true}
                    variant={'font14'}
                    color={'text.neutral9'}
                    text={guideline.description}
                />
                <Grid container xs={12} spacing={1} pt={2}>
                    <Grid item direction={'column'} rowGap={1} xs={6} p={1}>
                        {guideline.safetyVideos?.[0] && (
                            <ReactPlayer
                                url={guideline?.safetyVideos?.[0]}
                                width="380px"
                                height="280px"
                                light={true}
                                controls
                            />
                        )}
                        <AppTypography
                            flexWrap={true}
                            variant={'font14'}
                            color={'text.neutral9'}
                            text={guideline.safetyVideos?.[0]}
                        />
                    </Grid>
                    <Grid item direction={'column'} rowGap={1} xs={6} p={1}>
                        {guideline.safetyVideos?.[1] && (
                            <ReactPlayer
                                url={guideline.safetyVideos?.[1]}
                                width="380px"
                                height="280px"
                                light={true}
                                controls
                            />
                        )}
                        <AppTypography
                            flexWrap={true}
                            variant={'font14'}
                            color={'text.neutral9'}
                            text={guideline.safetyVideos?.[1]}
                        />
                    </Grid>
                    <Grid item direction={'column'} rowGap={1} xs={6} p={1}>
                        {guideline.safetyVideos?.[2] && (
                            <ReactPlayer
                                url={guideline.safetyVideos?.[2]}
                                width="380px"
                                height="280px"
                                light={true}
                                controls={true}
                            />
                        )}
                        <AppTypography
                            flexWrap={true}
                            variant={'font14'}
                            color={'text.neutral9'}
                            text={guideline.safetyVideos?.[2]}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
