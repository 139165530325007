import React, { useEffect } from 'react';
import WizardForm from '../../design/Forms/WizardForm';
import LeaseDetailForm, { FSA } from './LeaseDetailForm';

import ExpensesDetailForm, { Expenses } from './ExpensesDetailForm';
import PreviewCrop from './PreviewСrop';
import LeaseUploadFormPageWrapper from './LeaseUploadFormPageWrapper';
import arrayMutators from 'final-form-arrays';
import {
    getCommonDraftInitialValues,
    getCommonInitialValues,
    validateFsaStep,
} from './utils';

import Loader from '../../design/BaseLoader';
import { Grid } from '@mui/material';
import { Lease } from '../../pages/Leases/TableConfig';

import { useLeaseWizard } from './useLeaseWizard';
import { useLeaseMutation } from './useLeaseMutation';
import CropSplit from './CropSplitForm';

interface OwnProps {
    pageNumber: number;
    setPageNumber: (page: number) => void;
    setIsValid: (isValid: boolean) => void;
    setIsNavigable: (isNavigable: boolean) => void;
}

export default function LeaseWizardCropForm({
    pageNumber,
    setPageNumber,
    setIsValid,
    setIsNavigable,
}: OwnProps) {
    const {
        state,
        fsaData,
        setFsaData,
        data,
        isLoading,
        isFetching,
        isEdit,
        isFetchingOne,
        isLoadingOne,
        initData,
        expensesData,
        setExpensesData,
    } = useLeaseWizard(pageNumber, setIsNavigable);
    const {
        handleSubmitDraft,
        dataCreate,
        handleUpdateDraft,
        isCreating,
        isUpdating,
        submitValues,
        handleSubmit,
    } = useLeaseMutation(fsaData, expensesData);

    function initialValues() {
        const doSplitVary = (data: Lease) => {
            if (
                data?.cropShareDistributions?.[0]?.crop &&
                data?.cropShareDistributions?.[0]?.crop !== 'ALL'
            ) {
                return 'YES';
            }
            if (!data?.cropShareDistributions?.[0]?.crop) {
                return undefined;
            }
            return 'NO';
        };
        if (isEdit) {
            return {
                ...initData,
                ...getCommonInitialValues(initData),
                doSplitVary: doSplitVary(initData),
            };
        }
        if (state?.isCopy) {
            return {
                ...state,
                doSplitVary: doSplitVary(state),
                ...getCommonDraftInitialValues(data, state),
            };
        }
        return {
            verbalLease: 'false',
            autoRenewal: false,
            type: 'CROPSHARE',
            grower: data.organization.id || '',
            doLumpSum: 'paymentsByField',
            cropShareDistributions: [],
            cropDeliveryType: 'PROCEEDS_FROM_SALE',
        };
    }
    useEffect(() => {
        if (initData) {
            const fsaIds = initData?.fsaIds?.map((item: FSA) => {
                return {
                    ...item,
                    tableId: item.id,
                };
            });
            setFsaData(fsaIds);
            const expensesIds = initData?.expenses?.map((item: Expenses) => {
                return {
                    ...item,
                    tableId: item.id,
                };
            });
            setExpensesData(expensesIds);
        }
    }, [initData]);

    const handleSubmitForm = ({
        leaseFile,
        supportingFile,
        ...values
    }: Lease) =>
        handleSubmit({
            leaseFile,
            supportingFile,
            ...values,
            id: !values.id ? dataCreate?.id : values.id,
        });
    const handleDraft = ({ leaseFile, supportingFile, ...values }: Lease) => {
        if (state?.isCopy) {
            handleUpdateDraft({
                id: dataCreate?.id || initialValues().id,
                ...submitValues({
                    leaseFile,
                    supportingFile,
                    ...values,
                }),
            });
            return;
        }
        handleSubmitDraft(
            submitValues({
                leaseFile,
                supportingFile,
                ...values,
            }),
        );
    };

    if (isFetchingOne || isLoadingOne) {
        return (
            <Grid
                width={'100%'}
                container
                alignItems={'center'}
                justifyContent={'center'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <WizardForm
            isDraft={!isEdit}
            onDraftSubmit={handleDraft}
            mutators={arrayMutators as never}
            isLoading={isCreating || isUpdating || isLoading || isFetching}
            setIsValid={setIsValid}
            onStepValidate={() => validateFsaStep(pageNumber, fsaData)}
            initialValues={initialValues()}
            onSubmit={handleSubmitForm}
            setStep={setPageNumber}
            step={pageNumber}>
            <LeaseUploadFormPageWrapper data={data?.organization} />
            <LeaseDetailForm setTableData={setFsaData} tableData={fsaData} />
            <CropSplit />
            <ExpensesDetailForm
                setTableData={setExpensesData}
                tableData={expensesData}
            />
            <PreviewCrop
                setPageNumber={setPageNumber}
                fsaTableData={fsaData}
                expensesTableData={expensesData}
            />
        </WizardForm>
    );
}
