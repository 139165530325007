import { Option } from '../../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';
import { HandleSubmitData, QboBill, SubmitData } from './QuickBookDataImport';
import { PaymentScheduledData, QuickBookBills } from '../interfaces';
import { Lease } from '../../../pages/Leases/TableConfig';
import { IntegrationData } from './IntegrationDataCard';

export function convertPaymentDateToOptions(
    data?: PaymentScheduledData[],
): Option[] {
    if (!data) return [];
    return data?.map((item) => ({
        id: item.id,
        name: item.paymentDate,
    }));
}
export function convertLeaseToOptions(data?: Lease[]): Option[] {
    if (!data) return [];
    return data?.map((item) => ({
        id: item.id,
        name: item.leaseName,
    }));
}
export function convertDataToIntegration(
    data: QuickBookBills,
): IntegrationData {
    return {
        billId: data.billId,
        payee: data.vendorName,
        date: data.dueDate,
        value: data.amount,
    };
}

export function convertStateToHandleSubmitData(
    state: QboBill[],
): HandleSubmitData[] {
    return state.map((item) => {
        return {
            billId: item.billId,
            paymentScheduleId: '',
            balance: item.balance,
        };
    });
}

export function validIfPaymentExist(value: HandleSubmitData[]): boolean {
    return value.every((item) => item.paymentScheduleId !== '');
}
export function validIfPaymentUnique(value: HandleSubmitData[]): boolean {
    return value.every(
        (item, index, array) =>
            array.findIndex(
                (t) => t.paymentScheduleId === item.paymentScheduleId,
            ) === index,
    );
}
export function convertStateAndHandleSubmitDataToSubmitData(
    state: QboBill[],
    submitData: HandleSubmitData[],
): SubmitData {
    return {
        qboBillPaymentSchedulePairs: state.map((item) => {
            const paymentScheduleId = submitData.find(
                (data) => data.billId === item.billId,
            )?.paymentScheduleId;
            return {
                qboBill: item,
                paymentScheduleId: paymentScheduleId || '',
            };
        }),
    };
}
