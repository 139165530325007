import PreviewFormWrapper from './PreviewFormWrapper';
import { EvacuationSafety } from '../model';

import { Box, Typography } from '@mui/material';

import TextWithTitle from '../../../reusable/TextWithTitle';

import FileWithDownloadButton from '../../../reusable/FileWithDownloadButton';

interface OwnProps {
    data: EvacuationSafety;
}
export default function EvacuationAndSafetyPreview({ data }: OwnProps) {
    const evacuationPlanFile = data?.evacuationPlanFile;
    const safeAreaFile = data?.safeAreaFile;

    return (
        <PreviewFormWrapper label={'Evacuation and Safety'}>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}>
                <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'space-between'}>
                    <Box width={'50%'}>
                        <TextWithTitle
                            title={'Workers and Family Evacuation'}
                            value={data?.evacuationPlan}
                        />
                    </Box>
                    <Box width={'50%'}>
                        <Typography variant={'font14'} fontWeight="bold">
                            {'Documents'}
                        </Typography>
                        {evacuationPlanFile?.id && (
                            <FileWithDownloadButton
                                isRemovable={false}
                                id={evacuationPlanFile?.id}
                                name={evacuationPlanFile?.fileName}
                                size={evacuationPlanFile?.fileSize}
                                date={evacuationPlanFile?.createdAt}
                            />
                        )}
                    </Box>
                </Box>
                <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'space-between'}>
                    <Box width={'50%'}>
                        <TextWithTitle
                            title={'Designated safe area on property'}
                            value={data?.safeArea}
                        />
                    </Box>
                    <Box width={'50%'}>
                        <Typography variant={'font14'} fontWeight="bold">
                            {'Documents'}
                        </Typography>
                        {safeAreaFile?.id && (
                            <FileWithDownloadButton
                                isRemovable={false}
                                id={safeAreaFile?.id}
                                name={safeAreaFile?.fileName}
                                size={safeAreaFile?.fileSize}
                                date={safeAreaFile?.createdAt}
                            />
                        )}
                    </Box>
                </Box>

                <Box
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={2}>
                    <Typography variant={'font14'} fontWeight="bold">
                        Personnel Accountability Plan
                    </Typography>
                    <Typography variant="font12" fontWeight={'regular'}>
                        {data?.personnelAccounting}
                    </Typography>
                </Box>
                <Box
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={2}>
                    <Typography variant={'font14'} fontWeight="bold">
                        Location of emergency supplies (first aid kits, fire
                        extinguishers)
                    </Typography>
                    <Typography variant="font12" fontWeight={'regular'}>
                        {data?.emergencySuppliesLocation}
                    </Typography>
                </Box>
            </Box>
        </PreviewFormWrapper>
    );
}
