import React from 'react';
import UserAvatar, { AvatarFormat } from '../design/Avatar/UserAvatar';
import useProfilePhoto from '../hooks/useProfilePhoto';
import { Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { createBlobUrl } from '../utils/dataConvertor';

interface OwnProps {
    id: string;
    type: AvatarType;
    avatarFormat: AvatarFormat;
}

export type AvatarType = 'USER' | 'CONTACT';

export default function ProfilePhoto({ id, avatarFormat, type }: OwnProps) {
    const { avatar, name, size } = avatarFormat;
    const { data, isLoading } = useProfilePhoto({ id, type });

    if (isLoading) return <LoadingAvatarSkeleton size={size} />;

    function avatarMapper() {
        if (type === 'USER') {
            return (
                data.profilePhoto &&
                data.fileType &&
                createBlobUrl(data.profilePhoto, data.fileType)
            );
        }
        if (type === 'CONTACT') {
            return (
                data.avatarData &&
                data.fileType &&
                createBlobUrl(data.avatarData, data.fileType)
            );
        }
    }

    if (data)
        return <UserAvatar avatar={avatarMapper()} name={name} size={size} />;
    return <UserAvatar avatar={avatar} name={name} size={size} />;
}

function LoadingAvatarSkeleton({ size }: { size: AvatarFormat['size'] }) {
    const { classes } = useStyles();
    return (
        <Skeleton
            className={clsx(
                size === 'small' && classes.smallAvatar,
                size === 'preMedium' && classes.preMediumAvatar,
                size === 'medium' && classes.mediumAvatar,
                size === 'large' && classes.largeAvatar,
                size === 'xlarge' && classes.xlargeAvatar,
            )}
        />
    );
}
const useStyles = makeStyles()(() => ({
    smallAvatar: { width: 24, height: 24, fontSize: 12 },
    preMediumAvatar: { width: 32, height: 32 },
    mediumAvatar: { width: 40, height: 40 },
    largeAvatar: { width: 60, height: 60 },
    xlargeAvatar: { width: 90, height: 90 },
}));
