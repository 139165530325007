import React from 'react';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone_icon.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/communications_icon.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/pin_location_icon.svg';
import { Box, Button, Paper } from '@mui/material';

import AppTypography from '../../design/AppTypography';

import { EmergencyContactData } from './model';
import { Address } from '../AllProfiles/AllProfiles';
import UserAvatar from '../../design/Avatar/UserAvatar';
import InfoWithLabel from '../../reusable/InfoWithLabel';
import LinkText from '../../design/Fields/LinkText';
import { phoneTypeMapper } from '../utils';
import { phoneMaskForString } from '../../utils/fieldMasks';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';

interface OwnProps {
    data: EmergencyContactData['contact'];
    handleRemoveContact: () => void;
}

export default function ContactFormCard({
    data,
    handleRemoveContact,
}: OwnProps) {
    const { classes } = useStyles();

    function getAddress(address: Address) {
        return (
            address &&
            [
                address.address1,
                address.address2,
                address.city,
                address.state,
                address.zipCode,
            ]
                .filter(Boolean)
                .join(', ')
        );
    }

    return (
        <Paper className={classes.container}>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} columnGap={1}>
                    <UserAvatar name={`${data?.firstName} ${data?.lastName}`} />
                    <Box display={'flex'} flexDirection={'column'}>
                        <AppTypography
                            variant={'font20'}
                            fontWeight="medium"
                            color="text.secondary"
                            text={`${data?.firstName} ${data?.lastName}`}
                        />
                    </Box>
                </Box>
                <Button variant={'outlined'} onClick={handleRemoveContact}>
                    Remove
                </Button>
            </Box>

            <InfoWithLabel
                icon={EmailIcon}
                text={
                    data?.primaryEmail ? (
                        <LinkText
                            to={`mailto:${data.primaryEmail}`}
                            text={data.primaryEmail}
                            fontWeight={'medium'}
                        />
                    ) : (
                        'N/A'
                    )
                }
            />
            {data?.contactNumbers?.map(
                (phone: {
                    type: React.Key | null | undefined;
                    number: string;
                }) => {
                    return (
                        <InfoWithLabel
                            key={phone.type}
                            icon={PhoneIcon}
                            text={`${phoneTypeMapper[phone?.type as string]}
                                        : ${phoneMaskForString(phone?.number)}`}
                        />
                    );
                },
            )}

            {data?.addresses
                ?.map(getAddress)
                .map((address) => (
                    <InfoWithLabel
                        key={address}
                        icon={LocationIcon}
                        text={address}
                    />
                ))}
        </Paper>
    );
}

const useStyles = makeStyles()(() => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 200,
        border: ` solid 1px ${theme.palette.neutral.neutral3}`,
        gap: theme.spacing(1),
        padding: theme.spacing(2),
        boxShadow: theme.shadows[1],
    },
}));
