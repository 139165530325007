import { Box } from '@mui/material';
import { useFormState } from 'react-final-form';
import EmergencyContactsPreview from './EmergencyContactsPreview';
import RecoveryProceduresContactsPreview from './RecoveryProceduresContactsPreview';
import RiskAssessmentPreview from './RiskAssessmentPreview';
import CriticalResourcesAndProcessesPreview from './CriticalResourcesAndProcessesPreview';
import EvacuationAndSafetyPreview from './EvacuationAndSafetyPreview';
import CommunicationPlanPreview from './CommunicationPlanPreview';
import CropProtectionContactsPreview from './CropProtectionContactsPreview';
import ResourceManagementContactsPreview from './ResourceManagementContactsPreview';
import InsuranceFinancialContactsPreview from './InsuranceFinancialContactsPreview';
import InsuranceFinancialPreview from './InsuranceFinancialPreview';
import ResourceManagementPreview from './ResourceManagementPreview';
import RecoveryProcedurePreview from './RecoveryProcedurePreview';
import ContinuityOperationsPreview from './ContinuityOperationsPreview';
import GuidelinePreview from './GuidelinePreview';
import ExternalAssistanceContactsPreview from './ExternalAssistanceContactsPreview';

export default function PreviewPage() {
    const { values } = useFormState();

    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2} p={2}>
            <EmergencyContactsPreview contacts={values?.emergencyContacts} />
            <RecoveryProceduresContactsPreview
                primaryLead={values?.recoveryProcedures?.primaryLead}
                secondaryLead={values?.recoveryProcedures?.secondaryLead}
            />
            <CropProtectionContactsPreview data={values?.cropProtection} />
            <ResourceManagementContactsPreview
                data={values?.resourceManagement}
            />
            <InsuranceFinancialContactsPreview
                insuranceAgent={values?.insurancePreparedness?.insuranceAgent}
                banker={values?.insurancePreparedness?.banker}
            />
            <ExternalAssistanceContactsPreview
                usdaFarmContact={values?.externalAssistance?.usdaFarmContact}
                agronomist={values?.externalAssistance?.agronomist}
            />
            <RiskAssessmentPreview data={values?.riskAssessment} />
            <CriticalResourcesAndProcessesPreview
                data={values?.criticalResources}
            />
            <EvacuationAndSafetyPreview data={values?.evacuationSafety} />
            <CommunicationPlanPreview data={values?.communicationPlan} />
            <ResourceManagementPreview data={values?.resourceManagement} />
            <RecoveryProcedurePreview data={values?.recoveryProcedures} />
            <GuidelinePreview data={values?.environmentalConcerns} />
            <InsuranceFinancialPreview data={values?.insurancePreparedness} />
            <ContinuityOperationsPreview data={values?.continuityOperations} />
        </Box>
    );
}
