import GenericTable from '../../design/GenericTable/GenericTable';
import { usePaymentOverviewPaid } from './usePaymentOverviewPaid';

export default function PaymentOverviewPaid() {
    const { data, totalCount, tableConfig, isLoading } =
        usePaymentOverviewPaid();

    return (
        <GenericTable
            isLoading={isLoading}
            data={data}
            totalCount={totalCount}
            tableConfig={tableConfig}
        />
    );
}
