import { Button } from '@mui/material';
import React, { useState } from 'react';
import theme from '../../styles/theme';
import { ReactComponent as ArchiveIcon } from '../../assets/icons/remove_icon.svg';
import ConfirmModal from '../../layouts/ConfirmModal/ConfirmModal';
import { makeStyles } from 'tss-react/mui';
import useManagePermission from '../../hooks/useManagePermission';
import { NoteType } from './NoteCard';

interface OwnProps {
    noteId: string;
    onClick: (noteId: string) => void;
    type: NoteType;
}

export default function ArchiveButton({ noteId, onClick, type }: OwnProps) {
    const { classes } = useStyles();
    const { checkPermission } = useManagePermission();
    const [isConfirmModalShowing, setIsConfirmModalShowing] = useState(false);

    const onClose = () => {
        setIsConfirmModalShowing(false);
    };

    const handleArchive = () => {
        onClick(noteId);
        onClose();
    };

    if (type === 'CONTACT' && !checkPermission('contacts', 'modify')) {
        return null;
    }
    if (
        (type === 'LEASE' || type === 'FIELD') &&
        !checkPermission('leases', 'modify')
    ) {
        return null;
    }

    return (
        <>
            <Button
                sx={{
                    fontSize: 12,
                    color: theme.palette.statusRedTint,
                    '&:hover': {
                        color: theme.palette.statusRed,
                    },
                }}
                endIcon={<ArchiveIcon />}
                onClick={() => setIsConfirmModalShowing(true)}
                className={classes.button}>
                Archive
            </Button>
            {isConfirmModalShowing && (
                <ConfirmModal
                    isShowing={isConfirmModalShowing}
                    hide={onClose}
                    title={'Please confirm you want to archived this Note'}
                    info={''}
                    onSubmit={handleArchive}
                />
            )}
        </>
    );
}

const useStyles = makeStyles()(() => ({
    button: {
        minWidth: 'fit-content',
        textTransform: 'capitalize',
    },
}));
