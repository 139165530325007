import { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton } from '@mui/material';
import InfoDrawer from './InfoDrawer';

export default function InfoButton() {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    function handleDrawerOpen() {
        setIsDrawerOpen(true);
    }

    function handleDrawerClose() {
        setIsDrawerOpen(false);
    }

    return (
        <>
            <IconButton
                size="small"
                onClick={handleDrawerOpen}
                sx={{
                    color: 'text.link',
                    '&:hover': {
                        bgcolor: 'backgroundB.backgroundB3',
                    },
                }}>
                <InfoOutlinedIcon />
            </IconButton>
            <InfoDrawer
                isDrawerOpen={isDrawerOpen}
                handleDrawerClose={handleDrawerClose}
            />
        </>
    );
}
