import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { useSorting } from '../../design/GenericTable/useSorting';

import { TableColumn } from '../../design/GenericTable/GenericTable';
import { useGetPaymentScheduledQuery } from '../../api/payment/paymentAPI';

import { dateConverterFromUTC } from '../../utils/dateFormat';
import { formatCurrency } from '../../utils/fieldMasks';
import BasedTooltip from '../../design/BasedTooltip';
import { useParams } from 'react-router-dom';
import ExpandedLeasePayment from './ExpandedLeasePayment';
import StatusTag, { Tag } from '../../design/StatusTag/StatusTag';
import React from 'react';
import { PaymentScheduledData } from '../PaymentsOverview/interfaces';

export function useLeasePayment() {
    const { id } = useParams<{ id: string }>();
    const { sortKey, sortOrder, handleSort } = useSorting('paymentDate', 'asc');

    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(1, 25);

    const { data, isLoading } = useGetPaymentScheduledQuery({
        leaseId: id,
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });

    const columns: TableColumn<PaymentScheduledData>[] = [
        {
            header: {
                label: 'Payee Name',
                sortable: true,
                sorting: {
                    id: 'landowner.name',
                    direction:
                        sortKey === 'landowner.name' ? sortOrder : 'desc',
                    isSorted: sortKey === 'landowner.name',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <BasedTooltip>{data?.landowner.name}</BasedTooltip>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Due Date',
                sortable: true,
                sorting: {
                    id: 'paymentDate',
                    direction: sortKey === 'paymentDate' ? sortOrder : 'desc',
                    isSorted: sortKey === 'paymentDate',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <>
                    {data?.paymentDate &&
                        dateConverterFromUTC(data?.paymentDate)}
                </>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Expenses',
                sortable: false,
                sorting: {
                    id: 'expenses',
                    direction: sortKey === 'expenses' ? sortOrder : 'desc',
                    isSorted: sortKey === 'expenses',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{data?.lease?.expenses ? 'Y' : 'N/A'}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Status',
                sortable: false,
            },
            cellRender: (data) => (
                <>{<StatusTag {...statusMap[data.status]} />}</>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Total',
                sortable: true,
                sorting: {
                    id: 'amount',
                    direction: sortKey === 'amount' ? sortOrder : 'desc',
                    isSorted: sortKey === 'amount',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{formatCurrency(data?.amount)}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];
    const tableConfig = {
        columns,
        pageable: true,
        handlePageChange,
        handleItemsPerPageChange,
        initialPageSize: 25,
        initialPage: 1,
        expandable: true,
        ExpandComponent: ExpandedLeasePayment,
    };
    const totalCount = data?.totalCount || 0;
    return { data: data?.data || [], totalCount, tableConfig, isLoading };
}

const statusMap: Record<PaymentScheduledData['status'], Tag> = {
    PAID: {
        status: 'success',
        text: 'Paid',
    },
    UNPAID: {
        status: 'warning',
        text: 'Unpaid',
    },
    UPCOMING: {
        status: 'info',
        text: 'Upcoming',
    },
    OVERDUE: {
        status: 'error',
        text: 'Overdue',
    },
};
