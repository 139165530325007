import React, { useState } from 'react';
import CopiedReadField from '../../design/CopiedReadField/CopeiedReadField';
import { makeStyles } from 'tss-react/mui';
import { useFormState } from 'react-final-form';
import PreviewFormWrapper from './PreviewFormWrapper';
import GenericTable from '../../design/GenericTable/GenericTable';
import { FSA } from './LeaseDetailForm';

import { useTableFsaIdConfig } from './useTableFsaIdConfig';
import { convertUploadDataToField } from './utils';
import _ from 'lodash';
import { Button, Typography } from '@mui/material';
import ModalBonusFieldPreview from './ModalBonusFieldPreview';
import { Lease } from '../../pages/Leases/TableConfig';

interface OwnProps {
    setPageNumber: (page: number) => void;
    tableData: FSA[];
}
export default function PreviewFlex({ setPageNumber, tableData }: OwnProps) {
    const { values: data } = useFormState();
    const [openModal, setOpenModal] = useState(false);
    const handleModify = (page: number) => {
        setPageNumber(page);
    };
    const { classes } = useStyles();
    return (
        <div className={classes.container}>
            <PreviewFormWrapper onClick={handleModify} pageNumber={0}>
                {!!data &&
                    convertUploadDataToField(data as Lease).map(
                        (item, index) =>
                            item.value && (
                                <CopiedReadField key={index} {...item} />
                            ),
                    )}
            </PreviewFormWrapper>
            <PreviewFormWrapper onClick={handleModify} pageNumber={1}>
                <GenericTable
                    data={tableData}
                    totalCount={tableData?.length}
                    tableConfig={useTableFsaIdConfig(false, _, _, true)}
                />
            </PreviewFormWrapper>
            {data?.bonusCriteria && (
                <PreviewFormWrapper onClick={handleModify} pageNumber={2}>
                    <Typography noWrap variant={'font14'} fontWeight="bold">
                        Bonus Calculation Description
                    </Typography>
                    <Button size={'medium'} onClick={() => setOpenModal(true)}>
                        Show Bonus Preview
                    </Button>
                </PreviewFormWrapper>
            )}
            <ModalBonusFieldPreview
                isShowing={openModal}
                onClose={() => setOpenModal(false)}
                data={data?.bonusCriteria}
            />
        </div>
    );
}

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        width: '100%',
        gap: theme.spacing(1),
        padding: theme.spacing(2, 3),
    },
}));
