import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';
import { PARTNER_SERVICES } from '../tags';

interface PartnerServicesStatusResponse {
    enabled: boolean;
}

interface PartnerServicesStatusRequest {
    enabled: boolean;
}

export const partnerServicesAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getPartnerServicesStatus: builder.query<
            PartnerServicesStatusResponse,
            void
        >({
            query: () => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.partnerServices.root,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: [PARTNER_SERVICES.PARTNER_SERVICES_STATUS],
        }),
        updatePartnerServicesStatus: builder.mutation<
            PartnerServicesStatusResponse,
            PartnerServicesStatusRequest
        >({
            query: (body) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.partnerServices.root,
                    method: HTTP_METHOD.PUT,
                    body,
                };
            },
            invalidatesTags: () => [
                { type: PARTNER_SERVICES.PARTNER_SERVICES_STATUS },
            ],
        }),
    }),
});

export const {
    useGetPartnerServicesStatusQuery,
    useLazyGetPartnerServicesStatusQuery,
    useUpdatePartnerServicesStatusMutation,
} = partnerServicesAPI;
