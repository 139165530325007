import { Box, TextField, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import AppTypography from '../../../design/AppTypography';
import { Field } from 'react-final-form';

const FormInput = styled(TextField)({
    '& .MuiInputBase-root': {
        height: 24,
        fontSize: 12,
    },
});

export default function HazardousFormFields() {
    return (
        <Box display={'flex'} columnGap={2}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={1.5}
                width={'30%'}>
                <AppTypography
                    variant="font12"
                    fontWeight={'medium'}
                    text={'Event Name'}
                    color={'text.neutral9'}
                />
                <Field
                    name="eventName"
                    render={({ input }) => <FormInput {...input} fullWidth />}
                />
                <AppTypography
                    variant="font12"
                    fontWeight={'medium'}
                    text={'Safety Videos'}
                    color={'text.neutral9'}
                />
                <Field
                    name="safetyVideos.[0]"
                    render={({ input }) => <FormInput {...input} fullWidth />}
                />
                <Field
                    name="safetyVideos.[1]"
                    render={({ input }) => <FormInput {...input} fullWidth />}
                />
                <Field
                    name="safetyVideos.[2]"
                    render={({ input }) => <FormInput {...input} fullWidth />}
                />
            </Box>
            <Box width={'65%'}>
                <Field
                    name="description"
                    render={({ input }) => (
                        <Grid item xs={12}>
                            <TextField
                                {...input}
                                multiline={true}
                                minRows={7}
                                fullWidth
                            />
                        </Grid>
                    )}
                />
            </Box>
        </Box>
    );
}
