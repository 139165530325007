import { Box, Button } from '@mui/material';

interface OwnProps {
    isValid: boolean;
    handleSubmit: () => void;
    handleCancel: () => void;
}

export default function IntegrationStickySubmitBar({
    isValid,
    handleSubmit,
    handleCancel,
}: OwnProps) {
    return (
        <Box
            position={'sticky'}
            bottom={0}
            columnGap={2}
            bgcolor={'#fff'}
            display={'flex'}
            justifyContent={'flex-end'}
            p={2}
            borderRadius={2}
            boxShadow={'0px -2px 4px rgba(0, 0, 0, 0.05)'}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
                disabled={!isValid}
                variant={'contained'}
                color={'primary'}
                onClick={handleSubmit}>
                Submit
            </Button>
        </Box>
    );
}
