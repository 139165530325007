import useLeaseHistoryTable from './useLeaseHistoryTable';

import GenericTable from '../../design/GenericTable/GenericTable';

export default function LeaseHistoryTable() {
    const { data, totalCount, tableConfig, isLoading } = useLeaseHistoryTable();

    return (
        <GenericTable
            isLoading={isLoading}
            data={data}
            totalCount={totalCount}
            tableConfig={tableConfig}
        />
    );
}
