import PreviewFormWrapper from './PreviewFormWrapper';
import { ContactData } from '../model';
import PreviewContactCard from './PreviewContactCard';
import { Box } from '@mui/material';
import AppTypography from '../../../design/AppTypography';

interface OwnProps {
    insuranceAgent: ContactData;
    banker: ContactData;
}
export default function InsuranceFinancialContactsPreview({
    insuranceAgent,
    banker,
}: OwnProps) {
    return (
        <PreviewFormWrapper
            label={'Contacts:Insurance and Financial Preparedness'}>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={1}
                    width={'100%'}>
                    <div>
                        <AppTypography
                            variant="font16"
                            fontWeight={'medium'}
                            color={'text.neutral10'}
                            text={`Insurance Agent:`}
                        />
                    </div>
                    <PreviewContactCard contact={insuranceAgent} />
                </Box>

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={1}
                    width={'100%'}>
                    <div>
                        <AppTypography
                            variant="font16"
                            fontWeight={'medium'}
                            color={'text.neutral10'}
                            text={`Banker:`}
                        />
                    </div>
                    <PreviewContactCard contact={banker} />
                </Box>
            </Box>
        </PreviewFormWrapper>
    );
}
