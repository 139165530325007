import GenericTable, {
    Table,
    TableColumn,
} from '../../design/GenericTable/GenericTable';

import { Box, Button, Typography } from '@mui/material';
import UserAvatar from '../../design/Avatar/UserAvatar';
import StatusTag, { Tag } from '../../design/StatusTag/StatusTag';
import React, { useEffect, useMemo, useState } from 'react';
import { useSorting } from '../../design/GenericTable/useSorting';
import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { initCustomViewForTasks } from '../../pages/customizeViewInitialStates';
import { isColumnHidden } from '../../pages/utils';
import TaskFormModalUpdate from './TaskFormModalUpdate';
import useAvatar from '../../hooks/useAvatar';
import { useLazyGetTasksQuery } from '../../api/tasks/tasksAPI';
import { TaskView } from './utils';
import { useSettingsFromStorage } from '../../hooks/useSettingsFromStorage';
import { useSaveSettings } from '../../hooks/useSaveSettings';

export type TaskStatus = 'TO_DO' | 'IN_PROGRESS' | 'SUSPENDED' | 'DONE';
export type TaskPriority = 'LOW' | 'MEDIUM' | 'HIGH';
export type TaskRelatesToType = 'LEASE' | 'LAND_PARTNER';

export default function TasksTable() {
    const { settings, saveSettings } = useSettingsFromStorage('TASKS');
    const { sort, page, sortDirection, size } = settings;

    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'dueDate',
        sortDirection || 'desc',
    );
    const [isShowing, setIsShowing] = useState(false);
    const [id, setId] = useState('');

    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);

    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });

    const [getItems, { data: sortedData, isLoading }] = useLazyGetTasksQuery();
    const { createBlobUrl } = useAvatar();

    useEffect(() => {
        getItems({
            sort: `${sortKey},${sortOrder}`,
            page: currentPage - 1,
            size: itemsPerPage,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortKey, sortOrder, currentPage, itemsPerPage]);

    const totalCount = useMemo(() => {
        return sortedData?.totalCount || 0;
    }, [sortedData]);

    const data = useMemo(() => {
        return sortedData?.data || [];
    }, [sortedData]);
    const handleEdit = (id: string) => {
        setId(id);
        setIsShowing(true);
    };
    const handleClose = () => {
        setIsShowing(false);
    };

    const columns: TableColumn<TaskView>[] = [
        {
            header: {
                customizeKey: 'dueDate',
                label: 'Due Date',
                sortable: true,
                sorting: {
                    id: 'dueDate',
                    direction: sortKey === 'dueDate' ? sortOrder : 'desc',
                    isSorted: sortKey === 'dueDate',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(
                    initCustomViewForTasks,
                    'dueDate',
                    false,
                ),
            },
            cellRender: (item) => (
                <Typography
                    variant={'font14'}
                    color={'text.link'}
                    fontWeight={'medium'}>
                    {item.dueDate}
                </Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(initCustomViewForTasks, 'dueDate', false),
        },

        {
            header: {
                customizeKey: 'assignedTo',
                label: 'Assigned to',
                sortable: true,
                sorting: {
                    id: 'assignTo.firstName',
                    direction:
                        sortKey === 'assignTo.firstName' ? sortOrder : 'desc',
                    isSorted: sortKey === 'assignTo.firstName',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(
                    initCustomViewForTasks,
                    'assignedTo',
                    false,
                ),
            },
            cellRender: (item) => (
                <Typography
                    variant={'font14'}
                    color={'text.link'}
                    fontWeight={'medium'}>
                    <Box display={'flex'} columnGap={1} alignItems={'center'}>
                        <UserAvatar
                            name={
                                !item?.assignTo?.firstName &&
                                !item?.assignTo?.lastName
                                    ? 'Not assigned'
                                    : `${item?.assignTo?.firstName || ''} ${
                                          item?.assignTo?.lastName || ''
                                      }`
                            }
                            avatar={
                                item.assignTo?.profilePhoto &&
                                item.assignTo?.profilePhoto.fileType &&
                                createBlobUrl(
                                    item.assignTo?.profilePhoto?.profilePhoto,
                                    item.assignTo?.profilePhoto?.fileType,
                                )
                            }
                            size={'small'}
                        />
                        <Typography
                            variant={'font12'}
                            color={'text.darkTurqTint5'}>
                            {!item?.assignTo?.firstName &&
                            !item?.assignTo?.lastName
                                ? 'Not assigned'
                                : `${item?.assignTo?.firstName || ''} ${
                                      item?.assignTo?.lastName || ''
                                  }`}
                        </Typography>
                    </Box>
                </Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(
                initCustomViewForTasks,
                'assignedTo',
                false,
            ),
        },
        {
            header: {
                customizeKey: 'name',
                label: 'Task Name',
                sortable: true,
                sorting: {
                    id: 'name',
                    direction: sortKey === 'name' ? sortOrder : 'desc',
                    isSorted: sortKey === 'name',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(initCustomViewForTasks, 'name', false),
            },
            cellRender: (item) => (
                <Typography color={'text.link'}>{item.name}</Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(initCustomViewForTasks, 'name', false),
        },
        {
            header: {
                customizeKey: 'createdBy',
                label: 'Created By',
                sortable: true,
                sorting: {
                    id: 'createdByUser.firstName',
                    direction:
                        sortKey === 'createdByUser.firstName'
                            ? sortOrder
                            : 'desc',
                    isSorted: sortKey === 'createdByUser.firstName',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(
                    initCustomViewForTasks,
                    'createdBy',
                    false,
                ),
            },
            cellRender: (item) => (
                <Typography
                    color={
                        'text.link'
                    }>{`${item.createdByUser.firstName} ${item.createdByUser.lastName}`}</Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(
                initCustomViewForTasks,
                'createdBy',
                false,
            ),
        },
        {
            header: {
                customizeKey: 'priority',
                label: 'Priority',
                sortable: true,
                sorting: {
                    id: 'priority',
                    direction: sortKey === 'priority' ? sortOrder : 'desc',
                    isSorted: sortKey === 'priority',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(
                    initCustomViewForTasks,
                    'priority',
                    false,
                ),
            },
            cellRender: (item) => (
                <>{item?.priority ? priorityMapper[item?.priority] : 'N/A'}</>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(initCustomViewForTasks, 'priority', false),
        },
        {
            header: {
                customizeKey: 'status',
                label: 'Status',
                sortable: true,
                sorting: {
                    id: 'status',
                    direction: sortKey === 'status' ? sortOrder : 'desc',
                    isSorted: sortKey === 'status',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(
                    initCustomViewForTasks,
                    'status',
                    false,
                ),
            },
            cellRender: (item) => <StatusTag {...statusMap[item.status]} />,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(initCustomViewForTasks, 'status', false),
        },
        {
            header: {
                customizeKey: 'description',
                label: 'Description',
                sortable: true,
                sorting: {
                    id: 'description',
                    direction: sortKey === 'description' ? sortOrder : 'desc',
                    isSorted: sortKey === 'description',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(
                    initCustomViewForTasks,
                    'description',
                    false,
                ),
            },
            cellRender: (item) => <>{item.description}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(
                initCustomViewForTasks,
                'description',
                false,
            ),
        },
        {
            header: {
                customizeKey: 'edit',
                label: '',
                sortable: false,
                isHidden: isColumnHidden(initCustomViewForTasks, 'edit', false),
            },
            cellRender: (item) => (
                <Button
                    variant={'outlined'}
                    onClick={() => handleEdit(item.id)}>
                    Edit
                </Button>
            ),
            // eslint-disable-next-line max-lines
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(initCustomViewForTasks, 'edit', false),
        },
    ];

    const tableConfig: Table<TaskView> = {
        columns: columns,
        multiselect: false,
        expandable: false,
        handlePageChange: handlePageChange,
        handleItemsPerPageChange: handleItemsPerPageChange,
        initialPage: page,
        initialPageSize: size,
    };
    return (
        <div>
            <GenericTable
                isLoading={isLoading}
                data={data}
                totalCount={totalCount}
                tableConfig={tableConfig}
            />

            {isShowing && (
                <TaskFormModalUpdate
                    id={id}
                    isShowing={isShowing}
                    onClose={handleClose}
                />
            )}
        </div>
    );
}

const statusMap: Record<TaskStatus, Tag> = {
    TO_DO: {
        status: 'success',
        text: 'New',
    },

    IN_PROGRESS: {
        status: 'warning',
        text: 'In Progress',
        // eslint-disable-next-line max-lines
    },
    SUSPENDED: {
        status: 'error',
        text: 'Suspended',
    },
    DONE: {
        status: 'disabled',
        text: 'Complete',
    },
};
const priorityMapper: Record<TaskPriority, string> = {
    LOW: 'Low',
    MEDIUM: 'Medium',
    HIGH: 'High',
};
