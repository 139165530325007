import React, { useEffect, useMemo } from 'react';

import GenericTable, {
    Table,
    TableColumn,
} from '../../design/GenericTable/GenericTable';

import {
    Address,
    PrimaryContactUpdate,
    Status,
} from '../../features/ViewLandownersDetails/LandPartnerDetails';
import { Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../router/paths';
import StatusTag, { Tag } from '../../design/StatusTag/StatusTag';
import ExpandComponent from './ExpandedComponent';
import {
    useGetLandPartnersQuery,
    useLazyGetLandPartnersQuery,
} from '../../api/landpartners/landPartnersAPI';
import { useSorting } from '../../design/GenericTable/useSorting';
import { usePagination } from '../../design/GenericTable/useBackendPagination';

import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { isColumnHidden, sortColumnsByOrder } from '../utils';
import { useSettingsFromStorage } from '../../hooks/useSettingsFromStorage';
import { useSaveSettings } from '../../hooks/useSaveSettings';
import LinkText from '../../design/Fields/LinkText';
import useManagePermission from '../../hooks/useManagePermission';
import { ColumnConfig } from '../../api/users/customizeConfigAPI';
import { PhoneType } from '../../reusable/FormUI/PhoneNumberField';
import LandPartnerLeasesTooltip from '../../design/Tooltip/LandPartnerLeasesTooltip';

interface OwnProps {
    config: ColumnConfig[];
    isSelected: (items: LandPartnerTableView) => boolean;
    toggleSelection: (items: LandPartnerTableView) => void;
    toggleSelectionAll: (items: LandPartnerTableView[]) => void;
    isSelectedAll: (items: LandPartnerTableView[]) => boolean;
}

export interface LandPartnerLeaseData {
    id: string;
    leaseName: string;
    leasesCount: number;
}

export interface LandPartnerTableView {
    id: string;
    name: string;
    primaryContact: PrimaryContactUpdate;
    status: Status;
    billingAddress?: Address;
    leases?: LandPartnerLeaseData;
    phoneNumber?: PhoneType[];
}

export default function LandPartnersTable({
    isSelected,
    toggleSelection,
    toggleSelectionAll,
    isSelectedAll,
    config,
}: OwnProps) {
    const navigate = useNavigate();
    const { settings, saveSettings } = useSettingsFromStorage('LAND_PARTNERS');
    const { sort, page, sortDirection, size } = settings;

    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'createdAt',
        sortDirection || 'desc',
    );
    const { checkPermission } = useManagePermission();
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);
    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });

    const { data: initData, isLoading } = useGetLandPartnersQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });
    const [getItems, { data: sortedData, isLoading: isLazyLoading }] =
        useLazyGetLandPartnersQuery();

    useEffect(() => {
        getItems({
            sort: `${sortKey},${sortOrder}`,
            page: currentPage - 1,
            size: itemsPerPage,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortKey, sortOrder, currentPage, itemsPerPage]);

    const data = useMemo(() => {
        return sortedData?.data || initData?.data || [];
    }, [sortedData, initData]);

    const totalCount = useMemo(() => {
        return sortedData?.totalCount || initData?.totalCount || 0;
    }, [sortedData, initData]);

    const columns: TableColumn<LandPartnerTableView>[] = [
        {
            header: {
                customizeKey: 'name',
                label: 'Land Partner Name',
                sortable: true,
                sorting: {
                    id: 'name',
                    direction: sortKey === 'name' ? sortOrder : 'desc',
                    isSorted: sortKey === 'name',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(config, 'name', false),
            },
            cellRender: (landPartner) => (
                <LinkText
                    to={`/${PATHS.landpartners}/${PATHS.profiles}/${landPartner?.id}`}
                    text={landPartner?.name}
                    variant={'font14'}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'name', false),
        },
        {
            header: {
                customizeKey: 'primaryContactFirstName',
                label: 'Primary Contact Name',
                sortable: true,
                sorting: {
                    id: 'primaryContact.firstName',
                    direction:
                        sortKey === 'primaryContact.firstName'
                            ? sortOrder
                            : 'desc',
                    isSorted: sortKey === 'primaryContact.firstName',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(
                    config,
                    'primaryContactFirstName',
                    false,
                ),
            },
            cellRender: (landPartner) => (
                <LinkText
                    to={`/${PATHS.landpartners}/${PATHS.allProfiles}/${landPartner?.primaryContact?.id}`}
                    text={`${landPartner?.primaryContact?.firstName} ${landPartner?.primaryContact?.lastName}`}
                    variant={'font14'}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'primaryContactFirstName', false),
        },
        {
            header: {
                customizeKey: 'leases',
                label: 'Associated Leases',
                sortable: false,
                isHidden: isColumnHidden(config, 'leases', false),
            },
            cellRender: (landPartner) => showAssociatedLeases(landPartner),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'leases', false),
        },
        {
            header: {
                customizeKey: 'status',
                label: 'Status',
                sortable: true,
                sorting: {
                    id: 'status',
                    direction: sortKey === 'status' ? sortOrder : 'desc',
                    isSorted: sortKey === 'status',
                },
                onClick: handleSort,
                isHidden: isColumnHidden(config, 'status', false),
            },
            cellRender: (landPartner) => (
                <StatusTag {...statusMap[landPartner.status]} />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden: isColumnHidden(config, 'status', false),
        },
        {
            header: {
                customizeKey: 'actions',
                label: 'Actions',
                sortable: false,
                isHidden:
                    isColumnHidden(config, 'actions', false) ||
                    !checkPermission('landPartners', 'modify'),
            },
            cellRender: (landPartner) => (
                <Box>
                    <IconButton
                        onClick={() =>
                            navigate(`${landPartner.id}/${PATHS.update}`)
                        }>
                        <EditIcon />
                    </IconButton>
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
            isHidden:
                isColumnHidden(config, 'actions', false) ||
                !checkPermission('landPartners', 'modify'),
        },
    ];
    const orderedColumns = sortColumnsByOrder<LandPartnerTableView>(
        columns,
        config,
    );
    const tableConfig: Table<LandPartnerTableView> = {
        columns: orderedColumns,
        multiselect: true,
        expandable: true,
        ExpandComponent: ExpandComponent,
        handlePageChange: handlePageChange,
        handleItemsPerPageChange: handleItemsPerPageChange,
        initialPage: page,
        initialPageSize: size,
        isSelected: isSelected,
        toggleSelection: toggleSelection,
        toggleSelectionAll: toggleSelectionAll,
        isSelectedAll: isSelectedAll,
    };
    return (
        <GenericTable
            data={data}
            isLoading={isLoading || isLazyLoading}
            totalCount={totalCount}
            tableConfig={tableConfig}
        />
    );
}

export const statusMap: Record<Status, Tag> = {
    ACTIVE: {
        status: 'success',
        text: 'Active',
    },
    INACTIVE: {
        status: 'warning',
        text: 'Inactive',
    },
    PROSPECT: {
        status: 'info',
        text: 'Pending',
    },
    SUSPENDED: {
        status: 'error',
        text: 'Suspended',
    },
};

const showAssociatedLeases = (landPartner: LandPartnerTableView) => {
    if (!landPartner?.leases) {
        return null;
    }

    return (
        <Box display={'flex'} alignItems={'center'} gap={1}>
            <LinkText
                to={`/${PATHS.landPortfolio}/${PATHS.manage}/${landPartner.leases.id}`}
                text={landPartner.leases.leaseName}
                sx={{ textAlign: 'center' }}
                variant={'font14'}
            />
            <span>
                {landPartner.leases.leasesCount > 1 &&
                    `+${landPartner.leases.leasesCount - 1}`}
            </span>
            {landPartner.leases.leasesCount > 1 && (
                <LandPartnerLeasesTooltip
                    landPartnerId={landPartner.id}
                    position={'top'}
                />
            )}
        </Box>
    );
};
