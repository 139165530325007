import React from 'react';
import LeaseDetails from '../../features/ViewLeaseDetails/LeaseDetails';
import LeaseMap from '../../features/ViewLeaseDetails/LeaseMap';
import LeasePayments from '../../features/ViewLeaseDetails/LeasePayments';
import BasicTabsPanel from '../../design/TabsPanel/BasicTabsPanel';
import PATHS from '../../router/paths';
import LeaseHistory from '../../features/ViewLeaseHistory/LeaseHistory';
import { useParams } from 'react-router-dom';
import { useGetOneLeaseQuery } from '../../api/leases/leaseAPI';
import { Lease } from './TableConfig';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import Breadcrumbs from '../../design/Breadcrumbs/Breadcrumbs';
import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import { FsaId } from '../../features/PaymentsOverview/interfaces';
import { formatNumber } from '../../utils/fieldMasks';

interface TabsConfigProps {
    data: Lease;
    isLoading: boolean;
}
const tabsConfig = ({ data, isLoading }: TabsConfigProps) => [
    {
        label: 'Detail',
        content: <LeaseDetails data={data} isLoading={isLoading} />,
        value: 0,
        to: '',
    },
    {
        label: 'Fields',
        content: <LeaseMap />,
        value: `${PATHS.fields}`,
        to: `${PATHS.fields}`,
    },
    {
        label: 'Payments',
        content: <LeasePayments />,
        value: `${PATHS.payments}`,
        to: `${PATHS.payments}`,
    },
    {
        label: 'History ',
        content: <LeaseHistory />,
        value: `${PATHS.history}`,
        to: `${PATHS.history}`,
    },
];

const routes = {
    '/': 'Home',
    'landPortfolio/manage/': 'Leases',
    'landPortfolio/manage/:id': '',
    'landPortfolio/manage/:id/fields': 'Fields',
    'landPortfolio/manage/:id/payments': 'Payments',
    'landPortfolio/manage/:id/history': 'History',
};
export default function LeaseDetailPage() {
    const { id } = useParams();
    const { isFetching, isLoading, data } = useGetOneLeaseQuery(id);
    const breadcrumbs = useBreadcrumbs({
        dynamicName: data?.leaseName?.toUpperCase() || '',
        routes,
    });
    const totalAcres = data?.fsaIds?.reduce(
        (acc: number, curr: FsaId) => acc + curr?.totalAcres || 0,
        0,
    );
    return (
        <div>
            <Breadcrumbs collapsed={false} links={breadcrumbs} />
            <Box
                p={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}>
                <AppTypography
                    variant="font20"
                    fontWeight="regular"
                    text={data?.leaseName}
                    color={'text.neutral9'}
                />
                <Box display={'flex'} alignItems={'center'} columnGap={2}>
                    <AppTypography
                        variant="font12"
                        fontWeight="regular"
                        text={'TOTAL ACRES'}
                        color={'text.neutral9'}
                    />
                    <AppTypography
                        variant="font36"
                        fontWeight="light"
                        text={formatNumber(totalAcres)}
                        color={'text.neutral7'}
                    />
                </Box>
            </Box>
            <BasicTabsPanel
                tabItems={tabsConfig({
                    data,
                    isLoading: isLoading || isFetching,
                })}
            />
        </div>
    );
}
