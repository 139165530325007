import React from 'react';
import BaseModal from '../../../reusable/BaseModal';
import AddFieldNotes from './AddFieldNotes';

interface OwnProps {
    id: string;
    name: string;
    isShowing: boolean;
    onClose: () => void;
}

export default function FieldNotesModal({
    id,
    name,
    isShowing,
    onClose,
}: OwnProps) {
    const headerText = name
        ? `Notes for Field with Land Name: ${name}`
        : 'Field Notes';
    return (
        <BaseModal
            isShowing={isShowing}
            onClose={onClose}
            header={headerText}
            height={'large'}
            size={'smallMedium'}>
            <AddFieldNotes id={id} />
        </BaseModal>
    );
}
