import React from 'react';
import PrimaryContactInfoPanel from '../../features/ViewAllProfiles/PrimaryContactInfoPanel/PrimaryContactInfoPanel';

import useAvatar from '../../hooks/useAvatar';
import { Address, ContactProfile } from './AllProfiles';

import { Grid } from '@mui/material';
import Loader from '../../design/BaseLoader';
import useProfilePhoto from '../../hooks/useProfilePhoto';

interface OwnProps {
    contactData: ContactProfile;
    isLoading: boolean;
    isFetching: boolean;
}

export default function PrimaryContactDetails({
    contactData,
    isLoading,
    isFetching,
}: OwnProps) {
    const { data: avatarData, isLoading: isLoadingAvatar } = useProfilePhoto({
        id: contactData?.id,
        type: 'CONTACT',
    });

    const { createBlobUrl } = useAvatar();
    if (isLoading || isFetching || isLoadingAvatar) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }
    const formatAddressByType = (addressType: string) => {
        const address = contactData?.addresses?.find(
            (address: Address) => address.type === addressType,
        );
        return address
            ? [
                  address.address1,
                  address.address2,
                  address.city,
                  address.state,
                  address.zipCode,
              ]
                  .filter(Boolean)
                  .join(', ')
            : '';
    };

    const userMainData = {
        id: contactData?.id,
        name: `${contactData?.firstName} ${contactData?.lastName}`,
        avatar:
            avatarData?.avatarData &&
            avatarData?.fileType &&
            createBlobUrl(avatarData?.avatarData, avatarData?.fileType),
        isDeceased: !!contactData?.keyDates?.dateOfDeath,
        socialMedia: contactData?.socialMedia,
        advocacy: contactData?.advocate || [],
        isPrimaryContact: contactData?.primaryContact || false,
        landPartners: contactData?.landPartners || [],
    };

    const primaryContactData = {
        id: contactData?.id,
        emailStatus: contactData?.primaryEmailStatus,
        email: contactData?.primaryEmail,
        phone: contactData?.contactNumbers || ' N/A',
        address: formatAddressByType('PRIMARY_ADDRESS') || ' N/A',
    };

    return (
        <>
            <PrimaryContactInfoPanel
                userMain={userMainData}
                primaryContactData={primaryContactData}
                datesOfContact={{
                    birthday: contactData.keyDates?.birthDate,
                    anniversary: contactData.keyDates?.anniversary,
                    dateOfDeath: contactData.keyDates?.dateOfDeath,
                }}
            />
        </>
    );
}
