import { debounce, get } from 'lodash';

export function getLodashValue(
    // eslint-disable-next-line
    obj: any,
    path: string,
    defaultValue?: never,
) {
    return get(obj, path, defaultValue);
}

export function getLodashDebounce(
    func: (...args: string[]) => void,
    wait: number,
) {
    return debounce(func, wait);
}
