import React from 'react';
import BasePieChart, {
    PieChartConfig,
} from '../../design/Charts/PieChart/BasePieChart';
import { Grid } from '@mui/material';
import LastOutreachTable from '../../pages/LandPartners/LastOutreachTable';
import theme from '../../styles/theme';
import { useGetNotificationsSummaryQuery } from '../../api/landpartners/landPartnersAPI';
import { useGetLeaseDashboardQuery } from '../../api/leases/leaseAPI';
import { LeaseDashboard } from '../../pages/Leases/ManageLeasesPage';

export interface notificationsChart {
    totalCount: number;
    birthdayCount: number;
    paymentCount: number;
    taskCount: number;
    leaseRenewalCount: number;
}

export default function DashboardCharts() {
    const { data: notificationsChartData } = useGetNotificationsSummaryQuery(
        {},
    );
    const { data: leaseDashboard } = useGetLeaseDashboardQuery(null);
    return (
        <Grid
            container
            item
            flexWrap={'wrap'}
            columnGap={1}
            sx={{
                padding: theme.spacing(2),
                backgroundColor: theme.palette.neutralWhite,
                borderRadius: 2,
            }}>
            <Grid item>
                <BasePieChart
                    data={totalUpEvents(notificationsChartData)}
                    config={pieChartConfigTotalUpEvents(notificationsChartData)}
                />
            </Grid>
            <Grid item>
                <BasePieChart
                    data={createLeaseRenewal(leaseDashboard)}
                    config={pieChartConfigLeaseRenewal}
                />
            </Grid>
            <Grid item xs={5}>
                <LastOutreachTable />
            </Grid>
        </Grid>
    );
}

function totalUpEvents(notificationsChartData: notificationsChart) {
    return [
        {
            name: 'Birthdays',
            value: notificationsChartData?.birthdayCount || 0,
        },
        {
            name: 'Lease Renewals',
            value: notificationsChartData?.leaseRenewalCount || 0,
        },
        {
            name: 'Payments',
            value: notificationsChartData?.paymentCount || 0,
        },
        {
            name: 'Tasks',
            value: notificationsChartData?.taskCount || 0,
        },
    ];
}

function createLeaseRenewal(dashboardData: LeaseDashboard) {
    return [
        { name: '90+', value: dashboardData?.ninetyPlusDaysUntilRenewal || 0 },
        { name: '60', value: dashboardData?.sixtyDaysUntilRenewal || 0 },
        { name: '30', value: dashboardData?.thirtyDaysUntilRenewal || 0 },
    ];
}

function pieChartConfigTotalUpEvents(
    notificationsChartData: notificationsChart,
): PieChartConfig {
    return {
        summaryTitle: 'Events Total',
        summaryValue: `${notificationsChartData?.totalCount || 0}`,
        showLegend: true,
        dataKey: 'value',
        chartTitle: 'UPCOMING EVENTS',
        showTooltip: true,
    };
}
const pieChartConfigLeaseRenewal: PieChartConfig = {
    summaryTitle: 'Days Remaining',
    summaryValue: 'Count',
    showLegend: true,
    dataKey: 'value',
    chartTitle: 'Lease Renewals',
    showTooltip: true,
};
