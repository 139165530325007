import FormScreenWrapper from './FormScreenWrapper';
import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import FormPage from '../../reusable/FormUI/FormPage';
import { FormConfig } from '../../design/Forms/interfaces';
import { composeValidators } from '../../utils/validation';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../constants';

import useFormFileUpload, { FormConfigProps } from './useFormFileUpload';
import FileWithDownloadButton from '../../reusable/FileWithDownloadButton';
import { useForm, useFormState } from 'react-final-form';

export default function EvacuationSafetyForm() {
    const { handleFileUpload } = useFormFileUpload();

    const { values } = useFormState();
    const form = useForm();

    const evacuationPlanFile = values?.evacuationSafety?.evacuationPlanFile;
    const safeAreaFile = values?.evacuationSafety?.safeAreaFile;
    return (
        <FormScreenWrapper title="Evacuation and Safety">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What is the evacuation plan for farm workers and family members?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage
                    formConfig={evacuationFormConfig({
                        onChange: handleFileUpload,
                        isHidden: !!evacuationPlanFile?.id,
                    })}
                />
                {evacuationPlanFile?.id && (
                    <FileWithDownloadButton
                        isRemovable={true}
                        onDelete={() => {
                            form.change(
                                'evacuationSafety.evacuationPlanFile',
                                null,
                            );
                        }}
                        id={evacuationPlanFile.id}
                        name={evacuationPlanFile?.fileName}
                        size={evacuationPlanFile?.fileSize}
                        date={evacuationPlanFile?.createdAt}
                    />
                )}
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What is the designated safe area or shelter on the property?'
                    }
                    color={'text.neutral9'}
                />{' '}
                <FormPage
                    formConfig={safeAreaFormConfig({
                        onChange: handleFileUpload,
                        isHidden: !!safeAreaFile?.id,
                    })}
                />
                {safeAreaFile?.id && (
                    <FileWithDownloadButton
                        isRemovable={true}
                        onDelete={() => {
                            form.change('evacuationSafety.safeAreaFile', null);
                        }}
                        id={safeAreaFile.id}
                        name={safeAreaFile?.fileName}
                        size={safeAreaFile?.fileSize}
                        date={safeAreaFile?.createdAt}
                    />
                )}
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'How will you account for all personnel during an emergency?'
                    }
                    color={'text.neutral9'}
                />{' '}
                <FormPage formConfig={emergencySuppliesFormConfig} />
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'Where are emergency supplies (first aid kits, fire extinguishers) located?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={personalAccountConfig} />
            </Box>
        </FormScreenWrapper>
    );
}

function evacuationFormConfig({
    onChange,
    isHidden,
}: FormConfigProps): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'twoThird',
                name: 'evacuationSafety.evacuationPlan',
                type: 'textarea',
                label: '',
            },
            renderProps: {
                resize: 'none',
                isDisabled: false,
                minRows: 3,
            },
        },
        {
            formField: {
                scheme: 'third',
                name: 'evacuationSafety.evacuationPlanFile',
                type: 'fileupload',
                validation: composeValidators(
                    tooLargeFile(MAX_FILE_SIZE),
                    tooManyFiles(1),
                    invalidFileType([
                        'image/png',
                        'image/jpg',
                        'image/jpeg',
                        'image/bmp',
                        'application/pdf',
                    ]),
                ),
                onChange: (e) =>
                    onChange({
                        document: e as File[],
                        type: 'ER_EVACUATION_PLAN',
                        fieldName: 'evacuationSafety.evacuationPlanFile',
                    }),
                isHidden: isHidden,
            },
            renderProps: {
                filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
                placeholder: 'Drag a document here',
                helperText: 'Attach Document',
                multipleFiles: false,
                isDisabled: false,
            },
        },
    ];
}
function safeAreaFormConfig({
    onChange,
    isHidden,
}: FormConfigProps): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'twoThird',
                name: 'evacuationSafety.safeArea',
                type: 'textarea',
                label: '',
            },
            renderProps: {
                resize: 'none',
                isDisabled: false,
                minRows: 3,
            },
        },
        {
            formField: {
                scheme: 'third',
                name: 'evacuationSafety.safeAreaFile',
                type: 'fileupload',
                onChange: (e) =>
                    onChange({
                        document: e as File[],
                        type: 'ER_SAFE_AREA',
                        fieldName: 'evacuationSafety.safeAreaFile',
                    }),
                validation: composeValidators(
                    tooLargeFile(MAX_FILE_SIZE),
                    tooManyFiles(1),
                    invalidFileType([
                        'image/png',
                        'image/jpg',
                        'image/jpeg',
                        'image/bmp',
                        'application/pdf',
                    ]),
                ),
                isHidden: isHidden,
            },
            renderProps: {
                filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
                placeholder: 'Drag a document here',
                helperText: 'Attach Document',
                multipleFiles: false,
                isDisabled: false,
            },
        },
    ];
}
const personalAccountConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'twoThird',
            name: 'evacuationSafety.personnelAccounting',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
];
const emergencySuppliesFormConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'twoThird',
            name: 'evacuationSafety.emergencySuppliesLocation',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
];
