import FormScreenWrapper from './FormScreenWrapper';
import { Box, Grid } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import { FormConfig } from '../../design/Forms/interfaces';

import FormPage from '../../reusable/FormUI/FormPage';
import { Field } from 'react-final-form';
import ContactFormField from './ContactFormField';
import React from 'react';

export default function ExternalAssistanceForm() {
    return (
        <FormScreenWrapper title="External Assistance">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <Grid container spacing={2}>
                    <Grid item xs={6} direction={'column'} rowGap={1}>
                        <AppTypography
                            variant="font12"
                            fontWeight={'medium'}
                            text={'Is this your USDA Farm Agency Contact?'}
                            color={'text.neutral9'}
                        />
                        <Field
                            name={'externalAssistance.usdaFarmContact'}
                            render={({ input }) => (
                                <ContactFormField input={input} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} direction={'column'} rowGap={1}>
                        <AppTypography
                            variant="font12"
                            fontWeight={'medium'}
                            text={'Is this your Agronomist?'}
                            color={'text.neutral9'}
                        />
                        <Field
                            name={'externalAssistance.agronomist'}
                            render={({ input }) => (
                                <ContactFormField input={input} />
                            )}
                        />
                    </Grid>
                </Grid>
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'Are you familiar with government disaster assistance programs for farmers?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={emergencyDrillConfig} />
            </Box>
        </FormScreenWrapper>
    );
}

const yesNoOptions = [
    { id: 'YES', name: 'Yes' },
    { id: 'NO', name: 'No, I’d like to learn more' },
];

const emergencyDrillConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'trainingPreparedness.emergencyDrill.disasterAssistanceFamiliarity',
            type: 'radio',
            label: '',
            isRequired: true,
        },
        renderProps: {
            options: yesNoOptions,
            isDisabled: false,
            size: 'third',
        },
    },
];
