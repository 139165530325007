import { useEffect } from 'react';
import { useLazyProfilePhotoQuery } from '../api/users/usersAPI';
import { useLazyAvatarThumbnailQuery } from '../api/contacts/contactsAPI';
import { AvatarType } from '../reusable/ProfilePhoto';

interface OwnProps {
    id: string;
    type: AvatarType;
}

export default function useProfilePhoto({ id, type }: OwnProps) {
    const [getUserPhoto, { data: userPhoto, isLoading: isUserLoading }] =
        useLazyProfilePhotoQuery();
    const [
        getContactPhoto,
        { data: contactAvatar, isLoading: isContactLoading },
    ] = useLazyAvatarThumbnailQuery();

    function getPhoto() {
        if (type === 'USER') {
            getUserPhoto(id);
        }
        if (type === 'CONTACT') {
            getContactPhoto(id);
        }
    }
    function getData() {
        if (type === 'USER') {
            return userPhoto;
        }
        if (type === 'CONTACT') {
            return contactAvatar;
        }
    }

    useEffect(() => {
        id && getPhoto();
    }, [id, type]);

    const data = getData();
    const isLoading = isUserLoading || isContactLoading;
    return { data, isLoading };
}
