import { useEffect, useState } from 'react';
import { useLazyGetLandPartnersShortQuery } from '../../../api/landpartners/landPartnersAPI';
import { useLazyGetLeaseListQuery } from '../../../api/leases/leaseAPI';
import { useLazyGetPaymentScheduledQuery } from '../../../api/payment/paymentAPI';
import { IntegrationData } from './IntegrationDataCard';
import { convertLeaseToOptions, convertPaymentDateToOptions } from './utils';

interface OwnProps {
    data: IntegrationData;
}
export const useIntegrationData = ({ data }: OwnProps) => {
    const [landPartner, setLandPartner] = useState('');
    const [lease, setLease] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [balance, setBalance] = useState<string>(data?.value);

    const [
        getLandPartners,
        { data: landPartnerOptions, isLoading: isLandPartnersLoading },
    ] = useLazyGetLandPartnersShortQuery();

    const [getLease, { data: leaseOptions, isLoading: isLeaseLoading }] =
        useLazyGetLeaseListQuery();
    const [
        getPayments,
        { data: paymentDateOptions, isLoading: isPaymentsLoading },
    ] = useLazyGetPaymentScheduledQuery();

    useEffect(() => {
        if (landPartner) {
            getLease({
                sort: 'updatedAt,asc',
                page: 0,
                size: 1000,
                landPartnerId: landPartner,
            });
        }
    }, [landPartner]);

    useEffect(() => {
        if (lease) {
            getPayments({
                sort: 'paymentDate,asc',
                page: 0,
                size: 1000,
                leaseId: lease,
            });
        }
    }, [lease]);

    useEffect(() => {
        getLandPartners({ sort: 'name,asc', page: 0, size: 1000 });
    }, []);

    const resetSelections = () => {
        setLease('');
        setPaymentDate('');
    };
    function handleChangeBalance(value: string) {
        setBalance(value);
    }
    function handleFormChange(field: string, value: string) {
        if (field === 'landPartner') {
            setLandPartner(value);
            setLease('');
            setPaymentDate('');
        } else if (field === 'lease') {
            setLease(value);
            setPaymentDate('');
        } else if (field === 'paymentDate') {
            setPaymentDate(value);
        }
    }
    return {
        landPartner,
        lease,
        paymentDate,
        balance,
        resetSelections,
        handleFormChange,
        handleChangeBalance,
        landPartnerOptions: landPartnerOptions?.data || [],
        leaseOptions: convertLeaseToOptions(leaseOptions?.data) || [],
        paymentDateOptions:
            convertPaymentDateToOptions(paymentDateOptions?.data) || [],
        isLandPartnersLoading,
        isLeaseLoading,
        isPaymentsLoading,
    };
};
