import FormScreenWrapper from './FormScreenWrapper';
import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import FormPage from '../../reusable/FormUI/FormPage';
import { FormConfig } from '../../design/Forms/interfaces';
import { composeValidators } from '../../utils/validation';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../constants';
import useFormFileUpload, { FormConfigProps } from './useFormFileUpload';
import { useForm, useFormState } from 'react-final-form';
import FileWithDownloadButton from '../../reusable/FileWithDownloadButton';

export default function CommunicationForm() {
    const { handleFileUpload } = useFormFileUpload();

    const { values } = useFormState();
    const form = useForm();

    const teamCommunicationFile =
        values?.communicationPlan?.teamCommunicationFile;
    const supplierCommunicationFile =
        values?.communicationPlan?.supplierCommunicationFile;

    return (
        <FormScreenWrapper title="Communication">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What is the team communication plan during a disaster?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage
                    formConfig={teamCommunicationFormConfig({
                        onChange: handleFileUpload,
                        isHidden: !!teamCommunicationFile?.id,
                    })}
                />
                {teamCommunicationFile?.id && (
                    <FileWithDownloadButton
                        isRemovable={true}
                        onDelete={() => {
                            form.change(
                                'communicationPlan.teamCommunicationFile',
                                null,
                            );
                        }}
                        id={teamCommunicationFile.id}
                        name={teamCommunicationFile?.fileName}
                        size={teamCommunicationFile?.fileSize}
                        date={teamCommunicationFile?.createdAt}
                    />
                )}
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'How will you communicate with suppliers, and customers?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage
                    formConfig={supplierCommunicationFormConfig({
                        onChange: handleFileUpload,
                        isHidden: !!supplierCommunicationFile?.id,
                    })}
                />
                {supplierCommunicationFile?.id && (
                    <FileWithDownloadButton
                        isRemovable={true}
                        onDelete={() => {
                            form.change(
                                'communicationPlan.supplierCommunicationFile',
                                null,
                            );
                        }}
                        id={supplierCommunicationFile.id}
                        name={supplierCommunicationFile?.fileName}
                        size={supplierCommunicationFile?.fileSize}
                        date={supplierCommunicationFile?.createdAt}
                    />
                )}
            </Box>
        </FormScreenWrapper>
    );
}
function teamCommunicationFormConfig({
    onChange,
    isHidden,
}: FormConfigProps): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'twoThird',
                name: 'communicationPlan.teamCommunication',
                type: 'textarea',
                label: '',
            },
            renderProps: {
                resize: 'none',
                isDisabled: false,
                minRows: 3,
            },
        },
        {
            formField: {
                scheme: 'third',
                name: 'communicationPlan.teamCommunicationFile',
                type: 'fileupload',
                validation: composeValidators(
                    tooLargeFile(MAX_FILE_SIZE),
                    tooManyFiles(1),
                    invalidFileType([
                        'image/png',
                        'image/jpg',
                        'image/jpeg',
                        'image/bmp',
                        'application/pdf',
                    ]),
                ),
                onChange: (e) =>
                    onChange({
                        document: e as File[],
                        type: 'ER_TEAM_COMMUNICATION_PLAN',
                        fieldName: 'communicationPlan.teamCommunicationFile',
                    }),
                isHidden: isHidden,
            },
            renderProps: {
                filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
                placeholder: 'Drag a document here',
                helperText: 'Attach Document',
                multipleFiles: false,
                isDisabled: false,
            },
        },
    ];
}
function supplierCommunicationFormConfig({
    onChange,
    isHidden,
}: FormConfigProps): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'twoThird',
                name: 'communicationPlan.supplierCommunication',
                type: 'textarea',
                label: '',
            },
            renderProps: {
                resize: 'none',
                isDisabled: false,
                minRows: 3,
            },
        },
        {
            formField: {
                scheme: 'third',
                name: 'communicationPlan.supplierCommunicationFile',
                type: 'fileupload',
                validation: composeValidators(
                    tooLargeFile(MAX_FILE_SIZE),
                    tooManyFiles(1),
                    invalidFileType([
                        'image/png',
                        'image/jpg',
                        'image/jpeg',
                        'image/bmp',
                        'application/pdf',
                    ]),
                ),
                isHidden: isHidden,
                onChange: (e) =>
                    onChange({
                        document: e as File[],
                        type: 'ER_CUSTOMER_COMMUNICATION_PLAN',
                        fieldName:
                            'communicationPlan.supplierCommunicationFile',
                    }),
            },
            renderProps: {
                filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
                placeholder: 'Drag a document here',
                helperText: 'Attach Document',
                multipleFiles: false,
                isDisabled: false,
            },
        },
    ];
}
