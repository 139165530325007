import PreviewFormWrapper from './PreviewFormWrapper';
import { InsurancePreparednessFormValues } from '../model';

import { Box } from '@mui/material';

import TextWithTitle from '../../../reusable/TextWithTitle';
import React from 'react';
import FileWithDownloadButton from '../../../reusable/FileWithDownloadButton';

interface OwnProps {
    data: InsurancePreparednessFormValues;
}
export default function InsuranceFinancialPreview({ data }: OwnProps) {
    const propertyCoverageFile = data?.propertyCoverageFile;
    const damageDocumentationPlanFile = data?.damageDocumentationPlanFile;

    return (
        <PreviewFormWrapper label={'Insurance and Financial Prepardness'}>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}>
                <Box
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={2}>
                    <TextWithTitle
                        title={'Insurance Coverage'}
                        value={data?.propertyCoverage}
                    />

                    {propertyCoverageFile?.id && (
                        <FileWithDownloadButton
                            isRemovable={false}
                            id={propertyCoverageFile.id}
                            name={propertyCoverageFile?.fileName}
                            size={propertyCoverageFile?.fileSize}
                            date={propertyCoverageFile?.createdAt}
                        />
                    )}
                </Box>
                <Box
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={2}>
                    <TextWithTitle
                        title={'Plan for documenting damage for claims'}
                        value={data?.damageDocumentationPlan}
                    />

                    {damageDocumentationPlanFile?.id && (
                        <FileWithDownloadButton
                            isRemovable={false}
                            id={damageDocumentationPlanFile?.id}
                            name={damageDocumentationPlanFile?.fileName}
                            size={damageDocumentationPlanFile?.fileSize}
                            date={damageDocumentationPlanFile?.createdAt}
                        />
                    )}
                </Box>
                <Box
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={2}>
                    <TextWithTitle
                        title={'Insurance and financial document storage'}
                        value={data?.financialRecordsLocation}
                    />
                </Box>
            </Box>
        </PreviewFormWrapper>
    );
}
