import FormScreenWrapper from '../FormScreenWrapper';
import { Typography } from '@mui/material';

import EmergencyContactCardList from './EmergencyContactCardList';

import SearchContactField from '../SearchContactField/SearchContactField';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FieldProps } from 'react-final-form';
import { EmergencyContactData } from '../model';

interface OwnProps {
    input: FieldProps<EmergencyContactData[], never>;
}

export default function EmergencyContactsForm({ input }: OwnProps) {
    const { value, onChange } = input;
    const { id } = useParams();
    const [contacts, setContacts] = useState<EmergencyContactData[]>(
        value || [],
    );

    useEffect(() => {
        onChange(contacts);
    }, [contacts]);

    function handleSelectOption(option: EmergencyContactData['contact']) {
        setContacts([...contacts, { contact: option, contactTypes: [] }]);
    }

    return (
        <FormScreenWrapper title="Emergency Contacts">
            <Typography variant="font16" color="color.neutral9">
                When the unexpected strikes, clarity is key. From your daily
                operations team to your insurance agent, local emergency
                services, agronomist, attorney, equipment tech, and government
                agents—these are the people who will help you navigate the
                challenges and get you back on track. Take a moment to gather
                and update your list of these critical contacts.
            </Typography>
            <SearchContactField
                onSelect={handleSelectOption}
                organizationId={id}
            />
            <EmergencyContactCardList
                setContacts={setContacts}
                data={contacts}
            />
        </FormScreenWrapper>
    );
}
