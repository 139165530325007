import React from 'react';
import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import GenericTableHeaderCell, {
    TableHeaderCell,
} from './GenericTableHeaderCell';
import { makeStyles } from 'tss-react/mui';

export interface TableHeaderProps {
    header?: TableHeaderCell[];
    multiselect?: boolean;
    selected?: boolean;
    onSelect?: () => void;
    expandable?: boolean;
}
export default function GenericTableHeader({
    header,
    multiselect,
    selected,
    onSelect,
    expandable,
}: TableHeaderProps) {
    const { classes } = useStyles();
    return (
        <TableHead>
            <TableRow className={classes.tableHead}>
                {multiselect && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            className={classes.selectAllCheckBox}
                            checked={selected}
                            onChange={onSelect}
                        />
                    </TableCell>
                )}
                {header &&
                    header.map(
                        (
                            {
                                label,
                                sortable,
                                sorting,
                                isHidden,
                                onClick,
                                tooltip,
                            },
                            index,
                        ) => (
                            <GenericTableHeaderCell
                                key={index}
                                label={label}
                                sortable={sortable}
                                sorting={sorting}
                                isHidden={isHidden}
                                onClick={onClick}
                                tooltip={tooltip}
                            />
                        ),
                    )}
                {expandable && <TableCell />}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles()((theme) => ({
    tableHead: {
        position: 'relative',
        backgroundColor: theme.palette.neutral.neutral1,
        borderBottom: `1px solid ${theme.palette.neutral.neutral2}`,
        height: 40,
        minWidth: 650,
        fontWeight: 600,
        fontSize: 13,
    },
    selectAllCheckBox: {
        color: theme.palette.primary.main,
    },
    actions: {
        '& svg': {
            width: '16px',
            height: '16px',
            transition: 'transform 0.3s',
        },
    },
}));
