import React, { useEffect, useState } from 'react';
import { useGetAllContactsQuery } from '../../../api/contacts/contactsAPI';
import { Box, Divider, Grid, MenuItem, Select } from '@mui/material';
import AutocompleteField, {
    AutocompleteOption,
} from '../../../design/Fields/AutocompleteField';
import FieldLabelWithTooltip from '../../../design/Fields/FieldLabelWithTooltip';
import AppTypography from '../../../design/AppTypography';
import Loader from '../../../design/BaseLoader';
import { ContactLandPartnerView } from '../../../pages/AllProfiles/AllProfilesTable';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import { SelectChangeEvent } from '@mui/material/Select';
import { useCreateGuestMutation } from '../../../api/users/guestAPI';
import { DocumentData } from './HasAccessList';

interface OwnProps {
    input: FieldInputProps<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        HTMLElement
    >;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta: FieldMetaState<any>;
    data: DocumentData[];
}

type Permissions = 'VIEW';
interface UserAccess {
    contactId: string;
    accessType: Permissions;
    guestId: string;
}
export default function SharedContactArrayField({
    input,
    meta,
    data: documentData,
}: OwnProps) {
    const { value, onChange } = input;
    const [createGuest] = useCreateGuestMutation();
    const [userAccess, setUserAccess] = useState<UserAccess[]>(value || []);
    const [contactsId, setContactsId] = useState<AutocompleteOption[]>(
        value || [],
    );

    async function handleContacts(selectedContacts: AutocompleteOption[]) {
        const uniqueContacts = selectedContacts.filter(
            (contact, index, self) =>
                index === self.findIndex((c) => c.id === contact.id),
        );

        const newUserAccesses: (UserAccess | null)[] = await Promise.all(
            uniqueContacts.map(async (contact) => {
                if (!userAccess.find((ua) => ua.contactId === contact.id)) {
                    const guestResponse = await createGuest({
                        contactId: contact.id,
                        primaryEmail: contact.email,
                    }).unwrap();
                    return {
                        contactId: guestResponse.contactId,
                        accessType: 'VIEW',
                        guestId: guestResponse.id,
                    };
                }
                return null;
            }),
        );

        const filteredUserAccesses = newUserAccesses.filter(
            (ua): ua is UserAccess => ua !== null,
        );
        setUserAccess((prev) => [...prev, ...filteredUserAccesses]);

        setContactsId(uniqueContacts);
    }

    useEffect(() => {
        onChange(userAccess);
    }, [userAccess]);

    function handleUserAccess(accessType: Permissions, contactId: string) {
        setUserAccess((prev) => {
            const updatedAccess = prev.filter(
                (ua) => ua.contactId !== contactId,
            );
            return [...updatedAccess, { contactId, accessType, guestId: '' }];
        });
    }

    const { data, isLoading } = useGetAllContactsQuery({ page: 0, size: 2000 });

    if (isLoading) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            rowGap={2}>
            <Box width={'100%'}>
                <FieldLabelWithTooltip
                    isRequired={true}
                    label={'Whom do you want to share with?'}
                />
                <AutocompleteField
                    value={contactsId}
                    errorText={meta.touched && meta.error}
                    getOptionDisabled={(option: AutocompleteOption) =>
                        !option?.email
                    }
                    onChange={(value) =>
                        handleContacts(value as AutocompleteOption[])
                    }
                    options={filterContactsWhoHasAccess(
                        data?.data || [],
                        documentData,
                    )}
                    name="contacts"
                />
            </Box>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                sx={{ maxHeight: '15vh', overflowY: 'auto' }}
                rowGap={2}>
                {contactsId.map((contact: AutocompleteOption) => (
                    <Box
                        key={contact.id}
                        width={'100%'}
                        display={'flex'}
                        columnGap={2}>
                        <Box width={'60%'}>
                            <AppTypography
                                flexWrap={true}
                                component={'span'}
                                variant={'font14'}
                                text={contact.name}
                                fontWeight={'bold'}
                            />
                        </Box>
                        <Box width={'40%'}>
                            <Select
                                fullWidth
                                size={'small'}
                                sx={{ height: '30px' }}
                                value={
                                    userAccess.find(
                                        (ua) => ua.contactId === contact.id,
                                    )?.accessType || 'VIEW'
                                }
                                onChange={(e: SelectChangeEvent<Permissions>) =>
                                    handleUserAccess(
                                        e.target.value as Permissions,
                                        contact.id,
                                    )
                                }>
                                <MenuItem value={'VIEW'}>Viewer</MenuItem>
                                <Divider
                                    orientation="horizontal"
                                    variant={'middle'}
                                    flexItem
                                />
                            </Select>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

function filterContactsWhoHasAccess(
    contacts: ContactLandPartnerView[],
    data: DocumentData[],
): AutocompleteOption[] {
    return contacts
        .filter((contact) => {
            return !data.find((doc) => doc.contact.id === contact.id);
        })
        .map((contact) => ({
            id: contact.id,
            name: `${contact.firstName} ${contact.lastName} - ${contact?.primaryEmail || 'Email address not available'}`,
            email: contact.primaryEmail,
        }));
}
