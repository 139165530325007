import React from 'react';
import PreviewFormWrapper from './PreviewFormWrapper';
import { CommunicationPlan } from '../model';

import { Box, Typography } from '@mui/material';

import TextWithTitle from '../../../reusable/TextWithTitle';
import FileWithDownloadButton from '../../../reusable/FileWithDownloadButton';

interface OwnProps {
    data: CommunicationPlan;
}
export default function CommunicationPlanPreview({ data }: OwnProps) {
    const teamCommunicationFile = data?.teamCommunicationFile;
    const supplierCommunicationFile = data?.supplierCommunicationFile;

    return (
        <PreviewFormWrapper label={'Communication Plan'}>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                columnGap={2}>
                <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'space-between'}>
                    <Box width={'50%'}>
                        <TextWithTitle
                            title={'Team communication during disaster'}
                            value={data?.teamCommunication}
                        />
                    </Box>
                    <Box width={'50%'}>
                        <Typography variant={'font14'} fontWeight="bold">
                            {'Documents'}
                        </Typography>
                        {teamCommunicationFile?.id && (
                            <FileWithDownloadButton
                                isRemovable={false}
                                id={teamCommunicationFile?.id}
                                name={teamCommunicationFile?.fileName}
                                size={teamCommunicationFile?.fileSize}
                                date={teamCommunicationFile?.createdAt}
                            />
                        )}
                    </Box>
                </Box>
                <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'space-between'}>
                    <Box width={'50%'}>
                        <TextWithTitle
                            title={'Communication for Suppliers and Customers?'}
                            value={data?.supplierCommunication}
                        />
                    </Box>
                    <Box width={'50%'}>
                        <Typography variant={'font14'} fontWeight="bold">
                            {'Documents'}
                        </Typography>
                        {supplierCommunicationFile?.id && (
                            <FileWithDownloadButton
                                isRemovable={false}
                                id={supplierCommunicationFile?.id}
                                name={supplierCommunicationFile?.fileName}
                                size={supplierCommunicationFile?.fileSize}
                                date={supplierCommunicationFile?.createdAt}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </PreviewFormWrapper>
    );
}
