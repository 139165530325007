import PreviewFormWrapper from './PreviewFormWrapper';
import { ContactData } from '../model';
import PreviewContactCard from './PreviewContactCard';
import { Box } from '@mui/material';
import AppTypography from '../../../design/AppTypography';

interface OwnProps {
    primaryLead: ContactData;
    secondaryLead: ContactData;
}
export default function RecoveryProceduresContactsPreview({
    primaryLead,
    secondaryLead,
}: OwnProps) {
    return (
        <PreviewFormWrapper label={'Contacts: Recovery Procedures'}>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={1}
                    width={'100%'}>
                    <div>
                        <AppTypography
                            variant="font16"
                            fontWeight={'medium'}
                            color={'text.neutral10'}
                            text={`Primary Lead:`}
                        />
                    </div>
                    <PreviewContactCard contact={primaryLead} />
                </Box>

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={1}
                    width={'100%'}>
                    <div>
                        <AppTypography
                            variant="font16"
                            fontWeight={'medium'}
                            color={'text.neutral10'}
                            text={`Secondary Lead:`}
                        />
                    </div>
                    <PreviewContactCard contact={secondaryLead} />
                </Box>
            </Box>
        </PreviewFormWrapper>
    );
}
