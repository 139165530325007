import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download_icon.svg';
import PreviewModal from '../../reusable/Modal/PreviewModal';
import { useLazyGetFileQuery } from '../../api/fileApiSlice';
import {
    createDownloadLinkFromBlob,
    extractExtensionContentDisposition,
    extractFileNameContentDisposition,
    getFilenameFromContentDisposition,
} from '../../utils/fileHandler';
import { convertBytesToMbOrKb } from '../../utils/dataConvertor';
import FileIconView from '../../reusable/FileIconView';
import { makeStyles } from 'tss-react/mui';

export interface FileMetadata {
    id: string;
    name: string;
    size?: number | string;
    date: string;
}

export default function LeaseFileWithDownloadButton({
    name,
    id,
    size,
    date,
}: FileMetadata) {
    const [isShowing, setIsShowing] = useState(false);
    const [file, setFile] = useState<string | null>(null);
    const [header, setHeader] = useState<string | undefined>();

    const [
        getItems,
        { data: responsePromise, isLoading: isDocumentLoading, isFetching },
    ] = useLazyGetFileQuery();

    const { classes } = useStyles();

    const handlePreviewOpen = async () => {
        getItems(id);
        setIsShowing(true);
    };

    const handleDownload = async () => {
        getItems(id)
            .unwrap()
            .then(({ data, headers }) => {
                createDownloadLinkFromBlob(
                    data,
                    getFilenameFromContentDisposition(headers),
                );
            });
    };

    const handleClose = () => {
        setIsShowing(false);
        setFile(null);
    };

    useEffect(() => {
        if (!responsePromise) return;
        const { data, headers }: { data: Blob; headers: string } =
            responsePromise;
        const fileData = data && URL.createObjectURL(data);
        if (fileData) setFile(fileData);
        if (headers) setHeader(headers);
    }, [responsePromise]);

    const formattedSize = size ? convertBytesToMbOrKb(size) : 'N/A';

    return (
        <Box className={classes.fileContainer}>
            <Tooltip title={name} arrow>
                <Typography
                    className={classes.fileName}
                    variant="font14"
                    color="text.link"
                    fontWeight="regular"
                    onClick={handlePreviewOpen}
                    noWrap>
                    {name}
                </Typography>
            </Tooltip>
            <Tooltip title={size ? `Estimate ${formattedSize}` : 'N/A'} arrow>
                <Typography
                    className={classes.fileSize}
                    variant="font14"
                    color="text.neutral8"
                    noWrap>
                    {size ? formattedSize : 'N/A'}
                </Typography>
            </Tooltip>
            <Tooltip title={date} arrow>
                <Typography
                    className={classes.fileDate}
                    variant="font14"
                    color="text.neutral8"
                    noWrap>
                    {date}
                </Typography>
            </Tooltip>
            <Box className={classes.iconsContainer}>
                <Tooltip title="Preview File" arrow>
                    <IconButton onClick={handlePreviewOpen}>
                        <FileIconView name={name} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Download File" arrow>
                    <IconButton onClick={handleDownload}>
                        <DownloadIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            {header && (
                <PreviewModal
                    extension={extractExtensionContentDisposition(header)}
                    fileName={extractFileNameContentDisposition(header)}
                    file={file}
                    isLoading={isDocumentLoading || isFetching}
                    isShowing={isShowing}
                    onClose={handleClose}
                />
            )}
        </Box>
    );
}

const useStyles = makeStyles()(() => ({
    fileContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        columnGap: 12,
        overflow: 'hidden',
    },
    fileName: {
        flex: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    fileSize: {
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    fileDate: {
        flex: 1,
    },
    iconsContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
}));
