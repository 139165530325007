import React from 'react';
import {
    useUpdateEmergencyReadinessMutation,
    useSubmitEmergencyReadinessMutation,
} from '../../api/emergencyReadiness/emergencyReadiness';
import WizardForm from '../../design/Forms/WizardForm';
import arrayMutators from 'final-form-arrays';
import EmergencyContactsForm from './EmergencyContactPage/EmergencyContactsForm';
import RiskAssessmentForm from './RiskAssessmentForm';
import CriticalResourcesProcessesForm from './CriticalResourcesProcessesForm';
import EvacuationSafetyForm from './EvacuationSafetyForm';
import CommunicationForm from './CommunicationForm';
import CropProtectionForm from './CropProtectionForm';
import ResourceManagementForm from './ResourceManagementForm';
import RecoveryProceduresForm from './RecoveryProceduresForm';
import EnvironmentalConcernsForm from './EnvironmentalConcernsForm';
import InsuranceFinancialPreparednessForm from './InsuranceFinancialPreparednessForm';
import ExternalAssistanceForm from './ExternalAssistanceForm';
import TrainingPreparednessForm from './TrainingPreparednessForm';
import ContinuityOfOperationsForm from './ContinuityOfOperationsForm';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import PATHS from '../../router/paths';
import { useNavigate } from 'react-router-dom';
import { EmergencyFormValues, EmergencyReadinessValues } from './model';
import { Field } from 'react-final-form';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../../utils/dateFormat';
import PreviewPage from './Preview/PreviewPage';
interface OwnProps {
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
    planId?: string;
    initialValues: EmergencyReadinessValues;
}

export default function EmergencyReadinessFormPage({
    pageNumber,
    setPageNumber,
    planId,
    initialValues,
}: OwnProps) {
    const navigate = useNavigate();

    const [updatePlan] = useUpdateEmergencyReadinessMutation();

    const { handleSubmit, isLoading: isCreating } = useHandleSubmitRTK({
        useRtkHook: useSubmitEmergencyReadinessMutation,
        onSuccess: () =>
            navigate(`/${PATHS.services}/${PATHS.emergencyReadiness}`),
        successMessage: 'Emergency Readiness created successfully',
    });

    const handleAutosaveOnFormChange = debounce(
        (values: EmergencyFormValues) => {
            const valuesToSubmit = formValueSubmitAdapter(
                values,
            ) as EmergencyReadinessValues;
            updatePlan({
                id: planId,
                body: { ...valuesToSubmit, lastActiveStep: pageNumber },
            });
        },
        2000,
    );
    function handlePlanningSubmit() {
        return handleSubmit(planId);
    }

    return (
        <WizardForm
            key={JSON.stringify(initialValues)}
            initialValues={initialValues}
            isLoading={isCreating}
            onSubmit={handlePlanningSubmit}
            onChange={(values: EmergencyFormValues) =>
                handleAutosaveOnFormChange(values)
            }
            mutators={arrayMutators as never}
            setStep={setPageNumber}
            step={pageNumber}>
            <Field
                name={'emergencyContacts'}
                render={({ input }) => <EmergencyContactsForm input={input} />}
            />
            <RiskAssessmentForm />
            <CriticalResourcesProcessesForm />
            <EvacuationSafetyForm />
            <CommunicationForm />
            <CropProtectionForm />
            <ResourceManagementForm />
            <RecoveryProceduresForm />
            <EnvironmentalConcernsForm />
            <InsuranceFinancialPreparednessForm />
            <ExternalAssistanceForm />
            <TrainingPreparednessForm />
            <ContinuityOfOperationsForm />
            <PreviewPage />
        </WizardForm>
    );
}

function debounce(func: (args: EmergencyFormValues) => void, wait: number) {
    let timeout: NodeJS.Timeout;
    return function executedFunction(args: EmergencyFormValues) {
        const later = () => {
            clearTimeout(timeout);
            func(args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
function formValueSubmitAdapter(
    values: EmergencyFormValues,
): EmergencyReadinessValues {
    return {
        ...values,
        recoveryProcedures: {
            recoveryPlan: values.recoveryProcedures?.recoveryPlan,
            primaryLeadId: values.recoveryProcedures?.primaryLead?.id,
            secondaryLeadId: values.recoveryProcedures?.secondaryLead?.id,
        },

        emergencyContacts: values.emergencyContacts?.map((contact) => ({
            contactId: contact.contact.id,
            contactTypes: contact.contactTypes,
        })),
        cropProtection: {
            measuresToTake: values.cropProtection?.measuresToTake,
            insuranceAgentId: values.cropProtection?.insuranceAgent?.id,
        },
        resourceManagement: {
            backupPowerSources: values.resourceManagement?.backupPowerSources,
            essentialSupplies: values.resourceManagement?.essentialSupplies,
            localStoreAgentId: values.resourceManagement?.localStoreAgent?.id,
            additionalResourcesPlan:
                values.resourceManagement?.additionalResourcesPlan,
        },
        insurancePreparedness: {
            propertyCoverage: values.insurancePreparedness?.propertyCoverage,
            insuranceAgentId: values.insurancePreparedness?.insuranceAgent?.id,
            bankerId: values.insurancePreparedness?.banker?.id,
            damageDocumentationPlan:
                values.insurancePreparedness?.damageDocumentationPlan,
            financialRecordsLocation:
                values.insurancePreparedness?.financialRecordsLocation,
        },
        externalAssistance: {
            usdaFarmContactId: values.externalAssistance?.usdaFarmContact?.id,
            agronomistId: values.externalAssistance?.agronomist?.id,
            disasterAssistanceFamiliarity:
                values.externalAssistance?.disasterAssistanceFamiliarity,
        },
        trainingPreparedness: {
            emergencyDrill: {
                attendeesIds:
                    values.trainingPreparedness?.emergencyDrill?.attendeesIds,
                scheduledEvent:
                    values.trainingPreparedness?.emergencyDrill
                        ?.scheduledEvent &&
                    format(
                        new Date(
                            values.trainingPreparedness?.emergencyDrill?.scheduledEvent,
                        ),
                        DATE_FORMAT,
                    ),
            },
            reviewFrequency: {
                days: values.trainingPreparedness?.reviewFrequency?.days,
                exactDate:
                    values.trainingPreparedness?.reviewFrequency?.exactDate &&
                    format(
                        new Date(
                            values.trainingPreparedness?.reviewFrequency?.exactDate,
                        ),
                        DATE_FORMAT,
                    ),
            },
        },
    } as EmergencyReadinessValues;
}
