import { useForm, useFormState } from 'react-final-form';
import { useDocumentUploadMutation } from '../../api/documents/documentsAPI';
import customToastWithAlert from '../../design/CustomToastWithAlert';
import { useRef } from 'react';

export interface FileUploadProps {
    document: File[];
    type: string;
    fieldName: string;
}

export interface FormConfigProps {
    onChange: ({ document, type }: FileUploadProps) => void;
    isHidden?: boolean;
}

export default function useFormFileUpload() {
    const { values } = useFormState();
    const form = useForm();
    const [handleSubmit] = useDocumentUploadMutation();
    const lastUploadedFile = useRef<File | null>(null);

    function handleFileUpload({ document, type, fieldName }: FileUploadProps) {
        if (lastUploadedFile.current === document[0]) return;
        lastUploadedFile.current = document[0];

        handleSubmit({
            documents: document[0],
            parentId: values.planId,
            documentType: type,
        })
            .unwrap()
            .then((res) => {
                customToastWithAlert({
                    type: 'success',
                    message: 'File uploaded successfully',
                });
                form.change(fieldName, res);
            })
            .catch((error) => {
                customToastWithAlert({
                    type: 'error',
                    message: error?.data?.description || 'Something went wrong',
                });
            });
    }

    return {
        handleFileUpload,
    };
}
