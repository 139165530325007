import { useSettingsFromStorage } from '../../hooks/useSettingsFromStorage';
import { useSorting } from '../../design/GenericTable/useSorting';
import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { useSaveSettings } from '../../hooks/useSaveSettings';
import { useParams } from 'react-router-dom';
import { useLeaseHistoryQuery } from '../../api/leases/leaseAPI';
import { Lease } from '../../pages/Leases/TableConfig';
import { TableColumn } from '../../design/GenericTable/GenericTable';
import { dateTimeConverterFromUTC } from '../../utils/dateFormat';

type Action =
    | 'NEW_LEASE'
    | 'LEASE_EDITED'
    | 'LEASE_FILE_UPLOADED'
    | 'LEASE_FILE_DOWNLOADED'
    | 'RESTORE_LEASE_NOTE'
    | 'ARCHIVE_LEASE_NOTE'
    | 'DELETE_LEASE_NOTE'
    | 'UPDATE_LEASE_NOTE'
    | 'NEW_LEASE_NOTE';

export interface LeaseHistoryData {
    id: string;
    entityName: string;
    entityId: string;
    action: Action;
    createdBy: string;
    createdByName: string;
    valueFrom: Lease;
    valueTo: Lease;
    createdAt: string;
}

export default function useLeaseHistoryTable() {
    const { id } = useParams();
    const { settings, saveSettings } = useSettingsFromStorage(
        'LEASE_HISTORY_TABLE',
    );
    const { sort, page, sortDirection, size } = settings;
    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'leaseName',
        sortDirection || 'desc',
    );

    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);

    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });

    const { data, isLoading } = useLeaseHistoryQuery({
        leaseId: id,
        page: currentPage - 1,
        size: itemsPerPage,
        sort: 'createdAt,desc',
    });

    const columns: TableColumn<LeaseHistoryData>[] = [
        {
            header: {
                label: 'Date',
                sortable: false,
                sorting: {
                    id: 'createdAt',
                    direction: sortKey === 'createdAt' ? sortOrder : 'desc',
                    isSorted: sortKey === 'createdAt',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <>{dateTimeConverterFromUTC(data?.createdAt)}</>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'User Name',
                sortable: false,
                sorting: {
                    id: 'createdBy',
                    direction: sortKey === 'createdBy' ? sortOrder : 'desc',
                    isSorted: sortKey === 'createdBy',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{data?.createdByName}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Action',
                sortable: false,
                sorting: {
                    id: 'action',
                    direction: sortKey === 'action' ? sortOrder : 'desc',
                    isSorted: sortKey === 'action',
                },
                onClick: handleSort,
            },
            cellRender: (data) => <>{LeaseActionMapper[data?.action]}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Note',
                sortable: false,
            },
            cellRender: (data) => <>{LeaseNoteActionMapper[data?.action]}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];
    const tableConfig = {
        columns,
        pageable: true,
        handlePageChange,
        handleItemsPerPageChange,
        initialPageSize: size,
        initialPage: page,
    };

    const totalCount = data?.totalCount || 0;
    return { data: data?.data || [], totalCount, tableConfig, isLoading };
}

const LeaseActionMapper: Record<Action, string> = {
    NEW_LEASE: 'New Lease',
    LEASE_EDITED: 'Lease edited',
    LEASE_FILE_UPLOADED: 'Lease file uploaded',
    LEASE_FILE_DOWNLOADED: 'Lease file downloaded',
    NEW_LEASE_NOTE: 'New lease note',
    RESTORE_LEASE_NOTE: 'Restore note',
    ARCHIVE_LEASE_NOTE: 'Archive note',
    DELETE_LEASE_NOTE: 'Delete note',
    UPDATE_LEASE_NOTE: 'Update note',
};
const LeaseNoteActionMapper: Record<Action, string> = {
    NEW_LEASE: 'New Lease is created',
    LEASE_EDITED: 'Lease is edited',
    LEASE_FILE_UPLOADED: 'Lease file is uploaded',
    LEASE_FILE_DOWNLOADED: 'Lease file is downloaded',
    NEW_LEASE_NOTE: 'New lease note is created',
    RESTORE_LEASE_NOTE: 'Note is restored',
    ARCHIVE_LEASE_NOTE: 'Note is archived',
    DELETE_LEASE_NOTE: 'Note is deleted',
    UPDATE_LEASE_NOTE: 'Note is updated',
};
