import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

interface OwnProps {
    children: ReactNode;
    label?: string;
}
export default function PreviewFormWrapper({
    children,

    label,
}: OwnProps) {
    const { classes } = useStyles();
    return (
        <div className={classes.viewWrapper}>
            <Typography
                variant={'font20'}
                fontWeight={'regular'}
                color={'text.neutral9'}>
                {label}
            </Typography>

            <div className={classes.itemsWrapper}>{children}</div>
        </div>
    );
}

const useStyles = makeStyles()((theme) => ({
    viewWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
        rowGap: 16,
    },
    itemsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'space-between',
        padding: theme.spacing(3, 2),
        backgroundColor: theme.palette.backgroundB.backgroundB4,
    },
}));
