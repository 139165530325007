import FormScreenWrapper from './FormScreenWrapper';
import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import DndItemsList from './DndField/DndItemsList';
import { Field } from 'react-final-form';

export default function RiskAssessmentForm() {
    return (
        <FormScreenWrapper title="Risk Assessment">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What are the most likely natural disasters that could affect the farm?'
                    }
                    color={'text.neutral9'}
                />
                <Field
                    name={'riskAssessment.naturalDisasters'}
                    render={({ input }) => (
                        <DndItemsList
                            input={input}
                            addButtonLabel={'Add a Disaster'}
                        />
                    )}
                />
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What are the potential man-made disasters that could impact operations?'
                    }
                    color={'text.neutral9'}
                />
                <Field
                    name={'riskAssessment.manMadeDisasters'}
                    render={({ input }) => (
                        <DndItemsList
                            input={input}
                            addButtonLabel={'Add a Disaster'}
                        />
                    )}
                />
            </Box>
        </FormScreenWrapper>
    );
}
