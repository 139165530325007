import { TableColumn } from '../../../design/GenericTable/GenericTable';
import React from 'react';
import LinkText from '../../../design/Fields/LinkText';
import PercentageCell from '../../../reusable/PercentageCell';
import { useNavigate } from 'react-router-dom';
import Paths from '../../../router/paths';
import { useGetEmergencyReadinessPlansQuery } from '../../../api/emergencyReadiness/emergencyReadiness';
import { EmergencyReadinessResponse, pathMapper } from '../../Succession/model';
import { Box, Button } from '@mui/material';

export default function useSolutionsInProgressTable() {
    const navigate = useNavigate();

    function handleContinueClick(id: string, lastActivePage = 0) {
        navigate(`/${Paths.emergencyReadiness}?organizationId=${id}`, {
            state: { stepNumber: lastActivePage },
        });
    }
    const { data: responseData, isLoading } =
        useGetEmergencyReadinessPlansQuery({
            status: 'IN_PROGRESS',
            sort: `updatedAt,desc`,
            page: 0,
            size: 5,
        });

    const columns: TableColumn<EmergencyReadinessResponse>[] = [
        {
            header: {
                label: 'Name',
                sortable: false,
            },
            cellRender: (data) => (
                <Box display={'flex'} alignItems={'center'} columnGap={1}>
                    <LinkText
                        to={`/${Paths.landpartners}/${pathMapper[data.organization.type]}/${data.organization.id}`}
                        text={`${data.organization.name}`}
                        variant={'font14'}
                    />
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Solution',
                sortable: false,
            },
            cellRender: () => <>Emergency Readiness</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '19%',
        },
        {
            header: {
                label: 'Total Days',
                sortable: false,
            },
            cellRender: (data) => (
                <>{daysWeeksMonthsYearsBetweenDates(data?.createdAt)}</>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: '16%',
        },
        {
            header: {
                label: 'Last Active',
                sortable: false,
            },
            cellRender: (data) => (
                <>
                    {daysWeeksMonthsYearsBetweenDates(data?.updatedAt) + ' ago'}
                </>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: '19%',
        },
        {
            header: {
                label: 'Progress',
                sortable: false,
            },
            cellRender: (data) => (
                <PercentageCell progress={data?.percentageCompleted || 0} />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: '16%',
        },

        {
            header: {
                label: '',
                sortable: false,
            },
            cellRender: (data) => (
                <Box display="flex" columnGap={1}>
                    <Button
                        onClick={() =>
                            handleContinueClick(
                                data.organization.id,
                                data.lastActiveStep,
                            )
                        }>
                        Continue
                    </Button>
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];
    const tableConfig = {
        columns,
        pageable: false,
        expandable: false,
    };
    return { data: responseData?.data || [], tableConfig, isLoading };
}

function daysWeeksMonthsYearsBetweenDates(date: string): string {
    const today = new Date();
    const startDate = new Date(date);
    const diff = Math.abs(startDate.getTime() - today.getTime());
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);
    if (days < 7) return `${days} days`;
    if (weeks < 4) return `${weeks} weeks`;
    if (months < 12) return `${months} months`;
    return `${years} years`;
}
