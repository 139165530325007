import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import theme from '../../styles/theme';
import { Lease } from './TableConfig';
import { useGetLeaseDocumentsQuery } from '../../api/leases/leaseAPI';
import LeaseFileWithDownloadButton from './LeaseFileWithDownloadButton';
import { Document } from '../../features/ViewLeaseDetails/LeaseDetails';
import { dateConverterFromUTC } from '../../utils/dateFormat';

interface ExpandComponentProps {
    data: Lease;
}

export default function ExpandComponent({ data }: ExpandComponentProps) {
    const { data: documents, isLoading: isDocumentLoading } =
        useGetLeaseDocumentsQuery(data.id);

    if (isDocumentLoading)
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}>
                <Skeleton animation="wave" width="80%" />
                <Skeleton animation="wave" width="80%" />
                <Skeleton animation="wave" width="80%" />
            </Box>
        );

    return (
        <Box
            width={'100%'}
            display={'flex'}
            sx={{
                padding: theme.spacing(2, 3),
                backgroundColor: theme.palette.backgroundB.backgroundB2,
            }}
            columnGap={2}>
            <Box width={'16%'}>
                <Typography
                    variant="font14"
                    fontWeight="medium"
                    color="text.secondary">
                    Lease
                </Typography>
                <Box component="div">
                    <Typography variant="font14" color="text.neutral6">
                        {data.type}
                    </Typography>
                </Box>
            </Box>
            <Box width={'16%'}>
                <Typography
                    variant="font14"
                    fontWeight="medium"
                    color="text.secondary">
                    Payment Schedule
                </Typography>
                {!!data.paymentSchedule.length && (
                    <Box component="div">
                        <Typography variant="font14" color="text.neutral6">
                            {data.paymentSchedule?.join(', ')}
                        </Typography>
                    </Box>
                )}
            </Box>
            <Box width={'16%'}>
                <Typography
                    variant="font14"
                    fontWeight="medium"
                    color="text.secondary">
                    Land Partners
                </Typography>
                {!!data.landowners.length && (
                    <Box component="div">
                        <Typography variant="font14" color="text.neutral6">
                            {data.landowners.map((i) => i.name).join(', ')}
                        </Typography>
                    </Box>
                )}
            </Box>
            <Box width={'50%'}>
                <Typography
                    variant="font14"
                    fontWeight="medium"
                    color="text.secondary">
                    Available Downloads
                </Typography>
                {documents.map((file: Document, index: number) => (
                    <LeaseFileWithDownloadButton
                        key={index}
                        id={file.id}
                        name={file.fileName}
                        date={dateConverterFromUTC(file.createdAt)}
                        size={file.fileSize}
                    />
                ))}
            </Box>
        </Box>
    );
}
