import { useDownloadDocuments } from '../hooks/useDownloadDocuments';
import { Box, IconButton, Typography } from '@mui/material';
import { ReactComponent as DownloadIcon } from '../assets/icons/download_icon.svg';
import { ReactComponent as RemoveIcon } from '../assets/icons/remove_icon.svg';
import { convertBytesToMbOrKb } from '../utils/dataConvertor';
import PreviewModal from './Modal/PreviewModal';
import React from 'react';
import FileIconView from './FileIconView';
import { useDeleteDocumentMutation } from '../api/documents/documentsAPI';
import { dateTimeConverterFromUTC } from '../utils/dateFormat';

export interface FileMetadata {
    id: string;
    name: string;
    size?: number | string;
    date: string;
    isRemovable?: boolean;
    onDelete?: () => void;
}

export default function FileWithDownloadButton({
    name,
    id,
    size,
    date,
    onDelete,
    isRemovable = false,
}: FileMetadata) {
    const {
        isShowing,
        file,
        isLoading: isDocumentLoading,
        handleClose,
        handlePreviewOpen,
        handleDownload,
        extension,
    } = useDownloadDocuments();

    const [handleDelete] = useDeleteDocumentMutation();

    const fileExtension = name ? extension(name) : undefined;
    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            columnGap={2}
            maxWidth={700}>
            <Typography
                sx={{
                    '&:hover': {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    },
                }}
                variant="font14"
                color="text.link"
                fontWeight={'regular'}
                onClick={() => handlePreviewOpen(id)}>
                {name}
            </Typography>
            {size && (
                <Typography variant="font14" color="text.neutral8">
                    Estimate {convertBytesToMbOrKb(size)}
                </Typography>
            )}
            <Typography variant="font14" color="text.neutral8">
                {dateTimeConverterFromUTC(date)}
            </Typography>
            <Box display={'flex'} columnGap={1} alignItems={'center'}>
                <IconButton onClick={() => handlePreviewOpen(id)}>
                    <FileIconView name={name} />
                </IconButton>
                <IconButton onClick={() => handleDownload(id, name)}>
                    <DownloadIcon />
                </IconButton>
                {isRemovable && (
                    <IconButton
                        onClick={() => {
                            handleDelete({ documentId: id });
                            onDelete && onDelete();
                        }}>
                        <RemoveIcon />
                    </IconButton>
                )}
            </Box>
            {fileExtension && (
                <PreviewModal
                    extension={fileExtension as string}
                    fileName={name}
                    file={file}
                    isLoading={isDocumentLoading}
                    isShowing={isShowing}
                    onClose={handleClose}
                />
            )}
        </Box>
    );
}
