import React from 'react';
import { usePagination } from '../../../design/GenericTable/useBackendPagination';
import { TableColumn } from '../../../design/GenericTable/GenericTable';
import AppTypography from '../../../design/AppTypography';
import { useSelection } from '../../../design/GenericTable/useSelection';
import { QuickBookBills } from '../interfaces';
import { useGetListOfBillsQuery } from '../../../api/integrations/integrationsAPI';
import { dateConverterFromUTC } from '../../../utils/dateFormat';
import { formatCurrency } from '../../../utils/fieldMasks';

export function useImportPaymentsTable() {
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(1, 25);

    const {
        selected,
        isSelected,
        toggleSelection,
        toggleSelectionAll,
        isSelectedAll,
    } = useSelection<QuickBookBills>();

    const { data, isLoading } = useGetListOfBillsQuery({
        sort: `updatedAt,asc`,
        page: currentPage - 1,
        size: itemsPerPage,
    });

    const columns: TableColumn<QuickBookBills>[] = [
        {
            header: {
                label: 'Select All',
                sortable: false,
            },
            cellRender: (data) => (
                <AppTypography
                    variant={'font14'}
                    fontWeight={'regular'}
                    text={data.vendorName}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
        },
        {
            header: {
                label: 'Date',
                sortable: false,
            },
            cellRender: (data) => (
                <AppTypography
                    variant={'font14'}
                    fontWeight={'regular'}
                    text={dateConverterFromUTC(data?.dueDate)}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
        },
        {
            header: {
                label: 'Amount',
                sortable: false,
            },
            cellRender: (data) => (
                <AppTypography
                    variant={'font14'}
                    fontWeight={'regular'}
                    text={formatCurrency(data.amount)}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
        },
    ];
    const tableConfig = {
        columns,
        multiselect: true,
        pageable: true,
        handlePageChange,
        handleItemsPerPageChange,
        initialPageSize: 25,
        initialPage: 1,
        expandable: false,
        isSelected: isSelected,
        toggleSelection: toggleSelection,
        toggleSelectionAll: toggleSelectionAll,
        isSelectedAll: isSelectedAll,
    };

    const totalCount = data?.totalCount || 0;
    const syncData = data?.data || [];
    return { data: syncData, totalCount, isLoading, tableConfig, selected };
}
