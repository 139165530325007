import React, { forwardRef, ReactNode } from 'react';
import { TextField, InputAdornment } from '@mui/material';

export interface Input {
    onChange: (value: string) => void;
    //eslint-disable-next-line
    onBlur?: (e: any) => void;
    value?: string;
    placeholder?: string;
    isDisabled?: boolean;
    isValidationError?: boolean;
    errorText?: string;
    name?: string;
    size?: 'small' | 'medium';
    postfix?: ReactNode | string;
    prefix?: ReactNode | string;
    isHidden?: boolean;
    fieldType?: string;
}
//eslint-disable-next-line
const InputField = forwardRef(
    (
        {
            onChange,
            onBlur,
            value,
            placeholder,
            isDisabled,
            errorText,
            size,
            postfix,
            isHidden,
            fieldType,
            name,
            prefix,
        }: Input,
        ref,
    ) => {
        if (isHidden) return null;

        return (
            <TextField
                id={name}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                fullWidth={true}
                disabled={isDisabled}
                value={value}
                error={!!errorText}
                helperText={errorText}
                placeholder={placeholder}
                size={size}
                type={fieldType}
                inputRef={ref}
                InputProps={{
                    endAdornment: postfix && (
                        <InputAdornment position="end">
                            {postfix}
                        </InputAdornment>
                    ),
                    startAdornment: prefix && (
                        <InputAdornment position="start">
                            {prefix}
                        </InputAdornment>
                    ),
                }}
            />
        );
    },
);

export default InputField;
