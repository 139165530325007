import { Box, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import theme from '../../styles/theme';

interface OwnProps {
    step: string;
    isActive: boolean;
    isCompleted: boolean;
    isNavigable: boolean;
}

export default function GroupedCustomLabel({
    step,
    isActive,
    isCompleted,
    isNavigable,
}: OwnProps) {
    const { classes } = useStyles();

    return (
        <Box pl={4} className={isNavigable ? classes.navigableStep : undefined}>
            <Typography
                variant={'font16'}
                fontWeight={'medium'}
                color={
                    isActive || isCompleted
                        ? theme.palette.brandLightTurq
                        : theme.palette.neutral.neutral4
                }>
                {step}
            </Typography>
        </Box>
    );
}
const useStyles = makeStyles()(() => ({
    navigableStep: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
}));
