import { Option } from '../../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';
import { Grid, Skeleton } from '@mui/material';
import FieldLabelWithTooltip from '../../../design/Fields/FieldLabelWithTooltip';
import SelectField from '../../../design/Fields/SelectField';

interface OwnProps {
    label: string;
    value: string;
    options: Option[];
    onChange: (e: string) => void;
    isLoading: boolean;
    isDisabled?: boolean;
    isHidden?: boolean;
}

export default function SelectFieldWithLabel({
    label,
    value,
    options,
    onChange,
    isLoading,
    isDisabled,
    isHidden,
}: OwnProps) {
    if (isHidden) {
        return null;
    }

    if (isLoading) {
        return (
            <Grid item xs={6} spacing={1} pt={2} pb={1} alignItems={'center'}>
                <Skeleton
                    variant="rectangular"
                    animation={'wave'}
                    height={40}
                />
            </Grid>
        );
    }
    return (
        <Grid item xs={6} rowGap={1}>
            <FieldLabelWithTooltip label={label} />
            <SelectField
                isDisabled={isDisabled}
                fullWidth={true}
                value={value}
                options={options}
                onChange={onChange}
            />
        </Grid>
    );
}
