import { format } from 'date-fns';
import { Option } from '../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';
import { DateAmount } from './LeaseDetails';
import PATHS from '../../router/paths';
import LinkText from '../../design/Fields/LinkText';
import BasedTooltip from '../../design/BasedTooltip';
import { Box, Typography } from '@mui/material';
import InfoTooltip from '../../design/Tooltip/InfoTooltip';
import React from 'react';
import { Lease, LeaseFsaId } from '../../pages/Leases/TableConfig';
import { PaymentScheduledData } from '../PaymentsOverview/interfaces';
import { Table } from '../../design/GenericTable/GenericTable';
import { formatCurrency } from '../../utils/fieldMasks';
import ExpandedPaymentComponent from './ExpandedPaymentComponent';
import {
    DistributionDateAmounts,
    PaymentSchedule,
    TransformedPaymentData,
} from './LeasePayments';

export function convertLeaseDetailDataToField(data: Lease) {
    const fieldNameString = data?.fsaIds.map((i) => i?.landName).join('; ');

    return [
        {
            title: 'Land Partners',
            value: data?.landowners?.length
                ? data.landowners.map(
                      (
                          i: Option,
                          index: number,
                          array: { id: string; name: string }[],
                      ) => (
                          <LinkText
                              key={i.id}
                              to={`/${PATHS.landpartners}/${PATHS.profiles}/${i.id}`}
                              text={
                                  i.name +
                                  (index < array.length - 1 ? ', ' : '')
                              }
                              fontWeight={'normal'}
                          />
                      ),
                  )
                : 'N/A',
        },
        {
            title: 'Tenant',
            value: data?.grower?.name || data?.grower,
            isHidden: !data?.grower,
        },
        {
            title: 'Lease Type',
            value: data?.type,
        },
        {
            title: 'Number of Payments per Year',
            value: data?.paymentSchedule.length || 'N/A',
        },
        {
            title: 'Payment Schedule',
            value: data?.paymentSchedule.length
                ? data.paymentSchedule?.join(' ,')
                : 'N/A',
        },
        {
            title: 'Agent',
            value: data?.agent?.id ? (
                <LinkText
                    key={data?.agent?.id}
                    to={`/${PATHS.landpartners}/${PATHS.profiles}/${data?.agent?.id}`}
                    text={data?.agent?.name}
                    fontWeight={'normal'}
                />
            ) : (
                'N/A'
            ),
        },
        {
            title: 'Lease Start Date',
            value: data?.startDate || 'N/A',
        },
        {
            title: 'Lease End Date',
            value: data?.endDate || 'N/A',
        },

        {
            title: 'FSA ID',
            value: data?.fsaIds.map((i: LeaseFsaId) => i?.fsaId).join(' ,'),
            isHidden: !data?.fsaIds.length,
            copied: true,
        },
        {
            title: 'Field Name',
            value: fieldNameString,
            isHidden: !data?.fieldName,
            copied: true,
        },
        {
            title: 'Crop Delivery',
            value: data?.cropDelivery,
            isHidden: !data?.cropDelivery,
        },
        {
            title: 'Crop Storage',
            value: data?.cropStorage,
            isHidden: !data?.cropStorage,
        },
        {
            title: 'Field Percentage',
            value: data?.fieldPercent,
            isHidden: !data?.fieldPercent,
        },
        {
            title: 'Acres',
            value: data?.totalAcres,
            isHidden: !data?.totalAcres,
        },
        {
            title: 'Total Payment',
            value: data?.totalPayment,
            isHidden: !data?.totalPayment,
        },
        {
            title: 'Auto Renewal',
            value: data?.autoRenewal === true ? 'Yes' : 'No',
            isHidden: false,
        },
        {
            title: 'Verbal Lease',
            value: data?.verbalLease === true ? 'Yes' : 'No',
            isHidden: false,
        },
        {
            title: 'FSA POA',
            value: data?.fsaPoa === true ? 'Yes' : 'No',
            isHidden: false,
        },
        {
            title: 'Bonus Payment Date',
            value: data?.bonusSchedule,
            isHidden: !data?.bonusSchedule,
        },
        {
            title: 'Grace Period Date',
            value: data?.gracePeriodDate,
            isHidden: !data?.gracePeriodDate,
        },
    ];
}

export function extractMonthsAndDayFromDateAmount(obj: DateAmount[]) {
    return obj.reduce(
        (acc, i) => {
            const formattedDate = format(
                new Date(i.paymentDate),
                'MMM dd',
            ) as string;
            // eslint-disable-next-line
            //@ts-ignore
            acc.data[formattedDate] = i.amount;
            // eslint-disable-next-line
            //@ts-ignore
            acc.keys.push(formattedDate);
            return acc;
        },
        { data: {}, keys: [] },
    );
}
export const showLandPartnersName = (landowners: string[] | string) => {
    if (typeof landowners !== 'string' && landowners?.length) {
        return (
            <BasedTooltip text={landowners.join(' ,')}>
                <Box display={'flex'} gap={1}>
                    <Typography
                        sx={{ textAlign: 'center' }}
                        variant={'font14'}
                        fontWeight={'medium'}>
                        {landowners[0]}
                    </Typography>
                    <Typography
                        sx={{ textAlign: 'center' }}
                        variant={'font14'}
                        fontWeight={'medium'}>
                        {landowners.length > 1 && `+${landowners.length - 1}`}
                    </Typography>
                    {landowners.length > 1 && (
                        <InfoTooltip
                            text={landowners.join(' ,')}
                            position={'top'}
                        />
                    )}
                </Box>
            </BasedTooltip>
        );
    }
    return (
        <Typography
            sx={{ textAlign: 'center' }}
            variant={'font14'}
            fontWeight={'medium'}>
            {landowners}
        </Typography>
    );
};

export function convertPaymentDateToOptions(
    data?: PaymentScheduledData[],
): Option[] {
    if (!data) return [];
    return data?.map((item) => ({
        id: item.id,
        name: item.paymentDate,
    }));
}
export function convertLeaseToOptions(data?: Lease[]): Option[] {
    if (!data) return [];
    return data?.map((item) => ({
        id: item.id,
        name: item.leaseName,
    }));
}
export const tableScheduleSummaryConfig = (
    // eslint-disable-next-line
    headerConf: any,
): Table<TransformedPaymentData> => {
    return {
        columns: [
            {
                header: {
                    label: 'Year',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {tableData.year}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Field Name',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {tableData.landName}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'FSA Number',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {tableData.fsaId}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Land Partners',
                },
                cellRender: (tableData) => {
                    return (
                        <Box
                            width={'100%'}
                            display={'flex'}
                            justifyContent={'center'}>
                            {showLandPartnersName(tableData?.landowners)}
                        </Box>
                    );
                },
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            // eslint-disable-next-line max-lines
            {
                header: {
                    label: 'Total Acres',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {tableData.totalAcres}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Tillable Acres',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {tableData.tillableAcres}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Rent Per Acre',
                },
                // eslint-disable-next-line max-lines
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {formatCurrency(tableData.rentPerAcreRate) === '$0.00'
                            ? null
                            : formatCurrency(tableData.rentPerAcreRate)}
                    </Typography>
                ),

                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            //eslint-disable-next-line
            ...headerConf.map((columns: any) => {
                return {
                    header: {
                        label: columns,
                    },
                    cellRender: (tableData: PaymentSchedule) => (
                        <Typography variant={'font14'} fontWeight={'medium'}>
                            {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                formatCurrency(tableData[columns])
                            }
                        </Typography>
                    ),
                    format: {
                        align: 'left',
                        color: { color: 'primary' },
                    },
                    width: 'auto',
                };
            }),
            {
                header: {
                    label: 'Annual Rent',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {formatCurrency(tableData.totalAnnualRent)}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Total Payment',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {formatCurrency(tableData.totalAnnualRent)}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        multiselect: false,
        pageable: false,
        expandable: true,
        ExpandComponent: ExpandedPaymentComponent,
    };
};

export function convertResponseToModel(data: PaymentSchedule[]) {
    const uniqueKeys: string[] = [];
    const uniqueLandownerNames: string[] = [];
    const uniqueFsaIds: string[] = [];
    const splitPaymentData: {
        [fsaId: string]: { [paymentDate: string]: DistributionDateAmounts[] };
    } = {};
    const paymentData = data.flatMap((item) =>
        item.fsaIdPaymentDetails.map((fsaIdPaymentDetail, index) => {
            const { data, keys } = extractMonthsAndDayFromDateAmount(
                fsaIdPaymentDetail.dateAmounts,
            );
            const { data: yearlyData } = extractMonthsAndDayFromDateAmount(
                item.yearlyTotal,
            );

            keys.forEach((key) => {
                if (!uniqueKeys.includes(key)) {
                    uniqueKeys.push(key);
                }
            });

            if (!uniqueFsaIds.includes(fsaIdPaymentDetail.fsaId.fsaId)) {
                uniqueFsaIds.push(fsaIdPaymentDetail.fsaId.fsaId);
            }

            fsaIdPaymentDetail?.paymentDistributionDateAmounts?.forEach(
                (paymentDetail) => {
                    const { paymentDate, ...rest } = paymentDetail;

                    if (!splitPaymentData[fsaIdPaymentDetail.fsaId.fsaId]) {
                        splitPaymentData[fsaIdPaymentDetail.fsaId.fsaId] = {};
                    }
                    if (
                        !splitPaymentData[fsaIdPaymentDetail.fsaId.fsaId][
                            paymentDate
                        ]
                    ) {
                        splitPaymentData[fsaIdPaymentDetail.fsaId.fsaId][
                            paymentDate
                        ] = [{ paymentDate, ...rest }];
                    } else {
                        splitPaymentData[fsaIdPaymentDetail.fsaId.fsaId][
                            paymentDate
                        ].push({ paymentDate, ...rest });
                    }
                    if (
                        !uniqueLandownerNames.includes(
                            paymentDetail.landownerName,
                        )
                    ) {
                        uniqueLandownerNames.push(paymentDetail.landownerName);
                    }
                },
            );

            const commonProperties = {
                id: fsaIdPaymentDetail.fsaId.id,
                leaseId: fsaIdPaymentDetail.fsaId.leaseId,
                fsaId: fsaIdPaymentDetail.fsaId.fsaId,
                tfpNumber: fsaIdPaymentDetail.fsaId.tfpNumber,
                landName: fsaIdPaymentDetail.fsaId.landName,
                totalAcres: fsaIdPaymentDetail.fsaId.totalAcres,
                tillableAcres: fsaIdPaymentDetail.fsaId.tillableAcres,
                rentPerAcreRate: fsaIdPaymentDetail.fsaId.rentPerAcreRate,
                totalAnnualRent: fsaIdPaymentDetail.fsaId.totalAnnualRent,
                description: fsaIdPaymentDetail.fsaId.description,
                ...data,
                landowners: index === 0 ? uniqueLandownerNames : '---',
                year: index === 0 ? item.year : '',
                splitPaymentData,
            };

            if (
                item.fsaIdPaymentDetails.length > 0 &&
                index === item.fsaIdPaymentDetails.length - 1
            ) {
                return [
                    commonProperties,
                    {
                        year: 'Yearly Total',

                        ...yearlyData,

                        totalAnnualRent: item.totalPayment,
                    },
                ];
            }

            return commonProperties;
        }),
    );
    const flattenedPaymentData = paymentData.flat();

    return { paymentData: flattenedPaymentData, keys: uniqueKeys };
}
