import React, { ComponentType, ReactNode } from 'react';
import {
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material';

import palette from '../../styles/palette';
import { PaletteOptions } from '@mui/material/styles/createPalette';

import { TableHeaderCell } from './GenericTableHeaderCell';
import GenericTableHeader from './GenericTableHeader';
import GenericTableRow from './GenericTableRow';
import TablePagination from './TablePagination';
import usePagination from './usePagination';
import TableSkeleton from '../Skeleton/TableSkeleton';

import GoneFishing from '../../assets/images/goneFishing.png';
import EmptyList from '../../reusable/EmptyList';

type OrderDirection = 'asc' | 'desc';
interface ColumnFormat {
    align: 'left' | 'right' | 'center';
    color: TableColor;
}
export interface Sorting {
    direction: OrderDirection;
    isSorted?: boolean;
    id: string;
}
export interface TableColumn<T> {
    header?: TableHeaderCell;
    cellRender: (data: T) => ReactNode;
    format?: ColumnFormat;
    width?: string | number;
    isHidden?: boolean;
    maxColumnWidth?: string | number;
}
interface TableColor {
    color: keyof typeof palette | PaletteOptions;
}
export interface Table<T> {
    columns: TableColumn<T>[];
    expandable?: boolean;
    pageable?: boolean;
    handlePageChange?: (item: number) => void;
    handleItemsPerPageChange?: (item: number) => void;
    ExpandComponent?: ComponentType<{ data: T }>;
    onClick?: (data: T) => void;
    filter?: object;
    multiselect?: boolean;
    isSelected?: (item: T) => boolean;
    toggleSelection?: (item: T) => void;
    toggleSelectionAll?: (items: T[]) => void;
    isSelectedAll?: (items: T[]) => boolean;
    initialPageSize?: number;
    initialPage?: number;
}
interface TableProps<T> {
    data: T[];
    isLoading?: boolean;
    totalCount?: number;
    tableConfig: Table<T>;
}
export default function GenericTable<T>({
    data = [],
    totalCount = 0,
    isLoading = false,
    tableConfig,
}: TableProps<T>) {
    const {
        columns,
        pageable = true,
        handlePageChange,
        handleItemsPerPageChange,
        ExpandComponent,
        multiselect,
        expandable,
        isSelected,
        toggleSelection,
        toggleSelectionAll,
        isSelectedAll,
        initialPageSize,
        initialPage,
        onClick,
    } = tableConfig;

    const headerConfig = columns
        .map((column) => column.header)
        .filter((header) => header) as Required<TableHeaderCell[]>;

    const { currentPage, pageSize, handlePageSizeChange, visibleData } =
        usePagination(data, initialPage, initialPageSize);

    const pageData = handleItemsPerPageChange || !pageable ? data : visibleData;

    if (isLoading) {
        return <TableSkeleton />;
    }
    return (
        <TableContainer>
            <MuiTable>
                <GenericTableHeader
                    header={headerConfig}
                    multiselect={multiselect}
                    selected={isSelectedAll && isSelectedAll(pageData)}
                    onSelect={
                        toggleSelectionAll &&
                        (() => toggleSelectionAll(pageData))
                    }
                    expandable={expandable}
                />
                <TableBody>
                    {!pageData.length && (
                        <TableRow>
                            <TableCell colSpan={columns?.length}>
                                <EmptyList
                                    image={GoneFishing}
                                    title={'No Data Available'}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {pageData?.map((row, rowIndex) => (
                        <GenericTableRow
                            key={rowIndex}
                            data={row}
                            columns={columns}
                            expandable={expandable}
                            ExpandComponent={ExpandComponent}
                            multiselect={multiselect}
                            selected={isSelected && isSelected(row)}
                            onSelect={
                                toggleSelection && (() => toggleSelection(row))
                            }
                            onClick={onClick}
                        />
                    ))}
                </TableBody>
            </MuiTable>
            {pageable && !!data.length && (
                <TablePagination
                    handleMovePage={handlePageSizeChange}
                    handlePageChange={handlePageChange}
                    handleItemsPerPageChange={handleItemsPerPageChange}
                    pageSize={pageSize}
                    ordersCount={totalCount}
                    page={currentPage}
                />
            )}
        </TableContainer>
    );
}
