import React, { useState } from 'react';

import { Box, Button, Typography } from '@mui/material';

import { TableColumn } from '../../design/GenericTable/GenericTable';
import { formatCurrency } from '../../utils/fieldMasks';
import theme from '../../styles/theme';
import { ReactComponent as InfoIcon } from '../../assets/icons/info_icon.svg';
import { useParams } from 'react-router-dom';
import { useGetOneFieldQuery } from '../../api/leases/leaseAPI';
import NotesButton from '../../pages/AllProfiles/NotesButton';
import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { useSorting } from '../../design/GenericTable/useSorting';

export interface FsaId {
    id: string;
    leaseId: string;
    fsaId: string;
    tfpNumber: string[];
    landName: string;
    totalAcres: number;
    tillableAcres: number;
    rentPerAcreRate: number;
    totalAnnualRent: number;
    description: string;
    mapEmbedCode?: string;
    notesCount?: number;
    notesAttachmentsCount?: number;
}

interface UseFieldTableReturn {
    data: FsaId[];
    totalCount: number | undefined;
    tableConfig: {
        columns: TableColumn<FsaId>[];
        pageable: boolean;
        multiselect: boolean;
    };
    handleClose: () => void;
    landData: FsaId | undefined;
    isInfoModalShowing: boolean;
    isNotesModalShowing: boolean;
    isLoading: boolean;
}

export default function useFieldTable(): UseFieldTableReturn {
    const [isInfoModalShowing, setIsInfoModalShowing] = useState(false);
    const [isNotesModalShowing, setIsNotesModalShowing] = useState(false);
    const [landData, setLandData] = useState<FsaId>();

    const { id } = useParams();
    const { sortKey, sortOrder, handleSort } = useSorting('landName', 'desc');
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(1, 25);

    const { data, isLoading } = useGetOneFieldQuery({
        id,
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });

    function handleInfoClick(land: FsaId) {
        setIsNotesModalShowing(false);
        setIsInfoModalShowing(true);
        setLandData(land);
    }

    function handleNotesClick(land: FsaId) {
        setIsInfoModalShowing(false);
        setIsNotesModalShowing(true);
        setLandData(land);
    }

    function handleClose() {
        setLandData(undefined);
        setIsInfoModalShowing(false);
        setIsNotesModalShowing(false);
    }

    const columns: TableColumn<FsaId>[] = [
        {
            header: {
                label: 'Field Name',
                sortable: true,
                sorting: {
                    id: 'landName',
                    direction: sortKey === 'landName' ? sortOrder : 'desc',
                    isSorted: sortKey === 'landName',
                },
                onClick: handleSort,
            },
            cellRender: (land) => (
                <Typography
                    variant={'font14'}
                    color={'text.link'}
                    fontWeight={'medium'}>
                    {land.landName}
                </Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'FSA ID',
                sortable: true,
                sorting: {
                    id: 'fsaId',
                    direction: sortKey === 'fsaId' ? sortOrder : 'desc',
                    isSorted: sortKey === 'fsaId',
                },
                onClick: handleSort,
            },
            cellRender: (land) => (
                <Typography
                    variant={'font14'}
                    color={'text.link'}
                    fontWeight={'medium'}>
                    {land.fsaId}
                </Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Tract/TFP Number(s)',
                sortable: true,
                sorting: {
                    id: 'tfpNumber',
                    direction: sortKey === 'tfpNumber' ? sortOrder : 'desc',
                    isSorted: sortKey === 'tfpNumber',
                },
                onClick: handleSort,
            },
            cellRender: (tableData) => (
                <>
                    {tableData.tfpNumber?.map((number, index) => (
                        <Box key={index}>
                            <Typography
                                variant={'font14'}
                                color={'text.link'}
                                fontWeight={'medium'}>
                                {number}
                            </Typography>
                        </Box>
                    ))}
                </>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Total Acres',
                sortable: true,
                sorting: {
                    id: 'totalAcres',
                    direction: sortKey === 'totalAcres' ? sortOrder : 'desc',
                    isSorted: sortKey === 'totalAcres',
                },
                onClick: handleSort,
            },
            cellRender: (land) => <Typography>{land.totalAcres}</Typography>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Tillable Acres',
                sortable: true,
                sorting: {
                    id: 'tillableAcres',
                    direction: sortKey === 'tillableAcres' ? sortOrder : 'desc',
                    isSorted: sortKey === 'tillableAcres',
                },
                onClick: handleSort,
            },
            cellRender: (land) => <Typography>{land.tillableAcres}</Typography>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Rent Per Acre',
                sortable: true,
                sorting: { id: 'rentPerAcreRate', direction: 'desc' },
            },
            cellRender: (land) => (
                <Typography>{formatCurrency(land.rentPerAcreRate)}</Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Total Annual Rent',
                sortable: true,
                sorting: {
                    id: 'totalAnnualRent',
                    direction:
                        sortKey === 'totalAnnualRent' ? sortOrder : 'desc',
                    isSorted: sortKey === 'totalAnnualRent',
                },
                onClick: handleSort,
            },
            cellRender: (land) => (
                <Typography>{formatCurrency(land.totalAnnualRent)}</Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },

        {
            header: {
                label: 'Notes',
                sortable: false,
            },
            cellRender: (land) => (
                <NotesButton
                    notesCount={land.notesCount}
                    attachmentCount={land.notesAttachmentsCount}
                    handleModalOpen={() => handleNotesClick(land as FsaId)}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },

        {
            header: {
                label: 'Info',
                sortable: false,
            },
            cellRender: (land) => (
                <Button
                    variant="outlined"
                    onClick={() => handleInfoClick(land as FsaId)}
                    sx={{
                        color: theme.palette.brandLightTurq,
                        fontWeight: 600,
                        fontSize: 12,
                    }}
                    endIcon={
                        <InfoIcon
                            style={{
                                fill: theme.palette.brandLightTurq,
                                width: 16,
                                height: 16,
                            }}
                        />
                    }>
                    Details
                </Button>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];

    const tableConfig = {
        columns,
        pageable: true,
        multiselect: false,
        handlePageChange: handlePageChange,
        handleItemsPerPageChange: handleItemsPerPageChange,
        initialPage: 1,
        initialPageSize: 25,
    };
    const totalCount = data?.totalCount || 0;
    const dataArr = data?.data || [];
    return {
        isLoading,
        data: dataArr,
        totalCount,
        tableConfig,
        handleClose,
        landData,
        isInfoModalShowing,
        isNotesModalShowing,
    };
}
