import { Box } from '@mui/material';
import AppTypography from '../../../design/AppTypography';
import IntegrationList from './IntegrationList';
import theme from '../../../styles/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import IntegrationStickySubmitBar from './IntegrationStickySubmitBar';
import { useEffect, useState } from 'react';
import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import { useQboPayMutation } from '../../../api/payment/paymentAPI';
import Paths from '../../../router/paths';
import {
    convertStateAndHandleSubmitDataToSubmitData,
    convertStateToHandleSubmitData,
    validIfPaymentExist,
    validIfPaymentUnique,
} from './utils';

export interface SubmitData {
    qboBillPaymentSchedulePairs: QboBillPaymentSchedulePair[];
}

export interface QboBillPaymentSchedulePair {
    qboBill: QboBill;
    paymentScheduleId: string;
}

export interface QboBill {
    billId: string;
    dueDate: string;
    createdAt: string;
    updatedAt: string;
    amount: number;
    balance: string;
    vendorName: string;
    vendorId: string;
}
export interface HandleSubmitData {
    billId: string;
    paymentScheduleId: string;
    balance: string;
}

export default function QuickBookDataImport() {
    const { state } = useLocation();
    const navigate = useNavigate();

    const [submitData, setSubmitData] = useState<HandleSubmitData[]>([]);
    const { handleSubmit } = useHandleSubmitRTK({
        useRtkHook: useQboPayMutation,
        successMessage: 'QuickBooks data imported successfully',
        onSuccess: () => navigate(`/${Paths.payments}`),
    });
    useEffect(() => {
        if (state?.selected) {
            setSubmitData(convertStateToHandleSubmitData(state.selected));
        }
    }, []);
    function handleCancel() {
        navigate(`/${Paths.payments}`);
    }
    function handleSubmitData(value: HandleSubmitData) {
        setSubmitData((prevState: HandleSubmitData[]) => {
            return prevState.map((item) => {
                if (item.billId === value.billId) {
                    return value;
                }
                return item;
            });
        });
    }

    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            <AppTypography
                variant={'font36'}
                fontWeight={'light'}
                color={'text.neutral7'}
                text={'QuickBooks Data Import'}
            />
            <AppTypography
                variant={'font16'}
                fontWeight={'light'}
                color={'text.neutral7'}
                text={'[additional instructions]'}
            />
            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}
                borderRadius={2}
                p={2}
                bgcolor={theme.palette.neutralWhite}>
                <IntegrationList
                    integration={state?.selected}
                    handleSubmitData={handleSubmitData}
                />
            </Box>
            <IntegrationStickySubmitBar
                handleCancel={handleCancel}
                isValid={
                    validIfPaymentExist(submitData) &&
                    validIfPaymentUnique(submitData)
                }
                handleSubmit={() =>
                    handleSubmit(
                        convertStateAndHandleSubmitDataToSubmitData(
                            state.selected,
                            submitData,
                        ),
                    )
                }
            />
        </Box>
    );
}
