import FormScreenWrapper from './FormScreenWrapper';
import { Box, Grid } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import FormPage from '../../reusable/FormUI/FormPage';
import { FormConfig } from '../../design/Forms/interfaces';
import { composeValidators } from '../../utils/validation';
import {
    invalidFileType,
    tooLargeFile,
    tooManyFiles,
} from '../../utils/fileValidation';
import { MAX_FILE_SIZE } from '../../constants';
import useFormFileUpload, { FormConfigProps } from './useFormFileUpload';
import { Field, useForm, useFormState } from 'react-final-form';
import FileWithDownloadButton from '../../reusable/FileWithDownloadButton';
import ContactFormField from './ContactFormField';
import React from 'react';

export default function InsuranceFinancialPreparednessForm() {
    const { handleFileUpload } = useFormFileUpload();

    const { values } = useFormState();
    const form = useForm();

    const propertyCoverageFile =
        values?.insurancePreparedness?.propertyCoverageFile;
    const damageDocumentationPlanFile =
        values?.insurancePreparedness?.damageDocumentationPlanFile;
    return (
        <FormScreenWrapper title=" Insurance and Financial Preparedness">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'What types of insurance coverage do you have for property?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage
                    formConfig={propertyCoverageFormConfig({
                        onChange: handleFileUpload,
                        isHidden: !!propertyCoverageFile?.id,
                    })}
                />
                {propertyCoverageFile?.id && (
                    <FileWithDownloadButton
                        isRemovable={true}
                        onDelete={() => {
                            form.change(
                                'insurancePreparedness.propertyCoverageFile',
                                null,
                            );
                        }}
                        id={propertyCoverageFile.id}
                        name={propertyCoverageFile?.fileName}
                        size={propertyCoverageFile?.fileSize}
                        date={propertyCoverageFile?.createdAt}
                    />
                )}
                <Grid container spacing={2}>
                    <Grid item xs={6} direction={'column'} rowGap={1}>
                        <AppTypography
                            variant="font12"
                            fontWeight={'medium'}
                            text={'Is this your insurance agent?'}
                            color={'text.neutral9'}
                        />
                        <Field
                            name={'insurancePreparedness.insuranceAgent'}
                            render={({ input }) => (
                                <ContactFormField input={input} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} direction={'column'} rowGap={1}>
                        <AppTypography
                            variant="font12"
                            fontWeight={'medium'}
                            text={'Is this your banker?'}
                            color={'text.neutral9'}
                        />
                        <Field
                            name={'insurancePreparedness.banker'}
                            render={({ input }) => (
                                <ContactFormField input={input} />
                            )}
                        />
                    </Grid>
                </Grid>
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={'How will you document damages for insurance claims?'}
                    color={'text.neutral9'}
                />{' '}
                <FormPage
                    formConfig={damageDocPlanFormConfig({
                        onChange: handleFileUpload,
                        isHidden: !!damageDocumentationPlanFile?.id,
                    })}
                />
                {damageDocumentationPlanFile?.id && (
                    <FileWithDownloadButton
                        isRemovable={true}
                        onDelete={() => {
                            form.change(
                                'insurancePreparedness.damageDocumentationPlanFile',
                                null,
                            );
                        }}
                        id={damageDocumentationPlanFile.id}
                        name={damageDocumentationPlanFile?.fileName}
                        size={damageDocumentationPlanFile?.fileSize}
                        date={damageDocumentationPlanFile?.createdAt}
                    />
                )}
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'Where are insurance policies and financial records stored?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={financialRecordsConfig} />
            </Box>
        </FormScreenWrapper>
    );
}
function propertyCoverageFormConfig({
    onChange,
    isHidden,
}: FormConfigProps): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'twoThird',
                name: 'insurancePreparedness.propertyCoverage',
                type: 'textarea',
                label: '',
            },
            renderProps: {
                resize: 'none',
                isDisabled: false,
                minRows: 3,
            },
        },
        {
            formField: {
                scheme: 'third',
                name: 'insurancePreparedness.propertyCoverageFile',
                type: 'fileupload',
                validation: composeValidators(
                    tooLargeFile(MAX_FILE_SIZE),
                    tooManyFiles(1),
                    invalidFileType([
                        'image/png',
                        'image/jpg',
                        'image/jpeg',
                        'image/bmp',
                        'application/pdf',
                    ]),
                ),
                onChange: (e) =>
                    onChange({
                        document: e as File[],
                        type: 'ER_PROPERTY_INSURANCE',
                        fieldName: 'insurancePreparedness.propertyCoverageFile',
                    }),
                isHidden: isHidden,
            },
            renderProps: {
                filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
                placeholder: 'Drag a document here',
                helperText: 'Attach Document',
                multipleFiles: false,
                isDisabled: false,
            },
        },
    ];
}
function damageDocPlanFormConfig({
    onChange,
    isHidden,
}: FormConfigProps): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'twoThird',
                name: 'insurancePreparedness.damageDocumentationPlan',
                type: 'textarea',
                label: '',
            },
            renderProps: {
                resize: 'none',
                isDisabled: false,
                minRows: 3,
            },
        },
        {
            formField: {
                scheme: 'third',
                name: 'insurancePreparedness.damageDocumentationPlanFile',
                type: 'fileupload',
                validation: composeValidators(
                    tooLargeFile(MAX_FILE_SIZE),
                    tooManyFiles(1),
                    invalidFileType([
                        'image/png',
                        'image/jpg',
                        'image/jpeg',
                        'image/bmp',
                        'application/pdf',
                    ]),
                ),
                onChange: (e) =>
                    onChange({
                        document: e as File[],
                        type: 'ER_INSURANCE_CLAIMS',
                        fieldName:
                            'insurancePreparedness.damageDocumentationPlanFile',
                    }),
                isHidden: isHidden,
            },
            renderProps: {
                filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp', '.pdf'],
                placeholder: 'Drag a document here',
                helperText: 'Attach Document',
                multipleFiles: false,
                isDisabled: false,
            },
        },
    ];
}
const financialRecordsConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'evacuationSafety.financialRecordsLocation',
            type: 'textarea',
            label: '',
        },
        renderProps: {
            resize: 'none',
            isDisabled: false,
            minRows: 3,
        },
    },
];
