import React from 'react';
import PreviewFormWrapper from './PreviewFormWrapper';
import { EnvironmentalConcerns } from '../model';

import { Box } from '@mui/material';
import HazardousPreview from '../HazardousForm/HazardousPreview';

interface OwnProps {
    data: EnvironmentalConcerns;
}
export default function GuidelinePreview({ data }: OwnProps) {
    return (
        <PreviewFormWrapper label={'Environment'}>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}>
                {data?.guidelines?.map((guideline, index) => (
                    <HazardousPreview key={index} guideline={guideline} />
                ))}
            </Box>
        </PreviewFormWrapper>
    );
}
