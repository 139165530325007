import React from 'react';
import BaseModal from '../../../reusable/BaseModal';
import { Box } from '@mui/material';

import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import { useRequestEsignMutation } from '../../../api/documents/documentsAPI';

import { convertDateToString } from '../../../utils/dateFormat';
import { DocumentTable } from './useDocumentsTable';
import RequestSignatureForm from './RequestSignatureForm';
import IframeDocuSign from './IframeDocuSign';

interface OwnProps {
    document: DocumentTable;
    isShowing: boolean;
    handleClose: () => void;
}

export interface FormValues {
    contacts: {
        id: string;
        email: string;
    }[];
    note: string;
    completeBy: Date;
}
export default function RequestESignatureModal({
    handleClose,
    isShowing,
    document,
}: OwnProps) {
    const {
        handleSubmit: handleRequest,
        data,
        isLoading: isRequesting,
    } = useHandleSubmitRTK({
        useRtkHook: useRequestEsignMutation,
        successMessage: 'Electronic Signature requested successfully',
    });

    function handleSubmit(values: FormValues) {
        handleRequest({
            documentId: document.id,
            contacts: values.contacts.map((contact) => {
                return {
                    id: contact.id,
                    primaryEmail: contact.email,
                };
            }),
            note: values.note,
            completeBy:
                values.completeBy && convertDateToString(values.completeBy),
        });
    }

    return (
        <BaseModal
            height={data?.docusignSenderViewUrl && 'large'}
            size={data?.docusignSenderViewUrl ? 'large' : 'small'}
            isShowing={isShowing}
            onClose={handleClose}
            header={`Request an Electronic Signature for "${document.fileName}"`}>
            <Box
                height="100%"
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}>
                <RequestSignatureForm
                    isHidden={!!data?.docusignSenderViewUrl}
                    handleSubmit={handleSubmit}
                    isRequesting={isRequesting}
                />
                <IframeDocuSign
                    isHidden={!data?.docusignSenderViewUrl}
                    docuSignUrl={data?.docusignSenderViewUrl}
                />
            </Box>
        </BaseModal>
    );
}
