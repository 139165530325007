import { useEffect, useState } from 'react';
import { FieldProps } from 'react-final-form';
import { ContactData } from './model';
import { Box } from '@mui/material';
import SearchContactField from './SearchContactField/SearchContactField';
import { useParams } from 'react-router-dom';
import ContactFormCard from './ContactFormCard';

interface OwnProps {
    input: FieldProps<ContactData, never>;
}
export default function ContactFormField({ input }: OwnProps) {
    const { id } = useParams();
    const { value, onChange } = input;
    const [contactForm, setContactForm] = useState<ContactData | undefined>(
        value,
    );

    function handleSelectOption(option: ContactData) {
        setContactForm(option);
    }

    function handleRemove() {
        setContactForm(undefined);
    }

    useEffect(() => {
        contactForm && onChange(contactForm);
    }, [contactForm]);

    return (
        <Box>
            {!contactForm?.id && (
                <SearchContactField
                    onSelect={handleSelectOption}
                    organizationId={id}
                />
            )}

            {contactForm && (
                <ContactFormCard
                    data={contactForm}
                    handleRemoveContact={handleRemove}
                />
            )}
        </Box>
    );
}
