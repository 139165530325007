import { ContactData, ContactType } from '../model';
import { Box, Grid } from '@mui/material';
import AppTypography from '../../../design/AppTypography';
import TextWithTitle from '../../../reusable/TextWithTitle';
import { phoneMaskForString } from '../../../utils/fieldMasks';
import { Address } from '../../AllProfiles/AllProfiles';
import StatusTag from '../../../design/StatusTag/StatusTag';

interface OwnProps {
    contact: ContactData;
    contactTypes?: ContactType[];
}
export default function PreviewContactCard({
    contact,
    contactTypes,
}: OwnProps) {
    const contactAddress = formatAddressByType(contact?.addresses);
    if (!contact) {
        return <p>No contact chosen</p>;
    }
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            <div>
                <AppTypography
                    variant="font16"
                    fontWeight={'medium'}
                    color={'text.neutral10'}
                    text={`${contact?.firstName} ${contact?.lastName}`}
                />
            </div>

            <Grid container xs={12}>
                <Grid item container xs={4} direction={'column'} rowGap={3}>
                    <TextWithTitle
                        maxWidth={'90%'}
                        title={'Email'}
                        value={contact?.primaryEmail}
                        isHidden={!contact?.primaryEmail}
                    />
                </Grid>
                <Grid item xs={4} rowGap={2}>
                    <TextWithTitle
                        maxWidth={'90%'}
                        title={'Phone'}
                        value={contact?.contactNumbers
                            ?.map((phone) => phoneMaskForString(phone?.number))
                            .join('; ')}
                        isHidden={!contact?.contactNumbers?.length}
                    />
                    <TextWithTitle
                        maxWidth={'90%'}
                        title={'Address'}
                        value={contactAddress?.join('; ')}
                        isHidden={!contact?.addresses?.length}
                    />
                </Grid>
                <Grid container item wrap={'wrap'} xs={4} gap={1}>
                    {contactTypes?.map((contactType) => (
                        <StatusTag
                            size={'small'}
                            key={contactType}
                            status={'success'}
                            text={contactTypeMapper[contactType as ContactType]}
                        />
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
}

const formatAddressByType = (addresses: Address[]) => {
    return addresses?.map((address) => {
        return [
            address?.address1,
            address?.address2,
            address?.city,
            address?.state,
            address?.zipCode,
        ]
            .filter(Boolean)
            .join(', ');
    });
};

const contactTypeMapper: Record<ContactType, string> = {
    FAMILY: 'Family',
    OWNER: 'Owner',
    POA: 'POA',
    ATTORNEY: 'Attorney',
    DISASTER_CONTACT: 'Disaster Contact',
    INSURANCE_AGENT: 'Insurance Agent',
    POLICE: 'Police',
    FIRE: 'Fire',
    MEDICAL: 'Medical',
    AGRONOMIST: 'Agronomist',
    EQUIPMENT_TECH: 'Equipment Tech',
    GOVERNMENT_AGENCY: 'Government Agency (EPA, FDA, FSA)',
    KEY_CUSTODIAN: 'Key Custodian',
    PASSWORD_MANAGER: 'Password Manager',
};
