import React from 'react';

import { makeStyles } from 'tss-react/mui';
import Chat from '../../../reusable/Chat/Chat';
import {
    FieldNoteResponse,
    useAddFieldNotesMutation,
    useGetFieldNotesQuery,
} from '../../../api/leases/fieldNotesAPI';
import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import { CommunicationContainer } from '../../../reusable/NotesField/NotesField';
import { NoteDetailsProps } from '../../../reusable/Chat/NoteDetails';
import { dateTimeConverterFromUTC } from '../../../utils/dateFormat';

interface OwnProps {
    id: string;
}

export default function AddFieldNotes({ id }: OwnProps) {
    const { classes } = useStyles();

    const { data: fieldNotes, isLoading: isLoadingNotes } =
        useGetFieldNotesQuery({
            fieldId: id,
            params: {
                page: 0,
                size: 2000,
            },
        });
    const { handleSubmit, isLoading: isAddFieldNoteLoading } =
        useHandleSubmitRTK({
            useRtkHook: useAddFieldNotesMutation,
            successMessage: 'Note added successfully',
        });

    function addFieldNote(
        note: string,
        file: File | null,
        communicationContainer: CommunicationContainer,
    ) {
        handleSubmit({
            addFieldNoteRequest: {
                fieldId: id,
                communicationType: communicationContainer?.communicationType,
                content: note,
                actualContactDate: communicationContainer?.actualContactDate,
            },
            attachment: file,
        });
    }

    return (
        <div className={classes.root}>
            <Chat
                type={'FIELD'}
                cards={
                    (fieldNotes &&
                        convertFieldNoteResponseToNote(fieldNotes)) ||
                    []
                }
                addNote={addFieldNote}
                isLoadingNotes={isLoadingNotes}
                isAddingNote={isAddFieldNoteLoading}
                autoFocus={true}
            />
        </div>
    );
}
const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: theme.spacing(2),
        gap: 8,
        borderRadius: 12,
        backgroundColor: theme.palette.neutral.neutral1,
    },
}));

function convertFieldNoteResponseToNote(
    response: FieldNoteResponse[],
): NoteDetailsProps[] {
    return response?.map((note) => ({
        id: note.id,
        communicationType: note?.communicationType,
        content: note.content,
        name: `${note.createdByUser.firstName} ${note.createdByUser.lastName}`,
        actualContactDate: note?.actualContactDate,
        createdAt: dateTimeConverterFromUTC(note.createdAt),
        updatedAt: dateTimeConverterFromUTC(note.updatedAt),
        pinned: note?.pinned,
        attachment: note?.attachments?.length
            ? {
                  id: note.attachments[0]?.id,
                  date: dateTimeConverterFromUTC(note.attachments[0].createdAt),
                  name: note.attachments[0]?.fileName,
                  size: note.attachments[0]?.fileSize,
                  link: note.attachments[0]?.fileLocation,
              }
            : undefined,
    }));
}
