import {
    useCreateLeaseDraftMutation,
    useCreateLeaseMutation,
    useUpdateLeaseDraftMutation,
    useUpdateLeaseMutation,
} from '../../api/leases/leaseAPI';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import { Lease } from '../../pages/Leases/TableConfig';
import { format } from 'date-fns';
import {
    convertDateToString,
    DATE_FORMAT,
    dateConverterFromUTC,
} from '../../utils/dateFormat';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PATHS from '../../router/paths';
import { FSA } from './LeaseDetailForm';
import { Expenses } from './ExpensesDetailForm';
import { createArrayOfNumbers } from '../../utils/dataConvertor';
export const useLeaseMutation = (fsaData: FSA[], expensesData?: Expenses[]) => {
    const { state } = useLocation();
    const { id } = useParams();
    const isEdit = !!id;

    const navigate = useNavigate();
    const uploadFsaDataWithoutTableId = fsaData.map((item) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { tableId, ...rest } = item;
        return rest;
    });

    const submitValues = ({ leaseFile, supportingFile, ...values }: Lease) => {
        return {
            leaseFile,
            supportingFile,
            lease: {
                ...values,
                grower: isEdit ? { id: values.grower } : values.grower,
                paymentSchedule:
                    isEdit || values?.isCopy
                        ? createArrayOfNumbers(
                              +values?.numberOfPaymentsPerYear,
                          ).map((i) => {
                              return dateConverterFromUTC(
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  values?.[`paymentSchedule${i - 1}`],
                              );
                          })
                        : values?.paymentSchedule &&
                          Object.values(values?.paymentSchedule).map((date) =>
                              format(new Date(date), DATE_FORMAT),
                          ),
                paymentDistributions: {
                    landowners:
                        values?.paymentDistributions?.landowners &&
                        Object.keys(
                            values?.paymentDistributions.landowners,
                        ).map((landowner) => ({
                            landownerId: landowner,
                            paymentPercent:
                                values?.paymentDistributions?.landowners?.[
                                    landowner as unknown as number
                                ],
                        })),
                    paymentDates: values?.paymentDistributions?.paymentDates
                        ? Object.keys(
                              values?.paymentDistributions.paymentDates,
                          ).map((date, index) => {
                              return {
                                  paymentDate:
                                      isEdit || values?.isCopy
                                          ? convertDateToString(
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                values?.[
                                                    `paymentSchedule${index}`
                                                ],
                                            )
                                          : convertDateToString(
                                                values?.paymentSchedule?.[
                                                    date as unknown as number
                                                ] as unknown as Date,
                                            ),
                                  paymentPercent:
                                      values?.paymentDistributions
                                          ?.paymentDates[
                                          date as unknown as number
                                      ],
                              };
                          })
                        : [],
                },
                fsaIds: uploadFsaDataWithoutTableId,
                expenses: expensesData,
                endDate:
                    values.endDate &&
                    format(new Date(values.endDate), DATE_FORMAT),
                startDate:
                    values.startDate &&
                    format(new Date(values.startDate), DATE_FORMAT),
                bonusSchedule:
                    values.bonusSchedule &&
                    format(new Date(values.bonusSchedule), DATE_FORMAT),
            },
        };
    };

    const { handleSubmit: handleSubmitDraft, data: dataCreate } =
        useHandleSubmitRTK({
            useRtkHook: useCreateLeaseDraftMutation,
            onSuccess: () => {
                return (state.isCopy = true), (state.id = dataCreate.id);
            },
            successMessage: 'Draft saved successfully',
        });

    const { handleSubmit: handleUpdateDraft } = useHandleSubmitRTK({
        useRtkHook: useUpdateLeaseDraftMutation,
        successMessage: 'Draft saved successfully',
    });

    const { handleSubmit: handleSubmitCreate, isLoading: isCreating } =
        useHandleSubmitRTK({
            useRtkHook: useCreateLeaseMutation,
            onSuccess: () => navigate(`/${PATHS.landPortfolio}`),
            successMessage: 'Lease added successfully',
        });

    const { handleSubmit: handleSubmitUpdate, isLoading: isUpdating } =
        useHandleSubmitRTK({
            useRtkHook: useUpdateLeaseMutation,
            onSuccess: () =>
                navigate(`/${PATHS.landPortfolio}/${PATHS.manage}/ ${id}`),
            successMessage: 'Lease updated successfully',
        });

    const handleSubmit = ({ leaseFile, supportingFile, ...values }: Lease) => {
        const submitData = submitValues({
            leaseFile,
            supportingFile,
            ...values,
        });
        if (isEdit) {
            handleSubmitUpdate(submitData);
            return;
        }
        handleSubmitCreate(submitData);
    };

    return {
        handleSubmitDraft,
        dataCreate,
        handleUpdateDraft,
        handleSubmitCreate,
        isCreating,
        handleSubmitUpdate,
        isUpdating,
        submitValues,
        handleSubmit,
    };
};
