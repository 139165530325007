import React, { useEffect } from 'react';
import IntegrationDataCard, { IntegrationData } from './IntegrationDataCard';
import AppTypography from '../../../design/AppTypography';
import { Box, Divider } from '@mui/material';
import SyncLabel from '../../../design/Labels/SyncLabel';
import AppFormCard from './AppFormCard';
import theme from '../../../styles/theme';

import { HandleSubmitData } from './QuickBookDataImport';
import { useIntegrationData } from './useIntegrationData';

export interface IntegrationProps {
    title?: string;
    data: IntegrationData;
    handleSubmitData: (value: HandleSubmitData) => void;
}

export default function IntegrationRow({
    title,
    data,
    handleSubmitData,
}: IntegrationProps) {
    const {
        landPartner,
        lease,
        balance,
        paymentDate,
        handleFormChange,
        handleChangeBalance,
        landPartnerOptions,
        leaseOptions,
        paymentDateOptions,
        isLandPartnersLoading,
        isLeaseLoading,
        isPaymentsLoading,
    } = useIntegrationData({ data });

    useEffect(() => {
        handleSubmitData({
            billId: data.billId,
            balance: balance,
            paymentScheduleId: paymentDate,
        });
    }, [paymentDate, balance]);

    return (
        <Box display="flex" flexDirection="column" rowGap={1} width="100%">
            {title && (
                <AppTypography
                    variant="font14"
                    fontWeight="medium"
                    color="text.neutral10"
                    text={title}
                />
            )}
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%">
                <IntegrationDataCard data={data} />
                <Divider
                    sx={{
                        borderColor: theme.palette.limeGreen.limeGreenShade2,
                        height: 2,
                        minWidth: 90,
                        width: '100%',
                        maxWidth: 190,
                    }}
                />
                <SyncLabel />
                <Divider
                    sx={{
                        borderColor: theme.palette.limeGreen.limeGreenShade2,
                        height: 2,
                        minWidth: 90,
                        width: '100%',
                        maxWidth: 190,
                    }}
                />
                <AppFormCard
                    landPartner={landPartner}
                    lease={lease}
                    paymentDate={paymentDate}
                    balance={balance}
                    onChange={{
                        landPartner: (value) =>
                            handleFormChange('landPartner', value),
                        lease: (value) => handleFormChange('lease', value),
                        paymentDate: (value) =>
                            handleFormChange('paymentDate', value),
                        balance: handleChangeBalance,
                    }}
                    options={{
                        landPartners: landPartnerOptions || [],
                        leases: leaseOptions || [],
                        paymentDates: paymentDateOptions || [],
                    }}
                    isLoading={{
                        landPartners: isLandPartnersLoading,
                        leases: isLeaseLoading,
                        paymentDates: isPaymentsLoading,
                    }}
                />
            </Box>
        </Box>
    );
}
