import PreviewFormWrapper from './PreviewFormWrapper';
import { ContactData } from '../model';
import PreviewContactCard from './PreviewContactCard';
import { Box } from '@mui/material';
import AppTypography from '../../../design/AppTypography';

interface OwnProps {
    usdaFarmContact: ContactData;
    agronomist: ContactData;
}
export default function ExternalAssistanceContactsPreview({
    usdaFarmContact,
    agronomist,
}: OwnProps) {
    return (
        <PreviewFormWrapper label={'Contacts: External Assistance'}>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={1}
                    width={'100%'}>
                    <div>
                        <AppTypography
                            variant="font16"
                            fontWeight={'medium'}
                            color={'text.neutral10'}
                            text={`USDA Farm Agency Contact:`}
                        />
                    </div>
                    <PreviewContactCard contact={usdaFarmContact} />
                </Box>

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={1}
                    width={'100%'}>
                    <div>
                        <AppTypography
                            variant="font16"
                            fontWeight={'medium'}
                            color={'text.neutral10'}
                            text={`Agronomist:`}
                        />
                    </div>
                    <PreviewContactCard contact={agronomist} />
                </Box>
            </Box>
        </PreviewFormWrapper>
    );
}
