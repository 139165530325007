import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, Divider } from '@mui/material';
import PrimaryContactMainInfo from './PrimaryContactMainInfo';
import { UserMain } from '../../../reusable/UserInfoPanel/UserMainInfo';

import { PhoneType } from '../../../reusable/FormUI/PhoneNumberField';
import PrimaryContactInfo from './PrimaryContactInfo';
import SecondaryContactInfo from './SecondaryContactInfo';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../../router/paths';
import DeactivateContactModal from './DeactivateContactModal';
import ActionButton, {
    ActionMenuItem,
} from '../../../design/Buttons/ActionButton';
import { EmailStatus } from '../../../pages/AllProfiles/AllProfilesTable';
import useManagePermission from '../../../hooks/useManagePermission';

interface OwnProps {
    userMain: UserMain;
    primaryContactData?: PrimaryContactData;
    datesOfContact?: RemindersData;
}

export interface PrimaryContactData {
    id: string;
    email?: string;
    phone?: PhoneType[];
    address?: string;
    emailStatus: EmailStatus;
}

export interface RemindersData {
    lastOutreach?: string;
    birthday?: string;
    anniversary?: string;
    customerSince?: string;
    dateOfDeath?: string;
}

export default function PrimaryContactInfoPanel({
    userMain,
    primaryContactData,
    datesOfContact,
}: OwnProps) {
    const { classes } = useStyles();
    const navigate = useNavigate();

    const [isDeactivateModalShowing, setIsDeactivateModalShowing] =
        useState(false);

    const { checkPermission } = useManagePermission();
    const actionButtonItems: ActionMenuItem[] = [
        {
            label: `Edit`,
            onClick: () =>
                navigate(
                    `/${PATHS.landpartners}/${PATHS.allProfiles}/${userMain?.id}/${PATHS.update}`,
                ),
            hidden: !checkPermission('contacts', 'modify'),
        },
        {
            label: 'Archive',
            onClick: () => setIsDeactivateModalShowing(true),
            hidden: !checkPermission('contacts', 'modify'),
        },
    ];
    return (
        <Box className={classes.containerWrapper}>
            <Box width={'100%'} display={'flex'}>
                <PrimaryContactMainInfo userMain={userMain} />
                <Divider orientation={'vertical'} flexItem variant="middle" />
                <Box width={'100%'} display={'flex'} flexDirection={'column'}>
                    <Box display={'flex'} columnGap={4} p={2} width={'100%'}>
                        <Box
                            flexDirection={'column'}
                            display={'flex'}
                            width={'40%'}
                            maxWidth={400}
                            columnGap={2}
                            rowGap={2}>
                            {primaryContactData && (
                                <PrimaryContactInfo
                                    primaryContactData={primaryContactData}
                                />
                            )}
                        </Box>
                        <Box
                            flexDirection={'column'}
                            display={'flex'}
                            columnGap={2}
                            rowGap={2}
                            maxWidth={400}
                            width={'40%'}>
                            {datesOfContact && (
                                <SecondaryContactInfo
                                    datesOfContact={datesOfContact}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <ActionButton label={'Actions'} items={actionButtonItems} />
            {isDeactivateModalShowing && (
                <DeactivateContactModal
                    isShowing={isDeactivateModalShowing}
                    contactId={userMain?.id}
                    onClose={() => setIsDeactivateModalShowing(false)}
                />
            )}
        </Box>
    );
}

const useStyles = makeStyles()((theme) => ({
    containerWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2, 3),
        backgroundColor: theme.palette.neutralWhite,
        borderRadius: 16,
    },
    expanded: {
        width: 24,
        height: 24,
        transform: 'rotate(180deg)',
    },
}));
