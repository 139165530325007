import React from 'react';
import BaseModal from '../../../reusable/BaseModal';
import { Box } from '@mui/material';
import { FormConfig } from '../../../design/Forms/interfaces';
import theme from '../../../styles/theme';
import FormPage from '../../../reusable/FormUI/FormPage';
import SubmitButton from '../../../design/Buttons/SubmitButton';
import { Field, Form } from 'react-final-form';

import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import {
    useDocumentDataByIdQuery,
    useShareDocumentMutation,
} from '../../../api/documents/documentsAPI';
import SharedContactArrayField from './SharedContactAraryField';
import { composeValidators, required } from '../../../utils/validation';
import HasAccessList from './HasAccessList';

interface OwnProps {
    id: string;
    fileName: string;
    isShowing: boolean;
    handleClose: () => void;
}
export default function SharingDocumentModal({
    handleClose,
    id,
    fileName,
    isShowing,
}: OwnProps) {
    const { handleSubmit: handleShare, isLoading } = useHandleSubmitRTK({
        useRtkHook: useShareDocumentMutation,
        onSuccess: handleClose,
        successMessage: 'Document shared successfully',
    });
    const { data, isLoading: isHasAccessLoading } = useDocumentDataByIdQuery({
        documentId: id,
    });

    function handleSubmit(values: { userAccesses: string[]; note: string }) {
        handleShare({
            documentId: id,
            userAccesses: values.userAccesses,
            note: values.note,
        });
    }
    return (
        <BaseModal
            size={'small'}
            isShowing={isShowing}
            onClose={handleClose}
            isLoading={isHasAccessLoading}
            header={`Share  "${fileName}"`}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
                height={'100%'}>
                <Form initialValues={{}} onSubmit={handleSubmit}>
                    {({ handleSubmit, valid }) => (
                        <form
                            style={{ height: '100%' }}
                            onSubmit={handleSubmit}>
                            <Box
                                height={'100%'}
                                width={'100%'}
                                display={'flex'}
                                flexDirection={'column'}
                                rowGap={2}>
                                <Box
                                    height={'100%'}
                                    width={'100%'}
                                    p={2}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    rowGap={2}
                                    bgcolor={
                                        theme.palette.backgroundB.backgroundB1
                                    }>
                                    <HasAccessList data={data} />
                                    <Field
                                        name="userAccesses"
                                        validate={(value) => {
                                            if (value?.length === 0) {
                                                return 'Please select at least one contact';
                                            }
                                        }}
                                        render={({ input, meta }) => (
                                            <SharedContactArrayField
                                                input={input}
                                                meta={meta}
                                                data={data}
                                            />
                                        )}
                                    />
                                    <FormPage formConfig={formConfig()} />
                                </Box>
                                <Box
                                    display={'flex'}
                                    justifyContent={'flex-end'}
                                    p={3}
                                    borderRadius={'0px 0px 12px 12px'}
                                    bgcolor={theme.palette.neutralWhite}>
                                    <SubmitButton
                                        isDisabled={!valid}
                                        isLoading={isLoading}
                                        text={'Send'}
                                    />
                                </Box>
                            </Box>
                        </form>
                    )}
                </Form>
            </Box>
        </BaseModal>
    );
}

function formConfig(): FormConfig[] {
    return [
        {
            formField: {
                scheme: 'full',
                name: 'note',
                type: 'textarea',
                label: 'Add a note',
                isRequired: true,
                validation: composeValidators(required),
            },
            renderProps: {
                isDisabled: false,
                resize: 'none',
                minRows: 3,
            },
        },
    ];
}
