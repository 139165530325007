import { useState } from 'react';
import { Box, Button, MobileStepper, Paper, useTheme } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import parse from 'html-react-parser';
import StubDummyComponent from '../../reusable/StubDummyComponent';
import AppTypography from '../../design/AppTypography';
import { FsaId } from './useFieldTable';

interface OwnProps {
    data: FsaId[];
}

export default function PreviewMapSlider({ data }: OwnProps) {
    const theme = useTheme();
    const embedMaps = data?.map((item) =>
        item.mapEmbedCode
            ? item
            : {
                  ...item,
                  mapEmbedCode: null,
              },
    );
    const [activeStep, setActiveStep] = useState(0);

    const maxMaps = embedMaps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: 500,
                flexGrow: 1,
            }}>
            <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    pl: 2,
                }}></Paper>
            <Box width={'100%'} height={500}>
                {embedMaps[activeStep]?.mapEmbedCode ? (
                    parse(
                        generateIframe(
                            embedMaps[activeStep].mapEmbedCode as string,
                        ),
                    )
                ) : (
                    <StubDummyComponent title={'No map available'} />
                )}
            </Box>
            <Box
                justifyContent={'center'}
                width={'100%'}
                display={'flex'}
                columnGap={2}>
                <AppTypography
                    color={'text.secondary'}
                    text={embedMaps[activeStep]?.landName}
                    variant={'font20'}
                    fontWeight={'medium'}
                />
            </Box>
            <MobileStepper
                sx={{
                    width: '100%',
                }}
                variant="text"
                steps={maxMaps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxMaps - 1}>
                        Next
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
        </Box>
    );
}

function generateIframe(link: string) {
    return `<iframe title="Acres Interactive Map"
            src="${link}"
            name="Acres Map Frame"
            scrolling="no"
            height="100%"
            width="100%"
            style="border: none;">
        </iframe>`;
}
