import React from 'react';
import {
    FormControl,
    FormControlLabel,
    Checkbox as MuiCheckbox,
    FormLabel,
    FormHelperText,
    Typography,
} from '@mui/material';
import clsx from 'clsx';

export interface CheckboxOption {
    id: string | boolean;
    name: string;
}

export interface Checkbox {
    options: CheckboxOption[];
    value: string[];
    onChange: (value: string[]) => void;
    //eslint-disable-next-line
    onBlur?: (e: any) => void;
    label?: string;
    errorText?: string;
    isDisabled?: boolean;
    name?: string;
    isRow?: boolean;
    size?: 'third' | 'half' | 'full' | 'auto';
}

export default function CheckboxRow({
    options,
    value,
    onChange,
    label,
    size = 'full',
    errorText,
    name,
    isDisabled = false,
}: Checkbox) {
    return (
        <FormControl
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
                width: '100%',
            }}
            component="fieldset"
            error={!!errorText}
            disabled={isDisabled}>
            {label && <FormLabel component="legend">{label}</FormLabel>}
            {options.map((option) => (
                <FormControlLabel
                    id={name}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        width: clsx(
                            size === 'full' && '100%',
                            size === 'half' && '50%',
                            size === 'third' && '33.33%',
                            size === 'auto' && 'auto',
                        ),
                    }}
                    key={option.name}
                    control={
                        <MuiCheckbox
                            checked={value?.includes(option.id as string)}
                            onChange={() => {
                                const updatedValue = value?.includes(
                                    option.id as string,
                                )
                                    ? value.filter((v) => v !== option.id)
                                    : [...value, option.id as string];
                                onChange(updatedValue);
                            }}
                        />
                    }
                    label={
                        <Typography
                            variant={'font14'}
                            fontWeight={'medium'}
                            color={'text.neutral6'}>
                            {option.name}
                        </Typography>
                    }
                />
            ))}
            {!!errorText && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
    );
}
