import ArchiveLeaseTable from './Leases/ArchiveLeaseTable';
import { Box } from '@mui/material';
import ArchiveContactTable from './Contacts/ArchiveContactTable';
import ArchiveLPTable from './LandPartners/ArchiveLPTable';
import ArchivedContactNotesTable from './ContactNotes/ArchivedContactNotesTable';
import ArchivedLeaseNotesTable from './LeaseNotes/ArchivedLeaseNotesTable';
import ArchivedFieldNotesTable from './FieldNotes/ArchivedFieldNotesTable';

export default function ArchiveTables() {
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={3}>
            <ArchiveLeaseTable />
            <ArchiveContactTable />
            <ArchiveLPTable />
            <ArchivedContactNotesTable />
            <ArchivedLeaseNotesTable />
            <ArchivedFieldNotesTable />
        </Box>
    );
}
