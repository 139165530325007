import React from 'react';
import { Box, Typography } from '@mui/material';
import PrimaryAdditionalInfo, {
    PrimaryAdditionalInfoProps,
} from './PrimaryAdditionalInfo';

interface OwnProps {
    primaryInfo: PrimaryAdditionalInfoProps;
    contactsCount: number;
}

export default function LandPartnerAdditionalSection({
    primaryInfo,
    contactsCount,
}: OwnProps) {
    return (
        <Box width={'100%'} display={'flex'} columnGap={4} flexWrap={'wrap'}>
            <PrimaryAdditionalInfo {...primaryInfo} />
            {contactsCount && (
                <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                    <Typography
                        variant={'font12'}
                        color={'text.neutral7'}
                        fontWeight={'medium'}>
                        NUMBER OF CONTACTS
                    </Typography>
                    <Typography
                        variant={'font40'}
                        color={'text.neutral7'}
                        fontWeight={'light'}>
                        {contactsCount}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}
