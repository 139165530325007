import React from 'react';
import PreviewFormWrapper from './PreviewFormWrapper';
import { ContinuityOperations } from '../model';

import { Box, Typography } from '@mui/material';

interface OwnProps {
    data: ContinuityOperations;
}
export default function ContinuityOperationsPreview({ data }: OwnProps) {
    return (
        <PreviewFormWrapper label={'Continuity of Operations'}>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}>
                <Typography variant={'font14'} fontWeight="bold">
                    In the event of primary market disruption
                </Typography>
                <Typography variant="font12" fontWeight={'regular'}>
                    {data?.alternativeMarketsPlan}
                </Typography>
            </Box>
        </PreviewFormWrapper>
    );
}
