import React from 'react';
import PreviewFormWrapper from './PreviewFormWrapper';
import { ResourceManagementFormValues } from '../model';

import { Box, Typography } from '@mui/material';

interface OwnProps {
    data: ResourceManagementFormValues;
}
export default function ResourceManagementPreview({ data }: OwnProps) {
    return (
        <PreviewFormWrapper label={'Resource Management'}>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}>
                <Typography variant={'font14'} fontWeight="bold">
                    Plan for additional resources
                </Typography>
                <Typography variant="font12" fontWeight={'regular'}>
                    {data?.additionalResourcesPlan}
                </Typography>
            </Box>
        </PreviewFormWrapper>
    );
}
