import React from 'react';
import { Grid, ThemeProvider, Typography } from '@mui/material';
import { tableTheme } from '../../styles/tableTheme';
import GenericTable, { Table } from '../../design/GenericTable/GenericTable';
import { PaymentSchedule } from './LeaseDetails';
import { formatCurrency } from '../../utils/fieldMasks';
import { extractMonthsAndDayFromDateAmount } from './utils';
import EmptyList from '../../reusable/EmptyList';
import GoneFishing from '../../assets/images/goneFishing.png';
interface OwnProps {
    paymentsData: PaymentSchedule[];
    isLoading: boolean;
}
export default function PaymentTable({ paymentsData, isLoading }: OwnProps) {
    if (!paymentsData?.length) {
        return <EmptyList title={'No Payment Schedule'} image={GoneFishing} />;
    }

    const { keys } = paymentsData?.length
        ? extractMonthsAndDayFromDateAmount(paymentsData[0].dateAmounts)
        : [];

    return (
        <Grid container>
            <ThemeProvider theme={tableTheme}>
                <GenericTable
                    isLoading={isLoading}
                    data={paymentsData}
                    totalCount={paymentsData?.length}
                    tableConfig={tableScheduleSummaryConfig(keys)}
                />
            </ThemeProvider>
        </Grid>
    );
}

export const tableScheduleSummaryConfig = (
    headerConf: any, //eslint-disable-line
): Table<PaymentSchedule> => {
    return {
        columns: [
            {
                header: {
                    label: 'Year', //eslint-disable-line
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {tableData?.year}
                    </Typography>
                ),
                // eslint-disable-next-line max-lines
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            ...headerConf.map((columns: string, index: number) => {
                return {
                    header: {
                        label: columns,
                    },
                    cellRender: (tableData: PaymentSchedule) => (
                        <Typography variant={'font14'} fontWeight={'medium'}>
                            {formatCurrency(
                                tableData?.dateAmounts[index]?.amount,
                            )}
                        </Typography>
                    ),
                    format: {
                        align: 'left',
                        color: { color: 'primary' },
                    },
                    width: 'auto',
                };
            }),
            {
                header: {
                    label: 'Total',
                },
                cellRender: (tableData) => (
                    <Typography variant={'font14'} fontWeight={'medium'}>
                        {formatCurrency(tableData?.total)}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        multiselect: false,
        pageable: false,
        expandable: false,
    };
};
