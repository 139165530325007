import React from 'react';
import InfoWithLabel from '../../../reusable/InfoWithLabel';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar_with_heart_icon.svg';
import { Box, Typography } from '@mui/material';

import { RemindersData } from './PrimaryContactInfoPanel';

interface OwnProps {
    datesOfContact: RemindersData;
}

export default function SecondaryContactInfo({ datesOfContact }: OwnProps) {
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={1}>
            <Typography variant={'font12'} color={'text.neutral7'}>
                DATES
            </Typography>

            <InfoWithLabel
                icon={CalendarIcon}
                text={`Date of Death ${datesOfContact?.dateOfDeath}`}
                isHidden={!datesOfContact?.dateOfDeath}
            />
            <InfoWithLabel
                icon={CalendarIcon}
                text={`Wedding Anniversary ${datesOfContact?.anniversary}`}
                isHidden={!datesOfContact?.anniversary}
            />
            <InfoWithLabel
                icon={CalendarIcon}
                text={`Birthday ${datesOfContact?.birthday}`}
                isHidden={!datesOfContact?.birthday}
            />
        </Box>
    );
}
