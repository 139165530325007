import React, { useContext, useState } from 'react';
import { TableColumn } from '../../../../design/GenericTable/GenericTable';

import { useSorting } from '../../../../design/GenericTable/useSorting';
import { usePagination } from '../../../../design/GenericTable/useBackendPagination';

import { Tooltip, Typography } from '@mui/material';
import ActionsColumn from '../ActionsColumn';
import { SelectedRowContext } from '../SelectedRowContext';
import useHandleSubmitRTK from '../../../../hooks/useHandleSubmit';
import { dateTimeConverterFromUTC } from '../../../../utils/dateFormat';
import { makeStyles } from 'tss-react/mui';
import { communicationTypeMapper } from '../../../../reusable/Chat/NoteDetails';
import {
    ArchivedFieldNoteResponse,
    useDeleteFieldNoteMutation,
    useGetArchivedFieldNotesQuery,
    useRestoreFieldNoteMutation,
} from '../../../../api/leases/fieldNotesAPI';

const useStyles = makeStyles()(() => ({
    truncatedText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 150,
        '&:hover': {
            overflow: 'visible',
            whiteSpace: 'normal',
        },
    },
}));

interface ArchivedFieldNoteData {
    id: string;
    content: string;
    archiveDate: string | number;
    archivedBy: string;
    communicationType?: string;
    actualContactDate?: string | number;
    createdAt: string | number;
}

export default function useTableArchivedFieldNotesConfig() {
    const { classes } = useStyles();
    const [isShowing, setIsShowing] = useState(false);
    const [deleteData, setDeleteData] = useState<ArchivedFieldNoteData | null>(
        null,
    );

    const { sortKey, sortOrder, handleSort } = useSorting('updated_at', 'desc');
    const { setSelectedRow } = useContext(SelectedRowContext);
    const handleRowClick = (rowData: ArchivedFieldNoteData) => {
        setSelectedRow(rowData);
    };
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(1, 100);

    const { handleSubmit, isLoading: isRestoreProcessing } = useHandleSubmitRTK(
        {
            onSuccess: () => setSelectedRow(null),
            useRtkHook: useRestoreFieldNoteMutation,
            successMessage: 'Field note restored successfully',
        },
    );
    const { data, isLoading } = useGetArchivedFieldNotesQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });
    const { handleSubmit: handleDelete, isLoading: isDeleteProcessing } =
        useHandleSubmitRTK({
            useRtkHook: useDeleteFieldNoteMutation,
            onSuccess: () => {
                setIsShowing(false);
                setSelectedRow(null);
            },
            successMessage: 'Field note deleted successfully',
        });

    function handleDeleteClick(data: ArchivedFieldNoteData) {
        setIsShowing(true);
        setDeleteData(data);
    }

    const columns: TableColumn<ArchivedFieldNoteData>[] = [
        {
            header: {
                label: 'Name',
                sortable: true,
                sorting: {
                    id: 'content',
                    direction: sortKey === 'content' ? sortOrder : 'desc',
                    isSorted: sortKey === 'content',
                },
                onClick: handleSort,
            },
            cellRender: (fieldNote) => (
                <Tooltip title={fieldNote.content} arrow>
                    <Typography
                        color={'text.link'}
                        className={classes.truncatedText}>
                        {fieldNote.content?.length > 10
                            ? `${fieldNote.content.substring(0, 10)}...`
                            : fieldNote.content}
                    </Typography>
                </Tooltip>
            ),
            maxColumnWidth: 200,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                label: 'Archived Date',
            },
            cellRender: (fieldNote) => <>{fieldNote?.archiveDate}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                label: 'Archived By',
            },
            cellRender: (fieldNote) => <>{fieldNote?.archivedBy}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: '20%',
        },
        {
            header: {
                label: 'Actions',
            },
            cellRender: (fieldNote) => (
                <ActionsColumn
                    id={fieldNote.id}
                    onDelete={() => handleDeleteClick(fieldNote)}
                    onRestore={() => handleSubmit(fieldNote.id)}
                    isProcessing={isRestoreProcessing || isDeleteProcessing}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: '40%',
        },
    ];

    const tableConfig = {
        columns,
        pageable: false,
        handlePageChange,
        onClick: handleRowClick,
        handleItemsPerPageChange,
        initialPageSize: 5,
        initialPage: 1,
    };
    const totalCount = data?.totalCount || 0;
    return {
        data: data?.data?.map(dataConvertorFromResponse) || [],
        totalCount,
        tableConfig,
        isLoading,
        isProcessing: isRestoreProcessing || isDeleteProcessing,
        deleteData,
        isShowing,
        setIsShowing,
        handleDelete,
    };
}

function dataConvertorFromResponse(
    data: ArchivedFieldNoteResponse,
): ArchivedFieldNoteData {
    return {
        id: data?.id,
        content: data?.content,
        archiveDate: dateTimeConverterFromUTC(data?.updatedAt),
        createdAt: dateTimeConverterFromUTC(data?.createdAt),
        archivedBy: data?.archivedBy?.id
            ? `${data?.archivedBy?.firstName} ${data?.archivedBy?.lastName}`
            : 'N/A',
        communicationType:
            data?.communicationType &&
            communicationTypeMapper[data.communicationType],
        actualContactDate:
            data?.actualContactDate &&
            dateTimeConverterFromUTC(data?.actualContactDate),
    };
}
