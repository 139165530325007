import PreviewFormWrapper from './PreviewFormWrapper';
import { CriticalResources } from '../model';
import { Box } from '@mui/material';
import CountListWithTitle from './CountLiistWithTiltle';
import AppTypography from '../../../design/AppTypography';
import React from 'react';

interface OwnProps {
    data: CriticalResources;
}
export default function CriticalResourcesAndProcessesPreview({
    data,
}: OwnProps) {
    return (
        <PreviewFormWrapper label={'Risk Assessment'}>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                width={'100%'}>
                <CountListWithTitle
                    title={'Critical Crops'}
                    listData={data?.crops}
                />

                <CountListWithTitle
                    title={'Essential Equipment'}
                    listData={data?.equipment}
                />
                <Box display={'flex'} flexDirection={'column'} rowGap={0.5}>
                    <AppTypography
                        variant={'font14'}
                        fontWeight="medium"
                        color={'text.neutral10'}
                        text={'Vital Document Storage'}
                    />
                    <AppTypography
                        variant={'font14'}
                        fontWeight="regular"
                        color={'text.neutral9'}
                        text={data?.vitalRecordsLocation}
                    />
                </Box>
            </Box>
        </PreviewFormWrapper>
    );
}
