import React from 'react';
import CopiedReadField from '../../design/CopiedReadField/CopeiedReadField';
import { makeStyles } from 'tss-react/mui';
import { useFormState } from 'react-final-form';

import PreviewFormWrapper from './PreviewFormWrapper';
import GenericTable from '../../design/GenericTable/GenericTable';
import { FSA } from './LeaseDetailForm';

import { Expenses } from './ExpensesDetailForm';
import { useTableFsaIdConfig } from './useTableFsaIdConfig';
import { convertUploadDataToField } from './utils';
import { tableExpensesConfig } from './tableExpensesConfig';
import _ from 'lodash';
import { Lease } from '../../pages/Leases/TableConfig';

interface OwnProps {
    setPageNumber: (page: number) => void;
    fsaTableData: FSA[];
    expensesTableData: Expenses[];
}
export default function PreviewCrop({
    setPageNumber,
    fsaTableData,
    expensesTableData,
}: OwnProps) {
    const { values: data } = useFormState();
    const handleModify = (page: number) => {
        setPageNumber(page);
    };
    const { classes } = useStyles();

    return (
        <div className={classes.container}>
            <PreviewFormWrapper onClick={handleModify} pageNumber={0}>
                {!!data &&
                    convertUploadDataToField(data as Lease).map(
                        (item, index) =>
                            item.value && (
                                <CopiedReadField key={index} {...item} />
                            ),
                    )}
            </PreviewFormWrapper>
            <PreviewFormWrapper onClick={handleModify} pageNumber={1}>
                <GenericTable
                    data={fsaTableData}
                    totalCount={fsaTableData?.length}
                    tableConfig={useTableFsaIdConfig(false, _, _, true)}
                />
            </PreviewFormWrapper>
            <PreviewFormWrapper onClick={handleModify} pageNumber={3}>
                <GenericTable
                    data={expensesTableData}
                    totalCount={expensesTableData?.length}
                    tableConfig={tableExpensesConfig({ isActionsHidden: true })}
                />
            </PreviewFormWrapper>
        </div>
    );
}

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        width: '100%',
        gap: theme.spacing(1),
        padding: theme.spacing(2, 3),
    },
}));
