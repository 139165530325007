import PreviewFormWrapper from './PreviewFormWrapper';
import { CropProtectionFormValues } from '../model';
import PreviewContactCard from './PreviewContactCard';
import { Box } from '@mui/material';

interface OwnProps {
    data: CropProtectionFormValues;
}
export default function CropProtectionContactsPreview({ data }: OwnProps) {
    return (
        <PreviewFormWrapper label={'Contacts: Crop Protection'}>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}>
                <PreviewContactCard contact={data?.insuranceAgent} />
            </Box>
        </PreviewFormWrapper>
    );
}
