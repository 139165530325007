import { Grid } from '@mui/material';
import CardWrapper from '../CardWrapper';
import SelectFieldWithLabel from './IntegrationFieldWithLabel';
import FieldLabelWithTooltip from '../../../design/Fields/FieldLabelWithTooltip';
import InputField from '../../../design/Fields/InputField';
import { Option } from '../../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';

interface AppFormCardProps {
    landPartner: string;
    lease: string;
    paymentDate: string;
    balance: string;
    onChange: {
        landPartner: (value: string) => void;
        lease: (value: string) => void;
        paymentDate: (value: string) => void;
        balance: (value: string) => void;
    };
    options: {
        landPartners: Option[];
        leases: Option[];
        paymentDates: Option[];
    };
    isLoading: {
        landPartners: boolean;
        leases: boolean;
        paymentDates: boolean;
    };
}

export default function AppFormCard({
    landPartner,
    lease,
    paymentDate,
    balance,
    onChange,
    options,
    isLoading,
}: AppFormCardProps) {
    return (
        <CardWrapper width={'35%'}>
            <Grid container xs={12} flexDirection={'column'}>
                <Grid container item spacing={1}>
                    <SelectFieldWithLabel
                        label="Land Partner"
                        value={landPartner}
                        options={options.landPartners}
                        onChange={onChange.landPartner}
                        isLoading={isLoading.landPartners}
                    />
                    <SelectFieldWithLabel
                        label="Lease"
                        value={lease}
                        options={options.leases}
                        onChange={onChange.lease}
                        isLoading={isLoading.leases}
                        isDisabled={!landPartner}
                    />
                </Grid>
                <Grid container item spacing={1}>
                    <SelectFieldWithLabel
                        label="Payment Date"
                        value={paymentDate}
                        options={options.paymentDates}
                        onChange={onChange.paymentDate}
                        isLoading={isLoading.paymentDates}
                        isDisabled={!lease}
                    />
                    <Grid item xs={6}>
                        <FieldLabelWithTooltip label="Schedule Payment" />
                        <InputField
                            value={balance}
                            onChange={onChange.balance}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </CardWrapper>
    );
}
