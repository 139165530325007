import CardWrapper from '../CardWrapper';

import React from 'react';
import { Box } from '@mui/material';
import TextWithTitle from '../TextWithTitle';
import { formatCurrency } from '../../../utils/fieldMasks';
import { dateConverterFromUTC } from '../../../utils/dateFormat';

export interface IntegrationData {
    billId: string;
    payee: string;
    date: string;
    value: string;
    notes?: string;
}
interface OwnProps {
    data: IntegrationData;
}
export default function IntegrationDataCard({ data }: OwnProps) {
    return (
        <CardWrapper width={'35%'}>
            <Box display={'flex'} width={'100%'} flexWrap={'wrap'} gap={1}>
                {convertDataRoFieldView(data).map(
                    ({ title, value, isHidden }, index) => (
                        <Box key={index} width={'30%'}>
                            <TextWithTitle
                                title={title}
                                value={value}
                                isHidden={isHidden}
                            />
                        </Box>
                    ),
                )}
            </Box>
        </CardWrapper>
    );
}

export function convertDataRoFieldView(data: IntegrationData) {
    return [
        {
            title: 'Payee',
            value: data?.payee,
            isHidden: !data?.payee,
        },
        {
            title: 'Date',
            value: dateConverterFromUTC(data?.date),
        },
        {
            title: 'Amount',
            value: formatCurrency(data?.value) || 'N/A',
        },
    ];
}
