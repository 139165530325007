import { OAKENApi } from '../OAKENApi';
import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { EMERGENCY } from '../tags';
import { EmergencyReadinessResponse } from '../../features/Succession/model';

export const emergencyReadinessAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getEmergencyReadinessStatus: builder.query({
            query: ({ id }) => {
                return {
                    url: `${process.env.REACT_APP_USER_API_HOST}${ENDPOINTS.emergencyReadiness.root}/${id}/status`,
                    method: HTTP_METHOD.GET,
                };
            },
        }),
        initiateEmergencyReadiness: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `${process.env.REACT_APP_USER_API_HOST}${ENDPOINTS.emergencyReadiness.root}/${id}/initiate`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [EMERGENCY.EMERGENCY],
        }),
        updateEmergencyReadiness: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `${process.env.REACT_APP_USER_API_HOST}${ENDPOINTS.emergencyReadiness.root}/${id}`,
                    method: HTTP_METHOD.PUT,
                    body,
                };
            },
        }),
        submitEmergencyReadiness: builder.mutation({
            query: (id) => {
                return {
                    url: `${process.env.REACT_APP_USER_API_HOST}${ENDPOINTS.emergencyReadiness.root}/${id}/submit`,
                    method: HTTP_METHOD.PUT,
                };
            },
            invalidatesTags: [EMERGENCY.EMERGENCY, EMERGENCY.STATUS],
        }),
        getEmergencyReadinessPlans: builder.query({
            query: (params) => {
                return {
                    url: `${process.env.REACT_APP_USER_API_HOST}${ENDPOINTS.emergencyReadiness.root}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: EmergencyReadinessResponse[],
                meta,
            ): { data: EmergencyReadinessResponse[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: [EMERGENCY.EMERGENCY],
        }),
        getOneEmergencyReadinessPlan: builder.query({
            query: (id) => {
                return {
                    url: `${process.env.REACT_APP_USER_API_HOST}${ENDPOINTS.emergencyReadiness.root}/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: (id) => [{ type: EMERGENCY.EMERGENCY, id }],
        }),
        searchOrganizationWithoutPlan: builder.query({
            query: (params) => {
                return {
                    url: `${process.env.REACT_APP_USER_API_HOST}${ENDPOINTS.emergencyReadiness.organizationsWithoutPlan}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
    }),
});

export const {
    useLazySearchOrganizationWithoutPlanQuery,
    useLazyGetOneEmergencyReadinessPlanQuery,
    useGetEmergencyReadinessPlansQuery,
    useSubmitEmergencyReadinessMutation,
    useLazyGetEmergencyReadinessStatusQuery,
    useInitiateEmergencyReadinessMutation,
    useUpdateEmergencyReadinessMutation,
} = emergencyReadinessAPI;
