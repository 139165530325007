import { Box, Drawer, List, ListItem } from '@mui/material';
import theme from '../../styles/theme';
import AppTypography from '../../design/AppTypography';
import CloseButton from '../../design/Buttons/CloseButton';

interface OwnProps {
    isDrawerOpen: boolean;
    handleDrawerClose: () => void;
}

export default function InfoDrawer({
    isDrawerOpen,
    handleDrawerClose,
}: OwnProps) {
    return (
        <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose}>
            <Box
                width={800}
                padding={2}
                display={'flex'}
                flexDirection={'column'}
                height={'100vh'}
                bgcolor={theme.palette.backgroundB.backgroundB4}>
                <Box display="flex" justifyContent={'flex-end'} width={'100%'}>
                    <CloseButton onClick={handleDrawerClose} />
                </Box>
                <Box
                    width={'100%'}
                    height={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    rowGap={2}>
                    <AppTypography
                        variant={'font16'}
                        fontWeight={'bold'}
                        color={'text.neutral9'}
                        text={'Let’s get started'}
                    />
                    <AppTypography
                        flexWrap={true}
                        variant={'font14'}
                        fontWeight={'regular'}
                        color={'text.neutral9'}
                        text={
                            'Creating a robust disaster response plan is essential for protecting your operations, ensure worker safety, and maintain business continuity.'
                        }
                    />
                    <AppTypography
                        flexWrap={true}
                        variant={'font14'}
                        fontWeight={'regular'}
                        color={'text.neutral9'}
                        text={
                            'Our step-by-step plan will guide you through responding to emergencies, outlining steps to protect crops, manage resources, and coordinate with external agencies. '
                        }
                    />
                    <AppTypography
                        flexWrap={true}
                        variant={'font14'}
                        fontWeight={'medium'}
                        color={'text.neutral9'}
                        text={
                            'Before starting, we recommend gathering the following information:'
                        }
                    />
                    <List
                        disablePadding={true}
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'regular',
                            color: 'text.neutral9',
                        }}>
                        <ListItem>
                            1. Conduct a risk assessment to identify potential
                            hazards specific to your farm
                        </ListItem>
                        <ListItem>
                            2. Create a detailed inventory of all assets,
                            including land, crops, equipment, and supplies
                        </ListItem>
                        <ListItem>
                            3. Map out your farms layout, highlighting
                            buildings, water sources, and safe zones
                        </ListItem>
                        <ListItem>
                            4. Compile a list of all employees, their roles, and
                            contact information
                        </ListItem>
                        <ListItem>
                            5. Research local emergency services and their
                            capabilities
                        </ListItem>
                        <ListItem>
                            6. Review your insurance policies to understand your
                            coverage
                        </ListItem>
                        <ListItem>
                            7. Identify critical operations that must continue
                            during an emergency
                        </ListItem>
                        <ListItem>
                            8. Familiarize yourself with government assistance
                            programs for farmers
                        </ListItem>
                        <ListItem>
                            9. Reflect on past emergencies and lessons learned
                        </ListItem>
                        <ListItem>
                            10. Assess your current emergency preparedness,
                            including equipment, supplies, and training
                        </ListItem>
                    </List>
                    <AppTypography
                        flexWrap={true}
                        variant={'font14'}
                        fontWeight={'regular'}
                        color={'text.neutral9'}
                        text={
                            'Once you’ve completed this plan, you’ll have the opportunity to review and compile it into a consolidated PDF. We strongly recommend keeping printed copies in a binder at safe locations, ensuring you’re prepared even if there’s a power or internet outage.'
                        }
                    />
                </Box>
            </Box>
        </Drawer>
    );
}
