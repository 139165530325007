import PreviewFormWrapper from './PreviewFormWrapper';
import { ResourceManagementFormValues } from '../model';
import PreviewContactCard from './PreviewContactCard';
import { Box } from '@mui/material';

interface OwnProps {
    data: ResourceManagementFormValues;
}
export default function ResourceManagementContactsPreview({ data }: OwnProps) {
    return (
        <PreviewFormWrapper label={'Contacts: Resource Management'}>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}>
                <PreviewContactCard contact={data?.localStoreAgent} />
            </Box>
        </PreviewFormWrapper>
    );
}
