import React from 'react';
import { Box } from '@mui/material';
import UserMainInfo, {
    UserMain,
} from '../../../reusable/UserInfoPanel/UserMainInfo';

interface PrimaryContactMain {
    userMain: UserMain;
}
export type typeOfAdvocacy =
    | 'BLOCKER'
    | 'CHAMPION'
    | 'INFLUENCER'
    | 'KEY_DECISION_MAKER'
    | 'DO_NOT_CONTACT';

export default function PrimaryContactMainInfo({
    userMain,
}: PrimaryContactMain) {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            width={380}>
            <UserMainInfo {...userMain} />
        </Box>
    );
}
