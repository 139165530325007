import React from 'react';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone_icon.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/communications_icon.svg';
import { ReactComponent as LocationIcon } from '../../../assets/icons/pin_location_icon.svg';
import { Box, Button, Paper } from '@mui/material';
import UserAvatar from '../../../design/Avatar/UserAvatar';
import InfoWithLabel from '../../../reusable/InfoWithLabel';
import LinkText from '../../../design/Fields/LinkText';
import { phoneTypeMapper } from '../../../features/CreateLandPartner/PreviewLandPartner';
import { phoneMaskForString } from '../../../utils/fieldMasks';
import { makeStyles } from 'tss-react/mui';
import theme from '../../../styles/theme';
import CheckboxRow from '../../../design/Fields/CheckboxRow';
import {
    emergencyResponseAndSafety,
    farmAndCropManagement,
    legalAndFinancial,
} from './utils';

import AppTypography from '../../../design/AppTypography';

import { Address } from '../../AllProfiles/AllProfiles';
import { ContactType, EmergencyContactData } from '../model';

interface OwnProps {
    data: EmergencyContactData['contact'];
    setContacts: (
        data:
            | EmergencyContactData[]
            | ((prev: EmergencyContactData[]) => EmergencyContactData[]),
    ) => void;
    handleContactTypeCheck: (event: ContactType[], id: string) => void;
    value: ContactType[];
}

export default function EmergencyContactCard({
    data,
    handleContactTypeCheck,
    value,
    setContacts,
}: OwnProps) {
    const { classes } = useStyles();

    function removeContact() {
        setContacts((prev: EmergencyContactData[]) =>
            prev.filter((contact) => contact.contact.id !== data.id),
        );
    }
    function getAddress(address: Address) {
        return (
            address &&
            [
                address.address1,
                address.address2,
                address.city,
                address.state,
                address.zipCode,
            ]
                .filter(Boolean)
                .join(', ')
        );
    }

    return (
        <Paper className={classes.container}>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} columnGap={1}>
                    <UserAvatar name={`${data?.firstName} ${data?.lastName}`} />
                    <Box display={'flex'} flexDirection={'column'}>
                        <AppTypography
                            variant={'font20'}
                            fontWeight="medium"
                            color="text.secondary"
                            text={`${data?.firstName} ${data?.lastName}`}
                        />
                    </Box>
                </Box>
                <Button variant={'outlined'} onClick={removeContact}>
                    Remove
                </Button>
            </Box>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}>
                <AppTypography
                    variant={'font12'}
                    fontWeight="medium"
                    color="text.neutral7"
                    text={'Select Contact Type'}
                />
                <CheckboxRow
                    size={'third'}
                    value={value || []}
                    name="legalAndFinancial"
                    options={legalAndFinancial}
                    onChange={(e) =>
                        handleContactTypeCheck(e as ContactType[], data.id)
                    }
                />
                <AppTypography
                    variant={'font12'}
                    fontWeight="medium"
                    color="text.neutral7"
                    text={'Emergency Response + Safety'}
                />
                <CheckboxRow
                    size={'auto'}
                    value={value || []}
                    name="emergencyResponseAndSafety"
                    options={emergencyResponseAndSafety}
                    onChange={(e) =>
                        handleContactTypeCheck(e as ContactType[], data.id)
                    }
                />
                <AppTypography
                    variant={'font12'}
                    fontWeight="medium"
                    color="text.neutral7"
                    text={'Farm + Crop Management'}
                />
                <CheckboxRow
                    size={'auto'}
                    value={value || []}
                    name="farmAndCropManagement"
                    options={farmAndCropManagement}
                    onChange={(e) =>
                        handleContactTypeCheck(e as ContactType[], data.id)
                    }
                />
            </Box>
            <InfoWithLabel
                icon={EmailIcon}
                text={
                    data?.primaryEmail ? (
                        <LinkText
                            to={`mailto:${data.primaryEmail}`}
                            text={data.primaryEmail}
                            fontWeight={'medium'}
                        />
                    ) : (
                        'N/A'
                    )
                }
            />
            {data?.contactNumbers?.map(
                (phone: {
                    type: React.Key | null | undefined;
                    number: string;
                }) => {
                    return (
                        <InfoWithLabel
                            key={phone.type}
                            icon={PhoneIcon}
                            text={`${phoneTypeMapper[phone?.type as string]}
                                        : ${phoneMaskForString(phone?.number)}`}
                        />
                    );
                },
            )}

            {data?.addresses
                ?.map(getAddress)
                .map((address) => (
                    <InfoWithLabel
                        key={address}
                        icon={LocationIcon}
                        text={address}
                    />
                ))}
        </Paper>
    );
}

const useStyles = makeStyles()(() => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 500,
        border: ` solid 1px ${theme.palette.neutral.neutral3}`,
        gap: theme.spacing(1),
        padding: theme.spacing(2),
        boxShadow: theme.shadows[1],
    },
}));
