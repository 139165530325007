import React, { useState } from 'react';

import { ReactComponent as SearchIcon } from '../../../assets/icons/search_icon.svg';

import { Box, MenuItem, OutlinedInput, Popover } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import theme from '../../../styles/theme';

import { useSearchOrganizationField } from './useSearchOrganizationField';
import { LandPartner } from '../../CreateLandPartner/LandPartnerWizard';

interface OwnProps {
    onSelect: (option: LandPartner) => void;
    setValue?: (value: string) => void;
    value?: string;
    isDisabled?: boolean;
}

export default function SearchOrganizationField({
    onSelect,
    setValue,
    value,
    isDisabled,
}: OwnProps) {
    const { classes } = useStyles();
    const [text, setText] = useState('');

    const {
        anchorEl,
        open,
        handlePopoverOpen,
        handlePopoverClose,
        debouncedSearch,
        fetchedData,
    } = useSearchOrganizationField();

    const handleSelectOption = (option: LandPartner) => {
        handlePopoverClose();
        setText('');
        onSelect(option);
    };

    const id = open ? 'contact-popover' : undefined;

    return (
        <>
            <Box width={'100%'}>
                <OutlinedInput
                    size={'small'}
                    disabled={isDisabled}
                    value={text}
                    aria-describedby={id}
                    className={classes.searchInput}
                    placeholder={'Search'}
                    endAdornment={<SearchIcon />}
                    onMouseDown={handlePopoverOpen}
                    onChange={(e) => {
                        setText(e.target.value);
                        debouncedSearch(e.target.value);
                        value && setValue && setValue(e.target.value);
                    }}
                />
            </Box>
            <Popover
                disableAutoFocus
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        style: {
                            marginTop: theme.spacing(1),
                            maxHeight: 300,
                            overflowY: 'auto',
                            width: anchorEl?.clientWidth || 'auto',
                        },
                    },
                }}>
                {fetchedData &&
                    fetchedData.map((option: LandPartner, index: number) => (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                handleSelectOption(option);
                                setValue && setValue(`${option?.name}`);
                            }}>
                            {`${option?.name}`}
                        </MenuItem>
                    ))}
            </Popover>
        </>
    );
}

const useStyles = makeStyles()(() => ({
    searchInput: {
        width: '100%',
        backgroundColor: theme.palette.neutralWhite,
        '& .MuiInputBase-input': {
            padding: theme.spacing(2),
        },
        paddingBottom: theme.spacing(0),
    },
}));
