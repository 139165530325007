import { useSorting } from '../../design/GenericTable/useSorting';
import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { TableColumn } from '../../design/GenericTable/GenericTable';
import { Box } from '@mui/material';
import LinkText from '../../design/Fields/LinkText';
import StatusTag, { Tag } from '../../design/StatusTag/StatusTag';

import { useGetEmergencyReadinessPlansQuery } from '../../api/emergencyReadiness/emergencyReadiness';
import {
    ActiveServices,
    EmergencyReadinessResponse,
    pathMapper,
} from './model';
import { dateTimeConverterFromUTC } from '../../utils/dateFormat';
import { useNavigate } from 'react-router-dom';
import Paths from '../../router/paths';
import EditButton from './EditButton';

export default function useCompletedTable() {
    const { sortKey, sortOrder, handleSort } = useSorting('updatedAt', 'desc');
    const navigate = useNavigate();

    function handleEditClick(id: string) {
        navigate(`/${Paths.emergencyReadiness}?organizationId=${id}`, {
            state: { stepNumber: 0 },
        });
    }

    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(1, 25);

    const { data: responseData, isLoading } =
        useGetEmergencyReadinessPlansQuery({
            status: 'COMPLETED',
            sort: `${sortKey},${sortOrder}`,
            page: currentPage - 1,
            size: itemsPerPage,
        });
    const columns: TableColumn<EmergencyReadinessResponse>[] = [
        {
            header: {
                label: 'Name',
                sortable: true,
                sorting: {
                    id: 'organization.name',
                    direction:
                        sortKey === 'organization.name' ? sortOrder : 'desc',
                    isSorted: sortKey === 'organization.name',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <Box display={'flex'} alignItems={'center'} columnGap={1}>
                    <LinkText
                        to={`/${Paths.landpartners}/${pathMapper[data.organization.type]}/${data.organization.id}`}
                        text={`${data.organization.name}`}
                        variant={'font14'}
                    />
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Last Updated',
                sortable: true,
                sorting: {
                    id: 'updatedAt',
                    direction: sortKey === 'updatedAt' ? sortOrder : 'desc',
                    isSorted: sortKey === 'updatedAt',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <>{dateTimeConverterFromUTC(data?.updatedAt)}</>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Active Status',
                sortable: false,
                sorting: {
                    id: 'activeServices',
                    direction:
                        sortKey === 'activeServices' ? sortOrder : 'desc',
                    isSorted: sortKey === 'activeServices',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <Box display="flex" columnGap={1}>
                    <StatusTag
                        {...activeServicesMap[data.status as ActiveServices]}
                    />
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: '',
                sortable: false,
                onClick: handleSort,
            },
            cellRender: (data) => (
                <Box display="flex" columnGap={1}>
                    <EditButton
                        onClick={() => handleEditClick(data.organization.id)}
                    />
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];
    const tableConfig = {
        columns,
        pageable: true,
        handlePageChange,
        handleItemsPerPageChange,
        initialPageSize: 25,
        initialPage: 1,
        expandable: false,
    };
    const totalCount = responseData?.totalCount || 0;
    const data = responseData?.data || [];
    return { data, totalCount, tableConfig, isLoading };
}
const activeServicesMap: Record<ActiveServices, Tag> = {
    COMPLETED: {
        status: 'success',
        text: 'Completed',
    },
    IN_PROGRESS: {
        status: 'warning',
        text: 'In Progress',
    },
};
