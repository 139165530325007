import React, { useEffect, useState } from 'react';
import { Box, Switch } from '@mui/material';
import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';
import { useDocumentsUpdateMutation } from '../../../api/documents/documentsAPI';
import { DocumentTable } from './useDocumentsTable';

interface OwnProps {
    data: DocumentTable;
    id: string;
}

export default function VisionSwitch({ id, data }: OwnProps) {
    const { handleSubmit: handleVision } = useHandleSubmitRTK({
        useRtkHook: useDocumentsUpdateMutation,
    });
    const [allowed, setAllowed] = useState(data.sharingAllowed);
    useEffect(() => {
        setAllowed(data.sharingAllowed === null ? true : data.sharingAllowed);
    }, [data]);
    function handleSwitchChange() {
        setAllowed(!allowed);
        handleVision({
            ...data,
            documentId: id,
            sharingAllowed: !allowed,
        });
    }
    return (
        <Box display={'flex'} alignItems={'center'} columnGap={1}>
            <Switch
                checked={allowed}
                color="primary"
                onChange={() => {
                    handleSwitchChange();
                }}
            />
        </Box>
    );
}
