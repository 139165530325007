import { grey } from '@mui/material/colors';

declare module '@mui/material/styles/createPalette' {
    export interface Palette {
        avatarPurple: string;
        brandDarkTurq: string;
        brandLightTurq: string;
        primaryBlue: string;
        brandYellowGreen: string;
        statusBlue: string;
        brandLimeGreen: string;
        statusYellow: string;
        statusRed: string;
        fieldError: string;
        statusRedTint: string;
        statusBackgroundRed: string;
        strokeS1: string;
        characterPrimaryInverse: string;
        neutralWhite: string;
        neutralBlack: string;
        neutral: {
            [key: string]: string;
        };
        limeGreen: {
            [key: string]: string;
        };
        yellowGreen: {
            [key: string]: string;
        };
        lightTurq: {
            [key: string]: string;
        };
        darkTurq: {
            [key: string]: string;
        };
        backgroundB: {
            [key: string]: string;
        };
    }
}
type ChartColorsKeys = keyof typeof chartColors;
export type ChartColors = (typeof chartColors)[ChartColorsKeys];

const PaletteOfColors = {
    avatarPurple: '#694398',
    customToggle: {
        main: '#6cb4b8',
        contrastText: '#fff',
    },
    brandDarkTurq: '#003847',
    primaryBlue: '#40a9ff',
    brandLightTurq: '#2e798d',
    brandYellowGreen: '#e8f6c2',
    neutralWhite: '#fff',
    neutralBlack: '#000',
    brandLimeGreen: '#96e996',
    statusYellow: '#f7d67f',
    statusRed: '#d74242',
    statusBlue: '#119eca',
    fieldError: '#d32f2f',
    statusRedTint: '#e99696',
    statusBackgroundRed: '#fdeeec',
    strokeS1: '#d5dfec',
    characterPrimaryInverse: '#fff',
    text: {
        main: '#2c2e30',
        secondary: '#003847',
        secondaryContrast: '#5f6163',
        link: '#2e798d',
        neutralWhite: '#fff',
        light: '#abadaf',
        error: '#d32f2f',
        neutral6: '#787a7c',
        neutral7: '#5f6163',
        neutral8: '#454749',
        neutral9: '#2c2e30',
        neutral10: '#131416',
        darkTurqTint5: '#809ba3',
        darkTurqTint8: '#33606c',
    },
    border: {
        default: '#dfe0e2',
    },
    primary: {
        main: '#118197',
        light: '',
    },
    secondary: {
        main: '#fff',
    },
    backgroundB: {
        backgroundB1: '#f8fafc',
        backgroundB2: '#f2f5f9',
        backgroundB3: '#eaeff5',
        backgroundB4: '#e3eaf2',
        pinnedNote: '#E9EDF5',
        unpinnedNote: '#F2F3F9',
    },
    neutral: {
        neutral1: '#f8fafc',
        neutral2: '#dfe0e2',
        neutral3: '#c5c7c9',
        neutral5: '#929496',
        neutral8: '#454749',
        neutral6: '#787a7c',
        neutral4: '#abadaf',
        neutral7: '#5f6163',
        neutral9: '#2c2e30',
        neutral10: '#131416',
    },
    limeGreen: {
        limeGreenTint1: '#f5fdf5',
        limeGreenTint2: '#eafbea',
        limeGreenTint3: '#dff8df',
        limeGreenTint4: '#d5f6d5',
        limeGreenTint5: '#cbf4cb',
        limeGreenTint6: '#c0f2c0',
        limeGreenTint7: '#b5f0b5',
        limeGreenTint8: '#abedab',
        limeGreenTint9: '#a1eba1',
        limeGreenShade1: '#7dcf7d',
        limeGreenShade2: '#63b663',
        limeGreenShade5: '#176a17',
        limeGreenShade4: '#308330',
        limeGreenShade3: '#4a9c4a',
        limeGreenShade7: '#003700',
        limeGreenShade6: '#005000',
        limeGreenShade8: '#001d00',
        limeGreenShade9: '#000400',
    },
    yellowGreen: {
        yellowGreenTint1: '#fdfef9',
        yellowGreenTint2: '#fafdf3',
        yellowGreenTint3: '#f8fced',
        yellowGreenTint4: '#f6fbe7',
        yellowGreenTint5: '#f3fbe1',
        yellowGreenTint6: '#f1fada',
        yellowGreenTint8: '#edf8ce',
        yellowGreenTint7: '#eff9d4',
        yellowGreenTint9: '#eaf7c8',
        yellowGreenShade1: '#cedca8',
        yellowGreenShade3: '#9ba976',
        yellowGreenShade2: '#b5c38f',
        yellowGreenShade5: '#697743',
        yellowGreenShade4: '#82905c',
        yellowGreenShade6: '#4f5d29',
        yellowGreenShade7: '#364410',
        yellowGreenShade8: '#1c2a00',
        yellowGreenShade9: '#031100',
    },
    lightTurq: {
        lightTurqTint1: '#eaf2f4',
        lightTurqTint2: '#d5e4e8',
        lightTurqTint3: '#c0d7dd',
        lightTurqTint4: '#abc9d1',
        lightTurqTint5: '#96bcc6',
        lightTurqTint6: '#82afbb',
        lightTurqTint7: '#6da1af',
        lightTurqTint8: '#5894a4',
        lightTurqTint9: '#438698',
        lightTurqShade1: '#156074',
        lightTurqShade2: '#00465a',
        lightTurqShade3: '#002d41',
        lightTurqShade4: '#001327',
        lightTurqShade5: '#00000e',
    },
    darkTurq: {
        darkTurqTint1: '#e5ebed',
        darkTurqTint2: '#ccd7da',
        darkTurqTint3: '#b2c3c8',
        darkTurqTint4: '#99afb5',
        darkTurqTint5: '#809ba3',
        darkTurqTint7: '#4d747e',
        darkTurqTint8: '#33606c',
        darkTurqTint6: '#668891',
        darkTurqTint9: '#1a4c59',
        darkTurqShade1: '#001f2e',
        darkTurqShade2: '#000514',
    },
    grey: {
        ...grey,
        350: '#c4c4c4',
        750: '#545c64',
        100: '#f6f6f6',
        med: '#dcdddd',
    },
};

export const chartColors = {
    limeGreen: PaletteOfColors.limeGreen.limeGreenTint9,
    yellow: PaletteOfColors.yellowGreen.yellowGreenTint9,
    turq: PaletteOfColors.darkTurq.darkTurqTint2,
};

export default PaletteOfColors;
