import { Box, IconButton, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
    useDeleteRoleMutation,
    useGetRolesQuery,
    useLazyGetRolesQuery,
} from '../../../../api/users/rolesAPI';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit_icon.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/remove_icon.svg';
import GenericTable, {
    Table,
} from '../../../../design/GenericTable/GenericTable';
import useModal from '../../../../hooks/useModal';
import ConfirmModal from '../../../../layouts/ConfirmModal/ConfirmModal';
import EditRole from '../AddRole/EditRole';
import { useSorting } from '../../../../design/GenericTable/useSorting';
import { usePagination } from '../../../../design/GenericTable/useBackendPagination';
import { useSettingsFromStorage } from '../../../../hooks/useSettingsFromStorage';
import { useSaveSettings } from '../../../../hooks/useSaveSettings';

interface ShortResponse {
    id: string;
    name: string;
}

export interface Role {
    id: string;
    name: string;
    description: string;
    type?: string;
    organization?: ShortResponse;
}

export default function RoleList() {
    const { settings, saveSettings } = useSettingsFromStorage('ROLES');
    const { sort, page, sortDirection, size } = settings;

    const { isShowing: editIsShowing, setIsShowing: setEditIsShowing } =
        useModal();

    const { isShowing: deleteIsShowing, setIsShowing: setDeleteIsShowing } =
        useModal();
    const [id, setId] = useState<string | null>(null);
    const [deleteRole] = useDeleteRoleMutation();

    const { sortKey, sortOrder, handleSort } = useSorting(
        sort || 'type',
        sortDirection || 'asc',
    );
    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(page || 1, size || 25);

    useSaveSettings({
        settings,
        saveSettings,
        sortKey,
        sortOrder,
        currentPage,
        itemsPerPage,
    });
    const { data: initData, isLoading } = useGetRolesQuery({
        sort: `${sortKey},${sortOrder}`,
        page: currentPage - 1,
        size: itemsPerPage,
    });
    const [getItems, { data: sortedData, isLoading: isLazyLoading }] =
        useLazyGetRolesQuery();

    useEffect(() => {
        getItems({
            sort: `${sortKey},${sortOrder}`,
            page: currentPage - 1,
            size: itemsPerPage,
        });
    }, [sortKey, sortOrder, currentPage, itemsPerPage]);

    const onDelete = (id: string) => {
        id && setId(id);
        setDeleteIsShowing(true);
    };

    const handleDelete = (id: string) => {
        deleteRole(id);
        setDeleteIsShowing(false);
        setId(null);
    };

    const onEdit = (id: string) => {
        id && setId(id);
        setEditIsShowing(true);
    };

    const data = useMemo(() => {
        return sortedData?.data || initData?.data || [];
    }, [sortedData, initData]);

    const totalCount = useMemo(() => {
        return sortedData?.totalCount || initData?.totalCount || 0;
    }, [sortedData, initData]);

    const findRole = (id: string) => {
        return data?.find((item: Role) => item.id === id);
    };

    const roleTableConfig: Table<Role> = {
        columns: [
            {
                header: {
                    label: 'Role',
                    sortable: true,
                    sorting: {
                        id: 'name',
                        direction: sortKey === 'name' ? sortOrder : 'desc',
                        isSorted: sortKey === 'name',
                    },
                    onClick: handleSort,
                },
                cellRender: (role: Role) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {role.name}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Organization',
                    sortable: true,
                    sorting: {
                        id: 'organization.name',
                        direction:
                            sortKey === 'organization.name'
                                ? sortOrder
                                : 'desc',
                        isSorted: sortKey === 'organization.name',
                    },
                    onClick: handleSort,
                },
                cellRender: (role: Role) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {role.organization?.name}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Description',
                    sortable: true,
                    sorting: {
                        id: 'description',
                        direction:
                            sortKey === 'description' ? sortOrder : 'asc',
                        isSorted: sortKey === 'description',
                    },
                    onClick: handleSort,
                },
                cellRender: (role: Role) => (
                    <Typography
                        variant={'font14'}
                        color={'text.link'}
                        fontWeight={'medium'}>
                        {role.description}
                    </Typography>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
            {
                header: {
                    label: 'Actions',
                    sortable: false,
                },
                cellRender: (role: Role) => (
                    <Box>
                        {role.type !== 'GLOBAL' && (
                            <>
                                <IconButton onClick={() => onEdit(role.id)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => onDelete(role.id)}>
                                    <RemoveIcon />
                                </IconButton>
                            </>
                        )}
                    </Box>
                ),
                format: { align: 'left', color: { color: 'primary' } },
                width: 'auto',
            },
        ],
        multiselect: false,
        expandable: false,
        handlePageChange: handlePageChange,
        handleItemsPerPageChange: handleItemsPerPageChange,
        initialPage: page,
        initialPageSize: size,
    };

    return (
        <>
            <GenericTable
                isLoading={isLoading || isLazyLoading}
                data={data}
                totalCount={totalCount}
                tableConfig={roleTableConfig}
            />
            {id && (
                <EditRole
                    id={id}
                    isShowing={editIsShowing}
                    setIsShowing={setEditIsShowing}
                />
            )}
            {id && (
                <ConfirmModal
                    isShowing={deleteIsShowing}
                    hide={() => setDeleteIsShowing(false)}
                    title={`Are you sure you want to delete ${
                        findRole(id)?.name
                    }?`}
                    info={`You are about to delete  ${
                        findRole(id)?.name
                    }. This action cannot be undone`}
                    onSubmit={() => handleDelete(id)}
                />
            )}
        </>
    );
}
