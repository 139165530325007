import React, { useEffect } from 'react';
import WizardForm from '../../design/Forms/WizardForm';
import LeaseDetailForm, { FSA } from './LeaseDetailForm';
import PreviewFixed from './PreviewFixed';
import LeaseUploadFormPageWrapper from './LeaseUploadFormPageWrapper';
import {
    getCommonDraftInitialValues,
    getCommonInitialValues,
    validateFsaStep,
} from './utils';

import { Lease } from '../../pages/Leases/TableConfig';

import { Grid } from '@mui/material';
import Loader from '../../design/BaseLoader';

import { useLeaseMutation } from './useLeaseMutation';
import { useLeaseWizard } from './useLeaseWizard';

interface OwnProps {
    pageNumber: number;
    setPageNumber: (page: number) => void;
    setIsValid: (isValid: boolean) => void;
    setIsNavigable: (isNavigable: boolean) => void;
}

export default function LeaseWizardFixedForm({
    pageNumber,
    setPageNumber,
    setIsValid,
    setIsNavigable,
}: OwnProps) {
    const {
        state,
        fsaData,
        setFsaData,
        data,
        isLoading,
        isFetching,
        isEdit,
        isFetchingOne,
        isLoadingOne,
        initData,
    } = useLeaseWizard(pageNumber, setIsNavigable);
    const {
        handleSubmitDraft,
        dataCreate,
        handleUpdateDraft,
        isCreating,
        isUpdating,
        submitValues,
        handleSubmit,
    } = useLeaseMutation(fsaData);

    useEffect(() => {
        if (initData) {
            const fsaIds = initData.fsaIds.map((item: FSA) => {
                return {
                    ...item,
                    tableId: item.id,
                };
            });
            setFsaData(fsaIds);
        }
    }, [initData]);

    const handleSubmitForm = ({
        leaseFile,
        supportingFile,
        ...values
    }: Lease) =>
        handleSubmit({
            leaseFile,
            supportingFile,
            ...values,
            id: !values.id ? dataCreate?.id : values.id,
        });

    function initialValues() {
        if (isEdit) {
            return {
                ...initData,
                ...getCommonInitialValues(initData),
            };
        }

        if (state?.isCopy) {
            return {
                ...state,
                ...getCommonDraftInitialValues(data, state),
            };
        }
        return {
            verbalLease: false,
            autoRenewal: false,
            type: 'FIXED',
            doLumpSum: 'paymentsByField',
            grower: data.organization.id || '',
        };
    }

    const handleDraft = ({ leaseFile, supportingFile, ...values }: Lease) => {
        if (state?.isCopy) {
            handleUpdateDraft({
                id: dataCreate?.id || initialValues().id,
                ...submitValues({
                    leaseFile,
                    supportingFile,
                    ...values,
                }),
            });
            return;
        }
        handleSubmitDraft(
            submitValues({
                leaseFile,
                supportingFile,
                ...values,
            }),
        );
    };

    if (isFetchingOne || isLoadingOne) {
        return (
            <Grid
                width={'100%'}
                container
                alignItems={'center'}
                justifyContent={'center'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <WizardForm
            isDraft={!isEdit}
            onDraftSubmit={handleDraft}
            setIsValid={setIsValid}
            isLoading={isCreating || isUpdating || isLoading || isFetching}
            initialValues={initialValues()}
            onStepValidate={() => validateFsaStep(pageNumber, fsaData)}
            onSubmit={handleSubmitForm}
            setStep={setPageNumber}
            step={pageNumber}>
            <LeaseUploadFormPageWrapper data={data?.organization} />
            <LeaseDetailForm setTableData={setFsaData} tableData={fsaData} />
            <PreviewFixed setPageNumber={setPageNumber} tableData={fsaData} />
        </WizardForm>
    );
}
