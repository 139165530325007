import PreviewFormWrapper from './PreviewFormWrapper';
import { EmergencyContactData } from '../model';
import PreviewContactCard from './PreviewContactCard';
import { Box } from '@mui/material';

interface OwnProps {
    contacts: EmergencyContactData[];
}
export default function EmergencyContactsPreview({ contacts }: OwnProps) {
    return (
        <PreviewFormWrapper label={'Contacts: Emergency'}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={2}
                width={'100%'}>
                {contacts?.map((contact) => (
                    <PreviewContactCard
                        key={contact.contact.id}
                        contact={contact.contact}
                        contactTypes={contact.contactTypes}
                    />
                ))}
            </Box>
        </PreviewFormWrapper>
    );
}
