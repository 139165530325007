import GenericTable from '../../../design/GenericTable/GenericTable';
import useSolutionsInProgressTable from './useSolutionsInProgressTable';
import { Typography } from '@mui/material';
import React from 'react';

export default function SolutionsInProgressTable() {
    const { tableConfig, data, isLoading } = useSolutionsInProgressTable();
    return (
        <>
            <Typography variant={'font16'} fontWeight={'bold'}>
                Solutions in Progress
            </Typography>
            <GenericTable
                isLoading={isLoading}
                data={data}
                tableConfig={tableConfig}
            />
        </>
    );
}
