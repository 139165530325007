import React from 'react';
import { Box } from '@mui/material';
import AppTypography from '../../../design/AppTypography';

interface OwnProps {
    title: string;
    listData: string[];
}

export default function CountListWithTitle({ title, listData }: OwnProps) {
    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={1}>
            <AppTypography
                variant={'font14'}
                fontWeight="medium"
                color={'text.neutral10'}
                text={title}
            />
            <Box display={'flex'} flexDirection={'column'} rowGap={0.5}>
                {listData?.map((item, index) => (
                    <AppTypography
                        key={index}
                        variant="font14"
                        fontWeight={'regular'}
                        color={'text.neutral9'}
                        text={`${index + 1}.${item}`}
                    />
                ))}
            </Box>
        </Box>
    );
}
